.form-group {
    padding: 0;
    margin: 0 0 8px 0;
    position: relative;

    &:last-child {
        padding-top: 8px;
        margin-bottom: 0;
    }

    button {
      background: white;
      color: $gray;
      margin: 0;
      padding: 0;
      width: 100%;
  }
}

.form-control__select {
    padding: 0;
    position: relative;

    &::after {
        border-bottom: none;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid white;
        content: '';
        display: block;
        height: 8px;
        margin: 0;
        opacity: .84;
        padding: 0;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: all .4s ease-in-out;
        width: 10px;
        z-index: 1;
    }

    select {
        @include font-size(1.6);
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        font-weight: normal;
        padding-right: 24px;
        position: relative;
        z-index: 2;

        option {
            color: white;
            padding: 5px;
        }
    }
}

input[type='number'] {
  -moz-appearance: textfield;/*For FireFox*/

  &::-webkit-inner-spin-button { /*For Webkits like Chrome and Safari*/
    -webkit-appearance: none;
    margin: 0;
  }
}

.input-label {
  display: block;
  margin: 0;
  padding: 16px 0 0 0;
  position: relative;

  &::after {
    border-radius: 1px;
    bottom: 0;
    content: '';
    height: 2px;
    left: 0;
    margin: 0;
    opacity: 1;
    padding: 0;
    position: absolute;
    width: 100%;
    transition: all .35s ease-in-out;
  }

  label {
    @include font-size(1.3);
    color: $gray;
    font-weight: normal;
    left: 0;
    line-height: 16px;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    transform: translate(0, 0);
    transition: all .25s ease-in-out;
    z-index: 1;
  }

  input,
  select {
      @include font-size(1.6);
      background: transparent;
      border-radius: 0;
      border: none;
      color: $gray;
      display: block;
      height: 40px;
      margin: 0;
      opacity: .84;
      outline: none;
      padding: 0;
      position: relative;
      text-align: left;
      width: 100%;
      z-index: 2;

      &::-webkit-input-placeholder { 
        color: $gray;
      }
      &::-moz-placeholder { 
        color: $gray;
      }
      &:-ms-input-placeholder { 
        color: $gray;
      }
      &:-moz-placeholder { 
        color: $gray;
      }

      &:-webkit-autofill {
        background: transparent!important;
        color: $gray;
        -webkit-text-fill-color: $gray !important;
        box-shadow: 0 0 0 300px rgba(255,255,255,1) inset;
      }

      &:focus {
        background: transparent;
        box-shadow: 0 0 0 300px rgba(255,255,255,1) inset;
        border: none;
      }
  }

  &--is-empty {
    
    &::after {
      height: 1px;
    }

    label {
      @include font-size(1.6);
      line-height: normal;
      top: 50%;
      transform: translate(0, calc(-50% + 8px));
    }
  }

  &--is-focused {
    &::after {
      height: 2px;
    }

    label {
      @include font-size(1.3);
      line-height: 16px;
      top: 0;
      transform: translate(0, 0);
    }
  }
}

.submit {
  @include font-size(1.3);
  background: transparent;
  border: none;
  color: white;
  font-weight: $font-weight-bold;
  letter-spacing: 2px;
  margin: 10px 0;
  position: relative;
  text-align: left;
  text-transform: uppercase;

  @include r(medium) {
    text-align: center;
  }
}