.block-list {
	margin: 0 0 128px 0;
	padding: 0;

	.panel-grid-cell {
		width: 50%!important;

		@include r(tablet) {
		  width: 100%!important;
		}

		&:first-child {
			margin-right: 50px;
			z-index: 1;

			@include r(popular) {
				margin-right: 25px;
			}

			@include r(tablet-landscape) {
				width: 58.26%!important;
			}

			@include r(tablet) {
				margin: 0 0 -50px 0!important;
				width: 100%!important;
			}
		}
		&:last-child {
			margin-left: 50px;
			z-index: 2;

			@include r(popular) {
				margin-left: 25px;
			}

			@include r(tablet-landscape) {
				width: 41.74%!important;
			}

			@include r(tablet) {
				background: $gray!important;
				margin: 0;
				padding: 50px!important;
				width: calc(100% - 140px)!important;
			}

			@include r(mobile-landscape) {
				padding: 30px!important;
				width: calc(100% - 72px)!important;
			}
		}
	}

	&--last {
		margin-bottom: 0;
	}

	> .panel-grid-cell {
		&:nth-child(1) {
			order: 1;
		}
		&:nth-child(2) {
			order: 2;
		}
	}

	&--left {
		> .panel-grid-cell {
			&:nth-child(1) {
				order: 2;
				margin-left: 50px;
				margin-right: 0;

				@include r(popular) {
					margin-left: 25px;
				}

				@include r(tablet) {
					margin: 0 0 -50px 0!important;
					order: 1;
				}
			}
			&:nth-child(2) {
				order: 1;
				margin-right: 50px;
				margin-left: 0;

				@include r(popular) {
					margin-right: 25px;
				}

				@include r(tablet) {
					margin: 0;
					order: 2;
				}

				.block-list__text {
					padding-left: 150px;

					@include r(popular) {
						padding-left: 80px;
					}

					@include r(tablet) {
						padding: 0;
					}
				}
			}
		}
	}
	

	.wow {
		opacity: 0;
		transform: translate(0, 40px);
		transition: all .5s ease-in-out;

		&.animated {
			opacity: 1;
			transform: translate(0,0);

			.block-list__text {
				ul {
					opacity: 1;
				}
			}
		}
	}

	
	// Title Widget
	.widget-title {
		font-size: 85px;

		@include r(popular) {
			font-size: 55px;
		}

		@include r(mobile) {
			font-size: 45px;
		}
	}

	.block-list__text {

		ul {
			margin: 0;
			opacity: 0;
			padding: 0;
			transition: all .5s ease-in-out;
			visibility: hidden;

			li {
				margin: 0;
				&:last-child {
					margin-bottom: 0;
				}

				@include r(tablet) {
					margin-bottom: 15px;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
			&:hover > * { 
				a {
					color: #404040;
				}
			}
			> * { 
				a {
					@include font-size(2.4);
					color: white;
					font-weight: $font-weight-bold;
					line-height: 44px;
					text-transform: uppercase;
					transition: ease-in-out .25s color;
					visibility: visible;
					letter-spacing: 2px;

					@include r(popular) {
					  @include font-size(1.6);
					  line-height: 26px;
					}

					@include r(tablet) {
					  @include font-size(1.3);
					  line-height: 20px;
					}
				}
				&:hover { 
					a {
						color: white;
						&:hover {
					    	color: $yellow;
						}
					}
				}
			}
		}
	}
}