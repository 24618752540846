/* ------------------------------------------------------------ */
/*  Pagination */
/* ------------------------------------------------------------ */
.pagination {
    .page-item {
        align-items: center;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        margin-left: 8px;
        min-height: 40px;
        min-width: 40px;

        .page-link {
            border-color: $green;
            border-radius: 2px;
            color: $green;
            display: flex;
            flex-direction: column;
            font-weight: bold;
            height: 100%;
            justify-content: center;
            text-align: center;
            text-decoration: none;
            width: 100%;
            border: 1px solid rgba(191,217,204,1);
        }
    }
    .page-item.active .page-link {
        background-color: $gray !important;
        border: 1px solid $gray;
        color: #ffffff;
    }

    .next {
        .page-link {
            background-color: $green-dark !important;
            border: 1px solid $green-dark;
            color: #ffffff;
            font-family: $default-font-family;
            font-size: 12px;
            font-weight: bold;
            padding-left: 16px;
            width: auto;

            @include r(tablet-landscape) {
                padding: 0.5rem 0.75rem;
                width: 100%;
            }

            span {
                align-items: center;
                display: flex;
                flex-direction: row;
                justify-content: center;
            }
            
            i {
                align-items: center;
                font-size: 16px;
                margin-left: 5px;

                @include r(tablet-landscape) {
                    margin: 0;
                }
            }
        }
    }
}