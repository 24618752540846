.contact-form {
  background-color: white;
  border-radius: 2px;
  padding: 24px;
  overflow: hidden;

  @include r(350px) {
    padding: 24px 20px;
  }

  &__section {

  }

  &-footer {
    background-color: rgba(0,0,0,0.12);
    padding: 70px 14px 24px 14px;

    .widget-title {
      color: $gray-dark;
      display: block;
      text-align: center;

      @include r(small) {
        @include font-size(3.0);
        line-height: 34px;
        font-weight: $font-weight-semibold;
      }
    }

    .contact-form {
      margin-left: auto;
      margin-right: auto;
      max-width: 630px;
      width: 100%;
    }
  }
}


.contact-form-modal {

  .contact-form {
    @include r(350px) {
      padding: 24px 10px;
    }
  }

  .modal-title {
    padding-left: 22px;
    padding-right: 22px;

    @include r(350px) {
      padding-left: 10px;
    }
  }

  .modal-dialog {
    border: 0;
    box-shadow: none;
    max-width: 665px;
    margin: 0 auto;
  }

  // Close Button
  .close {
    @include font-size(2.4);
    opacity: 0.2;
    padding: 10px 15px;
    position: absolute;
    right: 7px;
    top: 7px;
  }

  .modal-content {
    border: 0;

    @include r(350px) {
      border-radius: 0;
      margin: 0;
    }
  }

  .modal-body {
    padding: 20px 12px;

    @include r(412px) {
      padding: 20px 0px;
    }
  }
}