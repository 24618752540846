// Footer menu
.footer__menu {
	margin: 0 0 16px 0;
	padding: 0;
	display: block;

	&:last-child {
		margin-bottom: 0;
	}

	li  {
		margin: 0 0 8px 0;
		padding: 0;
		display: block;

		&:last-child {
			margin-bottom: 0;
		}
	}

	a {
		@include font-size(1.6);
		color: $gray;
		display: inline-block;
		font-weight: normal;
		line-height: 26px;
		margin: 0;
		padding: 0;
		transition: all .25s ease-in-out;

		&:hover {
			color: $green-dark;
		}
	}

	// Parent Menu
	.footer__menu-parent {
		margin: 0 0 48px 0;
		padding: 0;

		&:last-child {
			margin-bottom: 0;
		}

		> a {
			font-weight: $font-weight-bold;
			margin: 0 0 24px 0;
			padding: 0 0 10px 0;
			position: relative;

			&::after {
		      background: $yellow;
		      bottom: 0;
		      content: '';
		      height: 2px;
		      left: 0;
		      margin: 0;
		      padding: 0;
		      position: absolute;
		      width: 16px;

		      @include r(tablet-landscape) {
			    left: 50%;
			    transform: translate(-50%, 0);
			  }
		    }
		}
	}


	// Submenu
	.footer__submenu {
		margin: 0;
		padding: 0;
	}
}