// Social Links Icons

.social-links {
  margin: 0 0 5px 0;
  padding: 0;

  li {
    display: inline-block;
    margin: 0 7px;
    vertical-align: middle;
  }

  a {
    background-repeat: no-repeat;
    background-position: center center;
    color: white;
    display: block;
    height: 32px;
    overflow: hidden;
    text-indent: 50px;
    transition: all 160ms ease-in-out;
    width: 24px;

    &:hover,
    &:focus,
    &:active {
      transform: scale(1.1);
    }
  }

  .phone {
    padding: 0 50px 0 0;
    position: relative;

    &::after {
      background-color: rgba(255,255,255,0.24);
      content: " ";
      display: block;
      height: 20px;
      position: absolute;
      right: 15px;
      top: 6px;
      width: 1px;
    }

    a {
      line-height: 30px;
      width: auto;

      &::before {
        background-image: url('assets/images/phone-icon.svg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 20px 20px;
        content: " ";
        display: inline-block;
        height: 20px;
        margin: 0 10px 3px 0;
        vertical-align: middle;
        width: 20px;
      }
    }
  }

  .facebook a {
    background-image: url('assets/images/facebook-icon.svg');
  }

  .twitter a {
    background-image: url('assets/images/twitter-icon.svg');
  }

  .instagram a {
    background-image: url('assets/images/instagram-icon.svg');
  }

  .linkedin a {
    background-image: url('assets/images/linkedin-icon.svg');
  }


  .footer-links & {
    
    li {
      display: inline-block;
    }

    a {
      background-size: 30px auto;
      height: 32px;
      width: 32px;
    }

    .facebook {
      margin-left: 0;

      a {
        background-image: url('assets/images/facebook-icon-blue.svg');

        &:hover,
        &:focus,
        &:active {
          background-image: url('assets/images/facebook-icon.svg');
          transform: scale(1.1);
        }
      }
    }

    .twitter a {
      background-image: url('assets/images/twitter-icon-blue.svg');

      &:hover,
      &:focus,
      &:active {
        background-image: url('assets/images/twitter-icon.svg');
        transform: scale(1.1);
      }
    }

    .instagram a {
      background-image: url('assets/images/instagram-icon-blue.svg');

      &:hover,
      &:focus,
      &:active {
        background-image: url('assets/images/instagram-icon.svg');
        transform: scale(1.1);
      }
    }

    .phone {
      display: none;
    }
  }
}