/* ------------------------------------------------------------ */
/*  Buttons */
/* ------------------------------------------------------------ */

%btn {
  @include font-size(1.3);
  background: transparent;
  border-radius: 2px;
  border: none;
  box-shadow: 0 0 0 0 transparent;
  font-family: $default-font-family;
  font-weight: bold;
  line-height: 40px;
  outline: 0;
  padding: 0 16px;
  position: relative;
  text-transform: uppercase;
  transition: all .35s ease-in-out;

  * {
    transition: all .35s ease-in-out;
  }

  @include r(desktop-minimun) {
    @include font-size(1.2);
    height: auto;
  }

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 0 0 0 transparent;
    outline: 0;
  }

  &:active:focus,
  &:active:hover {
    box-shadow: 0 0 0 0 transparent;
    outline: 0;
  }
}

// btn
.btn {
  @extend %btn;

  * {
    display: inline-block;
    vertical-align: middle;
  }

  &--disabled {
    cursor: default;
    opacity: .38;
    pointer-events: none;
  }

  &--24 {
    padding-left: 24px;
    padding-right: 24px;
  }

  &--30 {
    padding-left: 30px;
    padding-right: 30px;
  }

  &--36 {
    padding-left: 36px;
    padding-right: 36px;
  }

  &--icon {

    &.btn--24 {
      padding-left: 16px;
    }

    &.btn--36 {
      padding-left: 16px;
    }

    i {
      @include font-size(2);
      line-height: 40px;
      margin: 0 10px 0 0;
      text-align: center;
      vertical-align: top;

      @include r(desktop-minimun) {
        @include font-size(1.9);
      }

      &::before {
        display: inline-block;
        vertical-align: top;
      }
    }
  }
}



.btn--primary {
  @extend %btn;
  @include font-size(1.4);
  padding: 0;

  * {
    color: $gray-dark;
  }

  &:hover,
  &:focus,
  &:active {
    background: transparent;
    color: $gray-dark;
    outline: 0;
  }

  &:active:focus,
  &:active:hover {
    background: transparent;
    color: $gray-dark;
  }

  &.btn--icon {
    i {
      @include font-size(1.4);
    }
  }
}

// btn yellow
.btn--yellow{
  @extend %btn;

  background: $yellow;
  color: $gray-dark;

  * {
    color: $gray-dark;
  }

  &:hover,
  &:focus,
  &:active {
    background: $yellow-hover;
    color: white;

    * {
      color: white;
    }
  }

  &:active:focus,
  &:active:hover {
    background: $yellow-hover;
    color: white;

    * {
      color: white;
    }
  }

  &-outline {
    border: 1px solid $yellow;

    * {
      color: $gray-dark;
    }
  }
}


// btn green
.btn--green{
  @extend %btn;

  background: $green;
  color: white;

  * {
    color: white;
  }

  &:hover,
  &:focus,
  &:active {
    background: $green-hover;
    color: white;

    * {
      color: white;
    }
  }

  &:active:focus,
  &:active:hover {
    background: $green-hover;
    color: white;

    * {
      color: white;
    }
  }

  &-outline {
    background: transparent;
    border: 2px solid $green;
    color: $green;
    line-height: 36px;

    * {
      color: $green;
    }

    &:hover {
      background: $green;
      color: white;
    }
  }
}


// btn white
.btn--white{
  @extend %btn;

  background: white;
  color: $gray;

  * {
    color: $gray;
  }

  &:hover,
  &:focus,
  &:active {
    background: $white-hover;
    color: $gray;

    * {
      color: $gray;
    }
  }

  &:active:focus,
  &:active:hover {
    background: $white-hover;
    color: $gray;

    * {
      color: $gray;
    }
  }

  &-outline {
    border: 1px solid white;

    * {
      color: white;
    }
  }

  &-showcase {
    @extend .btn--white;
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.06);

    @include r(mobile){
      line-height: 30px;
      font-size: 0.9rem;
    }
  }
}


// btn gray
.btn--gray{
  @extend %btn;

  background: $gray;
  color: white;

  * {
    color: white;
  }

  &:hover,
  &:focus,
  &:active {
    background: $gray-hover;
    color: white;

    * {
      color: white;
    }
  }

  &:active:focus,
  &:active:hover {
    background: $gray-hover;
    color: white;

    * {
      color: white;
    }
  }

  &-outline {
    border: 1px solid $gray;

    * {
      color: $gray;
    }
  }

  &-showcase {
    @extend .btn--gray;
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.06);

    @include r(mobile){
      line-height: 30px;
      font-size: 0.9rem;
    }
  }
}


// btn phone\
.btn--phone{
  @extend %btn;
  @include font-size(1.6);
  align-items: center;
  background: transparent;
  border-radius: 30px;
  border: 1px solid #B7B8B8;;
  color: $gray;
  display: flex;
  font-weight: normal;
  justify-content: center;
  line-height: 36px;
  margin-bottom: 24px;
  max-width: 174px;
  padding-bottom: 0;
  padding-top: 0;
  transition: all .35s ease-in-out;

  @include r(991px){
    margin-left: auto;
    margin-right: auto;
  }

  i {
    @include font-size(1.8);
    align-items: center;
    color: $gray;
    display: flex;
    height: 18px;
    justify-content: center;
    transition: all .35s ease-in-out;
    width: 18px;

    &::before {
      align-items: center;
      display: flex;
      height: 18px;
      justify-content: center;
      width: 18px;
    }
  }

  &:hover,
  &:focus:hover {
    background: $gray;
    color: white;

    i {
      color: white;
    }
  }
}