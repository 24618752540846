// Showcase Slider
.showcase-slide {
  margin: 0!important;
  max-height: 836px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 100%;

  //Showcase
  .showcase {

    // Content
    .showcase__content {
      padding-bottom: 64px;

      @include r(tablet-landscape) {
        padding-bottom: 24px;
      }
    }
  }

  // Arrows
  .slick-arrow {
    width: 60px;
    height: 60px;
    z-index: 10;

    @include r(tablet-landscape) {
      display: none!important;
    }
  }

  .slick-prev {
    left: 40px;

    &::before {
      content: "\6f";
      font-family: "w-icons";
      font-size: 40px;
    }
  }

  .slick-next {
    right: 40px;

    &::before {
      content: "\41";
      font-family: "w-icons";
      font-size: 40px;
    }
  }


  // Dots
  .slick-dots {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 64px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    @include r(tablet-landscape) {
      height: 24px;
    }

    li {
      margin: 0 8px;
      button {
        &::before {
          font-size: 16px;
          color: white;
          transition: all .35s ease-in-out;

          @include r(tablet-landscape) {
            font-size: 12px;
          }
        }
      }

      &.slick-active  {
        button {
          &::before {
            color: white;
          }
        }
      }
    }
  }
}

// Showcase
.showcase {
  margin: 0;
  overflow: hidden; 
  padding: 0;
  position: relative;
  width: 100%;

  // image
  .showcase__image {
    margin: 0;
    padding: 0;
    position: relative;
    max-height: 520px;
    overflow: hidden;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;

    @include r(tablet) {
      display: block;
    }

    img {
      height: auto;
      margin: 0;
      padding: 0;
      width: 100%;
      opacity: 0;

      @include r(mobile-landscape) {
        left: 50%;
        min-width: 570px;
        position: relative;
        transform: translate(-50%,0);
      }
    }

    &_blur {
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      -webkit-filter: blur(20px);
      -moz-filter: blur(20px);
      -o-filter: blur(20px);
      -ms-filter: blur(20px);
      filter: blur(20px);
      z-index: -1;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transform: scale(1.1);

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(black,0.48);
      }
    }

    &_text {

      h3 {
        color: white;
        font-size: 40px;
        font-weight: bold;
        line-height: 49px;
        margin-bottom: 24px;
      }
      
      p {
        color: rgba(255,255,255,0.55);
      }
    }

    &--full {

      img {
        opacity: 1;
        width: 100%;
        height: auto;
      }
    }
  }


  // Content
  .showcase__content {
    background: linear-gradient(180deg, rgba(51,51,51,0) 0%, rgba(55,56,56,0.86) 100%);
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 98px 0 40px 0;
    position: absolute;
    width: 100%;
    min-height: 240px;
    display: flex;
    align-items: flex-end;

    @include r(tablet-landscape) {
      padding: 0 0 16px 0;
      min-height: 1px;
      height: 100%;
    }
  }

  // Showcase Title
  .showcase__title {
    color: white;
    margin-bottom: 32px;
    padding-bottom: 17px;
    position: relative;

    @include r(tablet-landscape) {
      margin-bottom: 0;
      font-size: 20px;
    }

    span {
      @include font-size(2.5);
      display: block;
      font-weight: 500;
      margin: 0;
      padding: 16px 0 0 0;

      @include r(tablet) {
        @include font-size(1.6);
        padding: 0;
      }
    }

    &::after {
      background: $yellow;
      bottom: 0;
      content: '';
      height: 2px;
      left: 0;
      margin: 0;
      padding: 0;
      position: absolute;
      width: 16px;
    }
  }

  p {
    @include font-size(1.8);
    color: white;
    margin: 0;
    padding: 0;
  }

  // No information
  &--no-info {
    // Showcase Title
    .showcase__title {
      margin-bottom: 0;
    }

    .showcase__info {
      display: none;
    }
  }
}


// Showcase Version 2
.showcase-v2 {
  align-items: center;
  display: flex;
  margin: 0;
  padding: 0;
  vertical-align: center;
  width: 100%;

  .showcase-info-content {
    margin: 0;
    padding: 0;
    width: 50%;
    display: flex;
    justify-content: flex-end;
  }

  .showcase-info {
    align-items: center;
    display: flex;
    margin: 0;
    padding: 10px 30px 10px 15px;
    vertical-align: center;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 570px;
  }

  .showcase-image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    padding: 0;
    width: 50%;

    img {
      margin: 0;
      padding: 0;
      height: auto;
      opacity: 0;

      @include r(tablet) {
        width: 100%;
        opacity: 1;
      }
    }
  }

  // Showcase Title
  .showcase__title {
    margin-bottom: 32px;
    padding-bottom: 17px;
    position: relative;

    @include r(mobile-landscape) {
      margin-bottom: 0;
      font-size: 20px;
    }

    &::after {
      background: $yellow;
      bottom: 0;
      content: '';
      height: 2px;
      left: 0;
      margin: 0;
      padding: 0;
      position: absolute;
      width: 16px;
    }
  }
}