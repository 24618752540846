.near-list {
  margin: 0;
  padding: 0;
  font-size: 0;

  li {
    border-radius: 2px;
    display: inline-block;
    margin: 0 15px;
    min-width: 250px;
    overflow: hidden;
    padding: 0;
    position: relative;
    vertical-align: middle;

    @include r(mobile-landscape) {
      display: block;
      margin: 0 0 16px 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  a {
    @include font-size(2.0);
    border: 2px solid $green;
    color: $gray;
    display: block;
    font-weight: bold;
    margin: 0;
    padding: 0;
    text-align: left;
    transition: all .25s ease-in-out;

    &:hover {
      background: $green;
      color: white;

      .near-list__number {
        //background: white;
        //color: $green;
      }
    }
  }

  span {
    display: inline-block;
    margin: 0;
    padding: 11px 8px;
    vertical-align: middle;
  }

  .near-list__number {
    @include font-size(2.5);
    background: $green;
    color: white;
    min-width: 80px;
    text-align: center;
    transition: all .25s ease-in-out;
  }
}