/* ------------------------------------------------------------ */
/*  Header */
/* ------------------------------------------------------------ */

// Site header top

.site__header-top {
	height: 104px;
	margin: 0;
	padding: 0;
	position: relative;
	width: 100%;

	@include r(tablet-landscape) {
		height: auto;
	}
}


// Mobile menu button

.site__mobile-menu-button {
	background: transparent;
	border: none;
	display: none;
	height: 40px;
	margin: 0;
	outline: none;
	padding: 0;
	width: 40px;

	@include r(tablet-landscape) {
		display: inline-block;
	}
}


// Logotype

.site__branding {
	margin: 0;
	padding: 0;
	position: relative;
	width: 241px;
  	height: 40px;

	@include r(tablet-landscape) {
		height: 30px;
		max-width: 186px;
	}

	.site__title {
		display: none;
	}

	.site__title-link {
		display: block;
		margin: 0;
		padding: 0;
	}

	img {
		display: block;
		height: auto;
		margin: 0;
		padding: 0;
		width: 100%;
	}
}

// Online Bank button

.site__online-bank {
	margin-left: 12px;

	@include r(tablet-landscape) {
		@include font-size(1.3);
		border-radius: 0;
		height: 48px;
		line-height: 48px;
		margin: 0;
		width: 100%;

		i {
			line-height: 48px;
		}
	}
}