/*!
Site Name: Banco Caribe
Site URI: http://www.bancocaribe.com.do/
Author: Soluciones GBH
Author URI: http://solucionesgbh.com/
Description: A leading provider of state-of-the-art communications technologies.
Version: 1.0
*/

/* ------------------------------------------------------------ */
/*  Vendors */
/* ------------------------------------------------------------ */

@import 'vendors/vendors';

/* ------------------------------------------------------------ */
/*  Helpers */
/* ------------------------------------------------------------ */

@import 'helpers/mixins-master';
@import 'helpers/settings';
@import 'helpers/responsive';



/* ------------------------------------------------------------ */
/*  Base */
/* ------------------------------------------------------------ */

@import 'base/base';
@import 'base/utils';
@import 'base/typography';


/* ------------------------------------------------------------ */
/*  Components */
/* ------------------------------------------------------------ */

@import 'components/components';


/* ------------------------------------------------------------ */
/*  Layout */
/* ------------------------------------------------------------ */

@import 'layout/layout';


/* ------------------------------------------------------------ */
/*  Pages */
/* ------------------------------------------------------------ */

@import 'pages/pages';
