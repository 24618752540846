/* ------------------------------------------------------------ */
/*  Cards Overlay */
/* ------------------------------------------------------------ */

.card-overlay {
    display: block;
    margin: 0;
    padding: 2px;
    position: relative;
    width: 100%;

    @include r(mobile-landscape) {
      margin: 0 0 16px 0;
      padding: 0; 
    }

    &:hover {
        .card-overlay-image {

            &::before {
                opacity: 0;
            }

            &::after {
                opacity: 1;
            }
        }

        .card-overlay-title {
            i {
                color: white;
            }
        }
    }

    .card-overlay-image {
        border-radius: 2px;
        display: block;
        margin: 0;
        overflow: hidden;
        padding: 0;
        position: relative;
        width: 100%;

        &::before {
            background-color: rgba(47,47,47,0.54);
            content: '';
            display: block;
            height: 100%;
            left: 0;
            margin: 0;
            opacity: 1;
            padding: 0;
            position: absolute;
            top: 0;
            transition: all .35s ease-in-out;
            width: 100%;
            z-index: 2;
        }

        &::after {
            background: linear-gradient(180deg, rgba(51,51,51,0) 0%, rgba(55,56,56,0.86) 100%);
            content: '';
            display: block;
            height: 100%;
            left: 0;
            margin: 0;
            opacity: 0;
            padding: 0;
            position: absolute;
            top: 0;
            transition: all .35s ease-in-out;
            width: 100%;
            z-index: 2;
        }

        img {
            height: auto;
            margin: 0;
            position: relative;
            width: 100%;
            z-index: 1;
        }
    }

    .card-overlay-title {
        @include font-size(2.5);
        bottom: 0;
        color: white;
        left: 0;
        line-height: 30px;
        margin: 0;
        padding: 24px 16px;
        position: absolute;
        transition: all .35s ease-in-out;
        width: 100%;
        z-index: 2;

        @include r(tablet-landscape) {
            @include font-size(2);
            padding: 16px;
        }

        i {
            @include font-size(1.6);
            color: $orange;
            transition: all .35s ease-in-out;
            vertical-align: middle;
        }
    }
}   