/* ------------------------------------------------------------ */
/*  Search result card */
/* ------------------------------------------------------------ */
.page-search-result {
  display:flex;
  margin: 0 0 24px 0;
  padding: 0 0 24px 0;
  position: relative;

  @include r(mobile-landscape) {
    flex-wrap: wrap;
    width: 100%;
  }

  &::after {
    background-color: rgba(0,0,0,0.25);
    bottom: -0.5px;
    content: '';
    height: 1px;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 100%;
  }

  &:last-child {
    margin: 0;
  }
}

.page-search-result-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 30px 0 0;
  min-width: 255px;
  padding: 0;
  position: relative;

  @include r(mobile-landscape) {
    margin: 0 0 16px 0;
    width: 100%;
  }

  img {
    margin: 0;
    opacity: 0;
    padding: 0;
    position: relative;
    z-index: 1;
  }

  &--video {
    &::before {
      align-items: center;
      background: white;
      border-radius: 50%;
      color: rgba(0,0,0,.8);
      content: "\76";
      display: flex;
      font-family: "w-icons";
      height: 36px;
      justify-content: center;
      left: 50%;
      margin: 0;
      padding: 0 0 0 2px;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 36px;
      z-index: 3;
    }

    &::after {
      background-color: rgba(0,0,0,0.18);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;
    }
  }
}

.page-search-result-info {
  margin: 0;
  padding: 0;

  @include r(mobile-landscape) {
    width: 100%;
  }
}

.page-search-result-title {
  margin: 0 0 16px 0;
  padding: 0;

  @include r(tablet) {
    @include font-size(2);
    margin-bottom: 14px;
  }

  a {
    color: $gray-smoke;
  }
}

.page-search-result-breadcrumb {
  margin: 0 0 16px 0;
  padding: 0;
  font-size: 0;

  li {
    @include font-size(1.3);
    display: inline-block;
    margin: 0 4px;
    padding: 0;
    vertical-align: middle;
    font-weight: bold;

    @include r(tablet) {
      @include font-size(1.3);
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  i {
    @include font-size(0.8);
    transform: rotate(180deg);

    &::before {
      display: inline-block;
      vertical-align: middle;
      line-height: 0;
    }
  }

  a {

    &:hover {
      color: $green;
    }
  }
}

.page-search-result-content {
  margin: 0;
  padding: 0;

  * {
    margin: 0;
    padding: 0; 
  } 
}

.page-search-result-read-more {
  @include font-size(1.6);
  color: $orange;
  display: inline-block;
  line-height: 26px;

  &:hover {
    color: darken($orange,10%);
  }

  i {
    margin-left: 12px;
    display: inline-block;
    vertical-align: middle;
    line-height: 0;
  }
}