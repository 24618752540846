// Scrolling content
.scrolling-menu-wrapper {
  height: 72px;
  position: relative;
  width: 100%;
  display: block;

  @include r(tablet-landscape) {
    height: 48px;
  }

  &--fixed {
    .scrolling-menu {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
}

.scrolling-menu {
  background: white;
  box-shadow: $shadow-style-card;
  display: block;
  height: 72px;
  padding: 0;
  position: relative;
  width: 100%;
  z-index: 13;

  @include r(tablet-landscape) {
    height: 48px;
  }

  ul {
    align-items: stretch;
    background: white;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0 auto;
    padding: 0;
    transition: .35s all ease-in-out;
    width: 100%;
    max-width: 1110px;

    @include r(tablet-landscape) {
      display: block;
      overflow: hidden;
      position: relative;

      &::after {
        content: "\68";
        position: absolute;
        top: 14px;
        right: 15px;
        z-index: 2;
        pointer-events: none;
        font-family: "w-icons" !important;
      }
    }

    &.open {
      @include r(tablet-landscape) {
        box-shadow: $shadow-style-card;
        height: auto;

        a {
          position: relative;
          opacity: 1;

          &.active {
            color: white;
            background: rgba($gray, .4);
          }
        }
      }
    }
  }

  li {
    margin: 0;
    padding: 0;
    width: 24%;
    display: flex;
    flex-grow : 1;

    @include r(tablet-landscape) {
      width: 100%;
      display: block;
    }
  }

  a {
    @include font-size(1.4);
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 10px;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;
    color: $gray;
    font-weight: 500;
    transition: .01s all ease-in-out;

    @include r(desktop-minimun) {
      @include font-size(1.2);
    }

    @include r(tablet-landscape) {
      display: block;
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;
    }

    &:hover {
      color: $gray;
    }

    &::after {
      background: $yellow;
      bottom: 0;
      content: '';
      height: 3px;
      left: 0;
      opacity: 0;
      position: absolute;
      width: 100%;
      transition: .35s all ease-in-out;

      @include r(tablet-landscape) {
        display: none;
      }
    }

    &.active {

      @include r(tablet-landscape) {
        opacity: 1;
        z-index: 2;
      }

      &::after {
        opacity: 1;
      }
    }
  }
}