.card-full-detail {
    
    h3 {
    	margin-bottom: 40px;

        @include r(tablet-landscape) {
            @include font-size(2.5);
        }
    }

    h4 {
    	@include font-size(2.5);
    	margin-bottom: 16px;
    }

    .text-highlight {
	  margin-bottom: 24px;
	  line-height: 30px;

	  span {
	    color: $gray-smoke;
	  }

	  @include r(tablet-landscape) {
	    @include font-size(2);
	    line-height: 25px;
	  }
	} 
}