.news-full-header {
    .news-full-title {
        margin-top: 32px;
        margin-bottom: 16px;
        font-size: 40px;
        font-weight: bold;
        line-height: 49px;
        color: #6f7271;

        @include r(mobile){
            font-weight: 500;
            line-height: 39px;
            font-size: 32px;
            margin-bottom: 24px;
        }
    }
    .news-full-date {
        margin-bottom: 0;
        color: rgba(111,114,113,0.62);
        font-size: 18px;
        font-weight: 500;
        line-height: 23px;
    }
}