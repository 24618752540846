// Two Columns
.infobox-two-colums {
  align-items: center;
  display: flex;
  margin: 0;
  padding: 0;
  vertical-align: center;
  flex-wrap: wrap;

  .infobox-two-colums-content-left {
    display: flex;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
    width: calc(50% + 15px);
    order: 1;

    @include r(tablet-landscape) {
      order: 2;
      width: 100%;
    }

    .infobox-two-colums-info {
      position: relative;
      z-index: 2;
      width: 100%;
      max-width: 570px;
      padding: 16px 80px 16px 16px;

      @include r(tablet-landscape) {
        max-width: 100%;
        padding: 24px 16px 0 16px;
      }
    }
  }

  .infobox-two-colums-content-right {
    align-items: center;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    margin: 0;
    min-height: 412px;
    padding: 1% 6% 1% 9%;
    position: relative;
    width: calc(50% - 15px);
    order: 2;

    @include r(tablet-landscape) {
      margin: 0 15px;
      min-height: 258px;
      order: 1;
      width: calc(100% - 30px);
      padding: 6.4% 0.66%;
    }

    &::after {
      background-color: rgba(55,56,56,0.75);
      content: '';
      height: 100%;  
      left: 0;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    .infobox-two-colums-info {
      position: relative;
      z-index: 2;

      &.text-italic-light {
        @include font-size(2.5);
        line-height: 38px;

        @include r(tablet-landscape) {
          @include font-size(2);
          line-height: 30px;
        }

        * {
          @include font-size(2.5);
          line-height: 38px;

          @include r(tablet-landscape) {
            @include font-size(2);
            line-height: 30px;
          }
        }
      }
    }
  }
}