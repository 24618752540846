/* ------------------------------------------------------------ */
/*  Main Navigation */
/* ------------------------------------------------------------ */
.site__navigation {
	@include r(tablet-landscape) {
		display: none;
	}
}
.site__main-menu-picker {
	background: rgba(black, .24);
	border-radius: 2px;
	font-size: 0;
	margin: 0 12px 0 0;
	overflow: hidden;
	padding: 0;

	li {
		display: inline-block;
		margin: 0;
		padding: 0;
		vertical-align: middle;

		&.active {
			a {
				background: white;
				color: $gray;
			}
		}
	}

	a {
		@include font-size(1.4);
		color: white;
		display: block;
		font-weight: $font-weight-medium;
		line-height: 32px;
		margin: 0;
		padding: 0 24px;
		transition: all .25s ease-in-out;

		&:hover {
			background: rgba(white, .8);
			color: $gray;
		}

		@include r(desktop-minimun) {
			@include font-size(1.2);
		}
	}
}

.site__main-menu-wrapper {
	background: $green;
	margin: 0;
	padding: 0;
	position: relative;
}

.site__main-menu {
	display: inline-block;
	font-size: 0;
	margin: 0;
	padding: 0;
	vertical-align: middle;

	li {
		display: inline-block;
		margin: 0 12px;
		padding: 0;
		vertical-align: middle;
	}

	a {
		@include font-size(1.6);
		color: white;
		display: block;
		font-weight: $font-weight-medium;
		line-height: 26px;
		margin: 0;
		padding: 15px 0;
		transition: all .25s ease-in-out;

		&:hover {
			color: white;
		}

		@include r(desktop-minimun) {
			@include font-size(1.3);
		}
	}

	.submenu-parent {
		.sub-menu {
			margin-top: 0;
		}
	}
}