.card-elite-full-detail {
    .showcase {

        .showcase__content {
            background: linear-gradient(180deg, rgba(51,51,51,0) 0%,
                        rgba(27,28,28,1) 100%);
            height: 100%;
        }
    }

    .site__content {
        background: $gray_odd;

        h2, h3, h4, h5 {
            color: white;
        }

        h3 {
            @include r(tablet-landscape) {
                @include font-size(2.5);
            }
        }

        p {
            color: rgba(white, .72);
        }
    }

    // Text Variants
    .text-bigger {
      * {
        @include font-size(2);
        line-height: 1.6;
        margin-bottom: 32px;

        @include r(tablet) {
          @include font-size(1.6);
          margin-bottom: 24px;
        }
      }
    }
}