.compromiso-social {
    
    h3 {
        margin-bottom: 24px;

        @include r(tablet-landscape) {
            @include font-size(2.5);
            margin-bottom: 16px;
        }
    }
}