/* ------------------------------------------------------------ */
/*  Vendors */
/* ------------------------------------------------------------ */

// Bootstrap SASS

@import 'bootstrap';


// Iconfont

@import 'web-icons';


// Baron Scroller

@import 'baron-scroller';


// Slick Carousel

@import '../../../node_modules/slick-carousel/slick/slick.scss';