/* ------------------------------------------------------------ */
/*  Help Form */
/* ------------------------------------------------------------ */
.help-form-container {
    border-top:  1px solid rgba(236,236,236,1);
    display: block;
    margin-top: 32px;
    position: relative;
    width: 100%;

    .text-container {
        padding: 32px 0;
    }

    .green-form {
        background: $green-odd;
        color: white;

        * {
            color: white;
        }
    }

    .form-container {
        display: inline-block;
        height: calc(100% + 32px);
        margin: -32px 0 0 0;
        max-width: 376px;
        padding: 32px;
        text-align: left;
        width: 100%;

        @include r(tablet-landscape) {
            height: auto;
            margin: 0;
            max-width: 100%;
            padding: 32px 16px;
        }

        .form-title {
            margin: 0;
        }

        label {
            color: white;
            opacity: .84;
        }

        input,
        select {
            @include font-size(1.6);
            background: transparent;
            border-radius: 0;
            border: none;
            color: white;
            display: block;
            height: 40px;
            margin: 0;
            outline: none;
            padding: 0;
            text-align: left;
            width: 100%;
            opacity: .84;

            &::-webkit-input-placeholder { 
              color: white;
            }
            &::-moz-placeholder { 
              color: white;
            }
            &:-ms-input-placeholder { 
              color: white;
            }
            &:-moz-placeholder { 
              color: white;
            }

            &:-webkit-autofill {
              background: transparent!important;
              color: white;
              -webkit-text-fill-color: $green-odd !important;
              -webkit-box-shadow: 0 0 0 300px $green-odd inset;
            }
        }

        button {
            background: white;
            color: $gray;
            margin: 0;
            padding: 0;
            width: 100%;
        }

        .form-group {
            padding: 0;
            margin: 0 0 8px 0;
            position: relative;

            &::after {
                background: white;
                bottom: 0;
                content: '';
                height: 1px;
                left: 0;
                margin: 0;
                padding: 0;
                position: absolute;
                width: 100%;
            }

            &:last-child {
                padding-top: 8px;
                margin-bottom: 0;
            }
        }

        .form-control__select {
            padding: 0;
            position: relative;

            &::after {
                border-bottom: none;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 5px solid white;
                content: '';
                display: block;
                height: 8px;
                margin: 0;
                opacity: .84;
                padding: 0;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translate(-50%, -50%);
                transition: all .4s ease-in-out;
                width: 10px;
                z-index: 1;
            }

            select {
                @include font-size(1.6);
                appearance: none;
                -moz-appearance: none;
                -webkit-appearance: none;
                font-weight: normal;
                padding-right: 24px;
                position: relative;
                z-index: 2;

                option {
                    background: $green-odd;
                    color: white;
                    padding: 5px;
                }
            }
        }
    }
}   