/* ------------------------------------------------------------ */
/*  Quote List */
/* ------------------------------------------------------------ */
.quote-list-container {
  overflow: hidden;
  position: relative;

  &::after {
    background-image: url('../images/ic_sabias_que_bg.png');
    background-position: center center;
    background-repeat: no-repeat;
    content: '';
    display: block;
    height: 100%;
    left: 60px;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 327px;
    z-index: 0;
  }

  .quote-list-content {
    position: relative;
    z-index: 1;
  }

  @include r(tablet-landscape) {

    &::after {
      width: 172px;
      height: 100%;
      left: 0;
      background-size: 100% auto;
      background-position: -25px -10px;
    }
  }

  // Quote list
  .quote-list {
    margin: 0 0 50px 0!important;
    position: static;

    @include r(tablet-landscape) {
      margin-bottom: 32px!important;
    }

    .quote-title {
      margin-bottom: 32px;
      text-align: center;
      color: white;
      font-weight: bold;
    }

    .quote-content {

      * {
        @include font-size(3.2);
        color: white;
        font-family: $default-font-family-italic-light;
        font-weight: $font-weight-light;
        line-height: 52px;
        margin: 0;
        padding: 0;
        text-align: center;

        @include r(tablet) {
          @include font-size(1.8);
          line-height: 32px;
        }
      }
    }

    .quote-image {
      border-radius: 50%;
      height: 64px;
      margin: 0 auto 16px auto;
      overflow: hidden;
      padding: 0;
      width: 64px;
    }

    .quote-detail {

      .quote-name {
        @include font-size(1.6);
        color: white;
        font-weight: 500;
        margin: 0 0 8px 0;
        padding: 0;
        text-align: center;
      }

      .quote-position {
        @include font-size(1.6);
        color: rgba(white, .72);
        font-weight: normal;
        margin: 0;
        padding: 0;
        text-align: center;
      }
    }
  }

  // Arrows
  .slick-arrow {
    width: 60px;
    height: 60px;
    z-index: 10;
  }

  .slick-prev {
    left: 128px;

    @include r(desktop-minimun) {
      left: 64px;
    }

    @include r(tablet-landscape) {
      display: none!important;
    }

    &::before {
      content: "\46";
      font-family: "w-icons";
      font-size: 40px;
    }
  }

  .slick-next {
    right: 128px;

    @include r(desktop-minimun) {
      right: 64px;
    }

    @include r(tablet-landscape) {
      display: none!important;
    }

    &::before {
      content: "\47";
      font-family: "w-icons";
      font-size: 40px;
    }
  }


  // Dots
  .slick-dots {
    bottom: 48px;
    left: 0;
    position: absolute;
    width: 100%;
    height: 80px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    @include r(tablet-landscape) {
      bottom: 0;
      height: 72px;
    }

    li {
      margin: 0 8px;
      button {
        &::before {
          font-size: 16px;
          color: white;
          transition: all .35s ease-in-out;
        }
      }

      &.slick-active  {
        button {
          &::before {
            color: white;
          }
        }
      }
    }
  }

  // Version 2
  &--v2 {
    background-image: none;

    &::after {
      left: calc(100% - 278px);
      transform: rotate(180deg);
      width: 278px;
      background-position: right center;

      @include r(tablet) {
        width: 172px;
        height: 100%;
        left: calc(100% - 172px);
        background-size: 100% auto;
        background-position: center calc(100% + 11px);
      }
    }

    // Quote list
    .quote-list {
      margin: 0!important;

      .quote-content {

        * {
          @include font-size(2.2);
          line-height: 30px;

          @include r(tablet) {
            @include font-size(1.6);
            line-height: 26px;
          }
        }
      }
    }

    // Dots
    .slick-dots {
      position: relative;
      bottom: 0;
      height: 64px;

      li {
        width: 16px;
        height: 16px;

        button {
          width: 16px;
          height: 16px;
          margin: 0;
          padding: 0;

          &::before {
            width: 16px;
            height: 16px;
            font-size: 12px;
          }
        }
      }
    }
  }

  // Version 3
  &--v3 {
    background-image: none;

    &::after {
      width: 100%;
      height: 1px;
      position: absolute;
      top: 0;
      left: 0;
      background-image: linear-gradient(to left, rgba(168, 171, 170, 0),
                        rgba(161, 164, 163, 0.25) 20%,
                        rgba(139, 142, 141, 0.25) 52%,
                        rgba(120, 123, 122, 0.25) 78%,
                        rgba(111, 114, 113, 0));

      @include r(tablet) {
        background-position: center center;
        background: rgba($gray, .25)
      }
    }

    // Quote list
    .quote-list {
      margin: 0!important;

      .quote-content {
        margin-bottom: 32px;

        * {
          @include font-size(2.5);
          line-height: 1.6;

          @include r(tablet) {
            @include font-size(2.5);
            line-height: 1.6;
          }
        }
      }
    }

    // Dots
    .slick-dots {
      position: relative;
      bottom: 0;
      height: 64px;

      li {
        width: 16px;
        height: 16px;

        button {
          width: 16px;
          height: 16px;
          margin: 0;
          padding: 0;

          &::before {
            width: 16px;
            height: 16px;
            font-size: 12px;
          }
        }
      }
    }
  }
}