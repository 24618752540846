.main-content-block {
  overflow: hidden;
  position: relative;
  z-index: 1;

  .panel-grid-cell,
  .so-panel {
    height: 100%;
    padding: 0 !important;
  }
}

// Text Container
.text-container {
  margin: 0 auto;
  max-width: 820px;
  padding: 220px 0 100px 0;
  position: relative;
  z-index: 3;

  @include r(popular) {
    max-width: 680px;
    padding-bottom: 50px;
    padding-top: 110px;
  }

  @include r(tablet) {
    max-width: 100%;
    padding-left: 70px;
    padding-right: 70px;
  }

  @include r(mobile-landscape) {
    padding-left: 36px;
    padding-right: 36px;
  }

  @include r(mobile) {
    br {
      display: none;
    }
  }

  &--equal-space {
    padding-bottom: 220px;
    padding-top: 220px;

    @include r(popular) {
      padding-bottom: 110px;
      padding-top: 110px;
    }
  }

  &--half-space {
    padding-bottom: 110px;
    padding-top: 110px;
    
    @include r(popular) {
      padding-bottom: 50px;
      padding-top: 50px;
    }
  }
}

// Secondary Text Container
.secondary-text-container {
  margin: 0 auto;
  max-width: 900px;
  padding-bottom: 130px;
  padding-top: 130px;
  position: relative;
  z-index: 3;

  @include r(popular) {
    max-width: 680px;
    padding-bottom: 100px;
    padding-top: 100px;
  }

  @include r(mobile) {
    br {
      display: none;
    }
  }

  * {
    @include font-size(2.4);
    font-family: $default-font-family;
    letter-spacing: 2px;
    margin: 0;
    padding: 0;

    @include r(popular) {
      @include font-size(1.6);
    }
  }

  > .so-widget-sow-editor {
    margin: 0;
    padding: 50px 0;

    @include r(popular) {
      padding: 30px 0;
    }
  }

  // Double Space Line
  &--double-space {
    padding-bottom: 400px;
    padding-top: 400px;

    @include r(popular) {
      padding-bottom: 300px;
      padding-top: 300px;
    }

    @include r(mobile-landscape) {
      padding-bottom: 150px;
      padding-top: 150px;
    }
  }

  // Half Space Line
  &--half-space {
    padding-bottom: 70px;
    padding-top: 70px;

    @include r(popular) {
      padding-bottom: 30px;
      padding-top: 30px;
    }
  }
}

// Secondary Text
.secondary-text {

  @include r(mobile) {
    br {
      display: none;
    }
  }
  
  * {
    @include font-size(2.4);
    font-family: $default-font-family;
    letter-spacing: 2px;
    margin: 0 0 20px 0;
    padding: 0;
    text-transform: uppercase;

    @include r(popular) {
      @include font-size(1.6);
    }
  }
}

// Secondary Font Family 
.secondary-font-family {
  * {
    font-family: $default-font-family;
  }
}


// Centered text 
.mobile-text-center {
  @include r(tablet) {
    * {
      text-align: center!important;
    }
  }

  @include r(mobile) {
    br {
      display: none;
    }
  }
}


// No Margin Text Bottom
.no-margin-bottom {
  
  * {
    margin-bottom: 0;
  }
}


// Image less top
.image-less-top {
  margin-top: -24%;
  z-index: 0;

  @include r(popular) {
    margin-top: -20%;
  }

  @include r(tablet) {
    margin-top: -30%;

    .sow-image-container {
      min-width: 220%;
    }
  }
}


// Image min width
.image-min-width {
  @include r(tablet) {
    overflow: hidden;

    .sow-image-container {
      left: 50%;
      min-width: 150%;
      position: relative;
      transform: translate(-50%,0);
    }
  }
}

// Image max width
.image-max-width {
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;

  @include r(mobile) {
    max-width: 90%;
  }
}

