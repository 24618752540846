/* ------------------------------------------------------------ */
/*  Site search */
/* ------------------------------------------------------------ */

.site__search {
	margin: 0 12px;

	@include r(tablet-landscape) {
		margin: 0;
	}

	// Search Button
	.site__search-button {
		@include font-size(1.8);
		background: transparent;
		border: none;
		color: $gray;
		display: inline-block;
		height: 80px;
		line-height: 80px;
		margin: 0;
		overflow: hidden;
		padding: 0;
		position: relative;
		text-align: center;
		vertical-align: middle;
		width: 24px;

		@include r(tablet-landscape) {
			width: 40px;
		}

		&::after {
			border-bottom: 8px solid $green-dark;
			border-left: 9px solid transparent;
			border-right: 9px solid transparent;
			bottom: 0;
			content: '';
			display: block;
			height: 8px;
			left: 50%;
			margin: 0;
			padding: 0;
			position: absolute;
			transform: translate(-50%, 8px);
			transition: all .4s ease-in-out;
			width: 18px;
		}
	}


	// Clear Search
	.site__search-clear {
		@include font-size(1.4);
		color: white;
		cursor: pointer;
		display: inline-block;
		height: 24px;
		line-height: 24px;
		margin: 0 32px 0 0;
		opacity: 0; 
		padding: 0;
		text-align: center;
		transition: all .4s ease-in-out;
		vertical-align: middle;
		width: 24px;

		* {
			color: white;
		}

		@include r(tablet-landscape) {
			margin: 0;
		}
	}


	// Search Form
	.site__search-form {
		background: $green-dark;
		display: block;
		left: 0;
		margin: -12px 0 0 0;
		max-height: 0;
		overflow: hidden;
		padding: 0;
		position: absolute;
		top: 100%;
		transition: all .4s ease-in-out;	
		width: 100%;
		z-index: 11;

		@include r(tablet-landscape) {
			margin-top: -48px;
		}

		input {
			@include font-size(4);
			background: transparent;
			color: white;
			height: 48px;
			line-height: 48px;
			margin: 0;
			padding: 0;
			position: relative;
			width: 100%;

			@include r(tablet-landscape) {
				@include font-size(2);
				height: 40px;
				line-height: 40px;
			}

			&::-webkit-input-placeholder { 
		      color: rgba(white, .3);
		    }
		    &::-moz-placeholder { 
		      color: rgba(white, .3);
		    }
		    &:-ms-input-placeholder { 
		      color: rgba(white, .3);
		    }
		    &:-moz-placeholder { 
		      color: rgba(white, .3);
		    }
		}

		button {
			@include font-size(1.2);
			background: white;
			border: none;
			color: $gray;
			font-weight: bold;
			letter-spacing: 1px;
			margin: 0;
			opacity: .5;
			padding: 0 30px;
			text-indent: 1px;
			transition: all .4s ease-in-out;

			@include r(tablet-landscape) {
				display: none;
			}
		}
	}


	&--active {

		// Search Button
		.site__search-button {
			&::after {
				transform: translate(-50%, 0);
			}
		}

		// Search Form
		.site__search-form {
			max-height: 1000px;
			padding: 22px 0;

			@include r(tablet-landscape) {
				padding: 16px 0;
			}
		}
	}

	// Focused
	&--focused {

		// Search Form
		.site__search-form {
			
			button {
				opacity: 1;
			}
		}

		// Close Search
		.site__search-clear {
			opacity: 1;
		}
	}
}