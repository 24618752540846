/* ------------------------------------------------------------ */
/*  Gallery Slider */
/* ------------------------------------------------------------ */
.gallery-slider-container {
    display: block;
    position: relative;
    width: 100%;
    overflow: hidden;
    padding: 0;

    .gallery-slider-content {
        display: block;
        margin: 0 0 16px 0;
        padding: 0;
        position: relative;
        width: 100%;
    }

    .gallery-slider-counter {
      @include font-size(1.4);
      background: rgba($gray-smoke,.82);
      border-radius: 2px;
      bottom: 8px;
      color: white;
      left: 8px;
      line-height: 40px;
      min-width: 140px;
      position: absolute;
      text-align: center;
      z-index: 2;
    }

    .gallery-slider {
        display: block;
        margin: 0;
        padding: 0;
        position: relative;
        width: 100%;

        li {
          display: block;
          margin: 0;
          padding: 0; 
        }

        img {
          height: auto;
          margin: 0;
          padding: 0;
          width: 100%;
        }

        // Arrows
        .slick-arrow {
          width: 80px;
          height: 80px;
          z-index: 10;
        }

        .slick-prev {
          left: 0;

          @include r(tablet-landscape) {
            display: none!important;
          }

          &::before {
            content: "\6f";
            font-family: "w-icons";
            font-size: 40px;
          }
        }

        .slick-next {
          right: 0;

          @include r(tablet-landscape) {
            display: none!important;
          }

          &::before {
            content: "\41";
            font-family: "w-icons";
            font-size: 40px;
          }
        }
    }

    .gallery-slider-thumbs {
      margin: 0;
      padding: 0;
      width: 100%;

      .slick-slide {
        margin: 0;
        outline: none;
        padding: 0 8px;
        position: relative;

        &::before {
          background: $yellow;
          bottom: 0;
          content: '';
          height: 3px;
          left: 8px;
          opacity: 0;
          position: absolute;
          transition: all .35s ease-in-out;
          width: calc(100% - 16px);
        }

        img {
          height: auto;
          margin: 0;
          padding: 0;
          width: 100%;
          cursor: pointer;
        }

        &.slick-current {

          &::before {
            opacity: 1;
          }
        }
      }
    }
}   