.product-poll {
    position: relative;
    overflow: hidden;

    &::after {
        background-image: url(../images/bg_pattern.svg);
        background-position: left -32px;
        background-repeat: no-repeat;
        top: 0;
        content: '';
        height: 100%;
        margin: 0;
        padding: 0;
        position: absolute;
        right: 0;
        width: 524px;
        z-index: 0;

        @include r(mobile-landscape) {
            background-position: 288px 62px;
            background-size: contain; 
            top: 0;
            right: 0;
            width: 500px;
        }
    }
}
.poll {
    display: block;
    margin: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    transition: opacity .45s ease-in-out .1s,
                height .1s ease-in-out .5s;
    width: 100%;
    z-index: 1;

    &--active {
        height: auto;
        opacity: 1;
        transition: opacity .45s ease-in-out .2s, 
                    height .1s ease-in-out .1s;
    }

    h3 {
        @include font-size(3.2);
        margin-bottom: 38px;
        color: $gray-smoke;
        font-weight: 500;

        @include r(tablet) {
            @include font-size(2);
            margin-bottom: 32px;
        }
    }

    h4 {
        @include font-size(2.5);
        margin-bottom: 32px;
        color: $gray-smoke;
        font-weight: normal;

        @include r(tablet) {
            @include font-size(1.6);
            margin-bottom: 24px;
        }
    }

    .btn {
        border-radius: 2px;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.06);
        margin: 0 8px;

        @include r(mobile-landscape) {
            margin-bottom: 16px;
        }
    }

    .poll__product-cart {
        margin: 0 0 16px 0;
        padding: 0;
        position: relative;

        .poll__product-image {
            margin: 0;
            padding: 0;
            position: relative;

            &::before {
                background: linear-gradient(180deg, rgba(51,51,51,0) 0%, rgba(55,56,56,0.86) 100%);
                content: '';
                height: 100%;
                left: 0;
                margin: 0;
                padding: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }

            img {
                height: auto;
                width: 100%;
                z-index: 2;
            }
        }

        .poll__product-title {
            @include font-size(2);
            bottom: 0;
            color: white;
            left: 0;
            line-height: 25px;
            margin: 0;
            padding: 24px 22px;
            position: absolute;
            text-align: left;
            width: 100%;
        }
    }
}
