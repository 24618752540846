/* ------------------------------------------------------------ */
/*  Calculator */
/* ------------------------------------------------------------ */

.calculator-form {
  background: $gray-smoke;
  margin: 0;
  padding: 40px;

  @include r(tablet) {
    padding: 24px 16px;
  }

  * {
    color: rgba(white, .75);
  }

  .text--small {
    color: $gray-light;
  }
}


// Calculator Form Header
.calculator-form__header {
  display: block;
  font-size: 0;
  margin: 0 0 26px 0;
  padding: 0;
  text-align: center;

  h4 {
    display: inline-block;
    line-height: normal;
    margin: 0 8px 0 0;
    vertical-align: middle;
  }

  .calculator-form__select {
    display: inline-block;
    max-width: 144px;
    vertical-align: middle;
    position: relative;

    &::after {
      @include font-size(1.8);
      color: $yellow;
      content: "\68";
      font-family: w-icons !important;
      font-style: normal;
      font-weight: normal !important;
      height: 10px;
      line-height: 10px;
      position: absolute;
      right: 0;
      top: 54%;
      transform: translate(0,-50%);
      vertical-align: top;
      width: 24px;
      z-index: 1;

      @include r(tablet) {
        @include font-size(1.4);
      }
    }

    select {
      @include font-size(2.5);
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      background: transparent;
      border: none;
      color: $yellow;
      display: block;
      line-height: normal;
      outline: none;
      padding: 0 32px 0 0;
      position: relative;
      z-index: 2;

      @include r(tablet) {
        @include font-size(2.0);
      }

      option {
        background: $gray-smoke;
        color: $yellow;
      }
    }
  }
}


// Calculator Form content
.calculator-form__content {
  margin: 0;
  padding: 0;

  .calculator-form__field {
    border-bottom: 1px solid rgba(255,255,255,0.10);
    margin-bottom: 16px;
    padding: 0 0 16px 0;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  * {
    margin: 0;
    padding: 0;
  }

  i {
    @include font-size(2.1);
    border: none;
    color: $gray;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    text-align: center;
    vertical-align: middle;
    width: 100%;

    &.w-icon-ic-interest {
       @include font-size(1.3);
    }
  }

  input,
  select {
    @include font-size(1.6);
    background: white;
    border-radius: 2px;
    border: none;
    color: $gray;
    height: 40px;
    line-height: 40px;
    padding: 0 8px;
    text-align: right;
    vertical-align: middle;
    width: 100%;
  }

  select {
    option {
      background: white;
      color: $gray;
    }
  }

  .select-container {
    background: white;
    border-radius: 2px;
    overflow: hidden;
    position: relative;

    select {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background: transparent;
      position: relative;
      z-index: 2;
    }

    &::after {
      @include font-size(1);
      color: $gray;
      content: "\6a";
      font-family: "w-icons" !important;
      margin: 0;
      padding: 0;
      position: absolute;
      right: 0;
      text-align: center;
      top: 50%;
      transform: translate(0,-50%);
      width: 40px;
    }
  }
}


// Calculator result
.calculator-results {
  margin: 0;
  padding: 40px;
  background-color: #F7F7F7;

  &--white {
    background: white;
  }

  @include r(tablet) {
    padding: 24px 16px;
  }


  .calculator-number-wrapper {
    * {
      margin: 0;
      padding: 0; 
    }
  }

  .calculator-number {
    font-weight: bold;
    margin-bottom: 8px;

    @include r(tablet) {
      @include font-size(3.2);
    }
  }

  .separator {
    background-color: rgba($gray, 0.25);
    display: block;
    height: 2px;
    width: 100%;
  }
}