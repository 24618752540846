.page-search-results {

    .page-search-title {
        @include font-size(3.2);
        margin: 0 0 28px 0;
        padding: 0;
        position: relative;
        font-weight: 500;

        @include r(tablet) {
            margin-bottom: 20px;
        }
    }

    .page-search-results-amount {
        margin: 0 0 24px 0;
        padding: 0;
        position: relative;
        display: block;
        font-weight: 500;
    }

    .page-search-results-search-form {
        border-radius: 2px;
        border-bottom: solid 1px rgba(183, 184, 184, .75);;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 0 0 48px 0;

        @include r(tablet) {
            margin-bottom: 40px;
        }

        input {
            @include font-size(2.5);
            background: transparent;
            border: none;
            color: rgba($gray-smoke, .5);
            font-weight: 500;
            line-height: 28px;
            margin: 0;
            padding: 13px 0;
            text-align: left;
            width: calc(100% - 32px);

            @include r(tablet) {
                @include font-size(2);
            }

            &:focus {
              box-shadow: none;
              outline: 0;
            }

            &::-webkit-input-placeholder { 
              color: rgba($gray-smoke, .5);
            }
            &::-moz-placeholder { 
              color: rgba($gray-smoke, .5);
            }
            &:-ms-input-placeholder { 
              color: rgba($gray-smoke, .5);
            }
            &:-moz-placeholder { 
              color: rgba($gray-smoke, .5);
            }

            &:-webkit-autofill {
              background: transparent!important;
              color: rgba($gray-smoke, .5);
              -webkit-text-fill-color: $gray-smoke !important;
              -webkit-box-shadow: 0 0 0 300px $gray-smoke inset;
            }
        }

        button {
            @include font-size(1.9);
            background: transparent;
            border: none;
            color: $gray;
            margin: 0;
            padding: 0;
            text-align: center;
            width: 32px;

            i {
                display: inline-block;
                line-height: 0;
                vertical-align: middle;
            }

            @include r(tablet) {
                @include font-size(1.8);
            }
        }
    }

    .page-search-results-container {
        margin: 0 0 40px 0;
    }
}