/* ---------------------- */
/*  Aligns */
/* ---------------------- */

.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto
}

/* ---------------------- */
/*  Aligns */
/* ---------------------- */

.lc-mpb-0 {

  * {
    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}

/* ------------------------------------------------------------ */
/*  Floats */
/* ------------------------------------------------------------ */

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}


/* --------------------- */
/* Margins and paddings */
/* --------------------- */
.nmgB { margin-bottom: 0;}
.mgB16 { margin-bottom: 16px; }
.mg0  { margin: 0; }
.mg1  { margin: 100px; }
.mgG  { margin: 10px; }
.mgB  { margin-bottom: 10px; }
.mgT  { margin-top: 10px; }
.mgL  { margin-left: 10px; }
.mgR  { margin-right: 10px; }
.mgB15 { margin-bottom: 15px; }
.mgB2 { margin-bottom: 20px; }
.mgB24 { margin-bottom: 24px; }
.mgB3 { margin-bottom: 30px; }
.mgB32 { margin-bottom: 32px; }
.mgB4 { margin-bottom: 40px; }
.mgB5 { margin-bottom: 50px; }
.mgB6 { 
  margin-bottom: 60px;

  @include r(tablet) {
    margin-bottom: 30px;
  }
}
.mgT15 { margin-top: 15px; }
.mgT2 { margin-top: 20px; }
.mgT5 { margin-top: 50px; }
.nmgB { margin-bottom: 0;}
.mgB-sm-32 { 
  @include r(tablet-landscape) {
    margin-bottom: 32px;
  }
}
.mgB-sm-24 { 
  @include r(tablet-landscape) {
    margin-bottom: 24px;
  }
}
.mgB-sm-16 { 
  @include r(tablet-landscape) {
    margin-bottom: 16px;
  }
}
.pd0  { padding: 0; }
.pd1  { padding: 100px; }
.pdG  { padding: 10px; }
.pdB  { padding-bottom: 10px; }
.pdT  { padding-top: 10px; }
.pdL  { padding-left: 10px; }
.pdR  { padding-right: 10px; }
.pdB15 { padding-bottom: 15px; }
.pdB2 { padding-bottom: 20px; }
.pdB5 { padding-bottom: 40px; }
.pdB5 { padding-bottom: 50px; }
.pdT15 { padding-top: 15px; }
.pdT2 { padding-top: 20px; }
.pdT5 { padding-top: 50px; }
.pdT32 { padding-top: 32px; }
.pdT48 { padding-top: 48px; }
.pdTB4 { 
  padding-bottom: 40px;
  padding-top: 40px;

  @include r(tablet-landscape) {
    padding-bottom: 20px;
    padding-top: 20px;
  }
}
.pdTB6 { 
  padding-bottom: 60px;
  padding-top: 60px;

  @include r(tablet-landscape) {
    padding-bottom: 0px;
    padding-top: 0px;
  }
}
.pdTB7 { 
  padding-bottom: 72px;
  padding-top: 72px;

  @include r(tablet-landscape) {
    padding-bottom: 40px;
    padding-top: 40px;
  }
}
.pdTB8 { 
  padding-bottom: 80px;
  padding-top: 80px;

  @include r(tablet-landscape) {
    padding-bottom: 40px;
    padding-top: 40px;
  }
}
.pdTB9 { 
  padding-bottom: 90px;
  padding-top: 90px;

  @include r(tablet-landscape) {
    padding-bottom: 40px;
    padding-top: 40px;
  }
}
.pdB-sm-16 { 
  @include r(tablet-landscape) {
    padding-bottom: 16px;
  }
}

.pdR15 { 
  padding-right: 15px;

  @include r(tablet-landscape) {
    padding-right: 0;
  }
}

.pdL15 { 
  padding-left: 15px;

  @include r(tablet-landscape) {
    padding-left: 0;
  }
}

.pdR24 { 
  padding-right: 24px;

  @include r(tablet-landscape) {
    padding-right: 0;
  }
}

.pdR56 { 
  padding-right: 56px;

  @include r(tablet-landscape) {
    padding-right: 0;
  }
}

.pdL56 { 
  padding-left: 56px;

  @include r(tablet-landscape) {
    padding-left: 0;
  }
}

/* ------------------------------------------------------------ */
/*  Miscellaneous */
/* ------------------------------------------------------------ */
.img-pdR24 {
  padding-right: 24px;

  @include r(tablet-landscape) {
    padding-right: 0;
  }
}
.img-pdL24 {
  padding-left: 24px;

  @include r(tablet-landscape) {
    padding-left: 0;
  }
}
.img-pdR16 {
  padding-right: 16px;
}
.img-pdL16 {
  padding-left: 16px;
}
.img-w-160 {
  img {
    width: 100%;
    max-width: 160px;
  }
}
.no-border { border: 0 !important; }
.no-comments { display: none; }
.flex-grow-2 { flex-grow: 2; }
.showcase_mobile-text-container {

  &--border {
    position: relative;

    @include r(tablet-landscape) {

      &::after {
          background-color: rgba(236,236,236,1);
          bottom: 0;
          content: '';
          height: 1px;
          left: 0;
          margin: 0;
          padding: 0;
          position: absolute;
          width: 100%;
      }
    }

    .showcase_mobile-text {
      padding: 24px 0;
    }
  }
}
.showcase_mobile-text {
  padding: 16px 0 0 0;

  * {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .mgb {
    @include r(tablet) {
      margin-bottom: 24px;
    }
  }
}

.text-decoration {
  text-decoration: none;
  list-style: none;
}

.slick-list {
  overflow: hidden;
}

.overflow-hidden {
  overflow: hidden;
}

.buttons {

  .btn {
    margin: 0 8px;

    @include r(mobile) {
      margin: 0 8px 16px 8px;
    }

    &:first-child {
      margin-left: 0;

      @include r(mobile) {
        margin-left: 8px;
      }
    }

    &:last-child {
      margin-right: 0;

      @include r(mobile) {
        margin-right: 8px;
        margin-bottom: 0;
      }
    }
  }
}

/* ---------------------- */
/*  Backgrounds */
/* ---------------------- */
.background--yellow { background-color: $yellow; }
.background--yellow-light { 
  position: relative;
  background-color: $yellow-light; 
  &-icon {
    width: 52%;
    height: 65%;
    position: absolute;
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: container;
    opacity: 0.5;

    @include r(tablet) {
      position: absolute;
      background-position: right bottom;
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0.5;  
      width: 95%;
      height: 45%;
    }
  }
}

.background--green { 
  background-color: $green; 
  
  .image {
    background-position: center center;
    background-size: contain; 
    background-repeat: no-repeat;
  }
}
.background--gray { background-color: $gray-background; }
.background--gray-smoke { background-color: $gray-smoke; }
.background--gray-dark { background-color: $gray-dark; }
.background--gray-light-odd { background-color: $gray-light-odd; }

// BAckground positions
.background-top {
  background-position: top center;
  background-repeat: no-repeat!important;
  background-size: 100% auto;
}

.background-bottom {
  background-position: bottom center;
  background-repeat: no-repeat!important;
  background-size: 100% auto;
}

.background-center {
  background-position: center center;
  background-repeat: no-repeat!important;
  background-size: 100% auto;
}


// Background size
.background-repeat-y {
  background-position: center center;
  background-repeat: repeat-y!important;
  background-size: 100% auto;

  &.panel-row-style {
    background-repeat: repeat-y!important;
  }
}

.background-size-contain {
  background-size: cover;

  @include r(tablet) {
    background-size: contain;
  }
}

// radius
.br2 {
  border-radius: 2px;
}

.bs-img {
	box-shadow: $shadow-style-card;
}

.mgb-img {

  @include r(tablet-landscape) {
    margin-bottom: 24px;
  }
}

.img-none {
  opacity: 0;
}

// Longshadow
//==========
@mixin longshadow($hue) {
  $ts: ();
  @for $i from 1 through 25 {
    $ts: $ts, $i*2px $i*2px $i*2px $i*-1px hsl(100 + $i*10, 0%, 78% + $i);          
  }

  box-shadow: $ts;
}


.img-box-shadow {
  @include longshadow(10);
  border-radius: 6.5%;
  display: inline-block;
  margin: 0 0 28px 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  transition: all .35s ease-in-out;

  @include r(tablet-landscape) {
    margin-bottom: 40px;
    max-width: calc(74% + 30px);
  }
}

.img-box-shadow-hover {
  border-radius: 6.5%;
  display: inline-block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  transition: all .35s ease-in-out;
  transform: translateY(0);

  &:hover {
    @include longshadow(10);
    transform: translateY(-10px);

    @include r(tablet-landscape) {
      transform: translateY(0);
    }
  }

  @include r(tablet-landscape) {
    @include longshadow(10);
    margin-bottom: 40px;
    max-width: calc(74% + 30px);
  }
}