/* ------------------------------------------------------------ */
/*  Social commitment */
/* ------------------------------------------------------------ */
.social-commitment {

    .social-commitment-title {
        margin: 0 0 32px 0;
        @include font-size(3.2);

        @include r(tablet-landscape) {
            @include font-size(3.2);
            margin-bottom: 24px;
        }
    }
}   