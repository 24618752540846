/* ------------------------------------------------------------ */
/*  News card */
/* ------------------------------------------------------------ */
.sala-de-prensa-bg {
  position: relative;
  overflow: hidden;

  * > {
    z-index: 1;
  }

  &::after {
    background-image: url(../images/bg_pattern.svg);
    background-position: left top;
    background-repeat: no-repeat;
    bottom: 0;
    content: '';
    height: calc(100% - 90px);
    margin: 0;
    opacity: .5;
    padding: 0;
    position: absolute;
    right: 0;
    width: 540px;
    z-index: 0;

    @include r(tablet) {
      bottom: -191px;
      right: -207px;
      height: 327px;
      width: 500px;
      background-size: contain;
    }
  }
}

// News Card
.news-card {
  margin-bottom: 32px;
  position: relative;
  z-index: 1;
  border-radius: 2px;
  overflow: hidden;
  height: calc(100% - 32px);
  box-shadow: $shadow-style-card;

  &--no-border {
    padding: 0;
    border-radius: 0;
    box-shadow: 0 0 0 0 transparent;

    .news-card__image {
      border-radius: 2px;
      overflow: hidden;
    }
  }

  .news-card__image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    margin: 0 16px 0 0;
    overflow: hidden;
    padding: 0;

    &-prensa {
      @extend .news-card__image;
      margin: 0;
    }

    img {
      opacity: 0;
    }
  }

  .news-card__info {
    margin-bottom: 16px;

    * {
      margin-bottom: 0;
      padding-bottom: 0;

      @include r(tablet) {
        @include font-size(1.3);
        line-height: 22px;
      }
    }
  }

  .news-card__title {
    font-weight: bold;
    margin-bottom: 16px;

    a:hover {
      color: $green;
    }

    @include r(tablet) {
      @include font-size(1.6);
      line-height: normal;
      margin-bottom: 6px;

      * {
        @include font-size(1.6);
        line-height: normal;
      }
    }

    @include r(mobile) {
      font-size: 20px;
      margin-bottom: 16px;  
    }
  }

  .news-card__read-more {
    color: $orange;
    @include font-size(1.6);

    i {
      margin-left: 8px;
      font-size: 14px;

      @include r(tablet) {
        @include font-size(1);
      }
    }

    @include r(tablet) {
      @include font-size(1.3);
    }
  }

  .news-card__white-bg {
    background: white;
    padding: 32px;

    .news-card__info {
      
      * {
        
        @include r(tablet) {
          @include font-size(1.6);
          line-height: 27px;
        }
      }
    }

    .news-card__title {
      @include r(tablet) {
        margin-bottom: 16px;
        @include font-size(2);

        * {
          @include font-size(2);
        }
      }
    }

    .news-card__read-more {

      i {
        @include r(tablet) {
          @include font-size(1.5);
        }
      }

      @include r(tablet) {
        @include font-size(1.6);
      }
    }
  }
}

.news-card-slider {
  @extend .news-card;
  
  @include r(tablet-landscape) {
    background-color: white;
    padding: 32px;
    width: 400px;
  }

  @include r(mobile-landscape) {
    width: 320px;
  }
}