/* ------------------------------------------------------------ */
/*  Settings */
/* ------------------------------------------------------------ */


/* ---------------------- */
/*  Color palettes */
/* ---------------------- */

//Gray Scale (gray)
$gray-lighter        	: rgba(253,253,253,1);
$gray-background-variant: rgba(0,0,0,0.08);
$gray-background        : rgba(247,247,247,1);
$gray-light             : rgba(147,149,148,1);
$gray                   : rgba(111,114,113,1);
$gray-smoke             : rgba(55,56,56,1);
$gray-shadow            : rgba(0,0,0,0.24);
$gray-medium            : rgba(0,0,0,0.54);
$gray-dark              : rgba(74,77,77,1);
$gray-hover             : rgba(82,84,83,1);
$gray-odd				: rgba(27,28,28,1);
$gray-light-odd			: rgba(36,37,37,1);


//Greens
$green-odd	            : rgba(128,188,0,1);
$green		            : rgba(0,104,53,1);
$green-dark	            : rgba(0,77,39,1);
$green-hover            : rgba(0,77,39,1);


// Yellows
$yellow					: rgba(255,209,0,1);
$yellow-light			: rgba(255,209,0,0.75);
$yellow-hover			: rgba(189,154,0,1);

// Others
$orange					: rgba(255,131,0,1);
$red                    : rgba(218,35,44,1);
$white-hover			: rgba(214,214,214,1);

/* ---------------------- */
/*  Fonts */
/* ---------------------- */
@include font-face(HelveticaNeue, '../fonts/HelveticaNeue');
@include font-face(HelveticaNeue, '../fonts/HelveticaNeue-Bold', bold);
@include font-face(HelveticaNeue, '../fonts/HelveticaNeue-Medium', 500);
@include font-face(HelveticaNeueItalic, '../fonts/HelveticaNeue-Italic');
@include font-face(HelveticaNeueLightItalic, '../fonts/HelveticaNeue-LightItalic');

$helveticaNeue			: 'HelveticaNeue', Helvetica, sans-serif;
$helveticaNeueItalic	: 'HelveticaNeueItalic', Helvetica, sans-serif;
$helveticaNeueLightItalic	: 'HelveticaNeueLightItalic', Helvetica, sans-serif;



/* ------------------------------------------------------------ */
/*  Defaults */
/* ------------------------------------------------------------ */


// Colors

$default-text-color     : $gray;
$primary-main-color     : $gray;
$secondary-main-color   : $yellow;
$tertiary-main-color    : $red;


// Fonts

$default-font-family    : $helveticaNeue;
$default-font-size      : 1.6;
$default-font-family-italic-light   : $helveticaNeueLightItalic;
$default-font-family-italic   : $helveticaNeueItalic;

$font-weight-light      : 300;
$font-weight-regular    : normal;
$font-weight-medium		: 500;
$font-weight-semibold   : 600;
$font-weight-bold       : bold;
// $font-weight-bolder     : 800;

$default-font-weight    : $font-weight-regular;


//Transitions

$hover-transition-default    : background .25s ease-in-out;
$border-color-transition     : border-color .25s ease-in-out;
$border-width-transition     : border-size .005s ease-in-out;


// Shadows

$shadow-style-card             : 0 2px 2px 0 rgba(0,0,0,0.06);
$shadow-style-card-back        : 0px 2px 12px 15px rgba(0, 0, 0, 0.2);
$shadow-style-growler          : 0px 3px 15px 0px rgba(0, 0, 0, 0.2);
$shadow-style-user-img         : 0px 4px 15px 0px rgba(0,0,0,0.25);
$shadow-style-sidebar-right    : -5px 0px 15px -5px rgba(0,0,0,0.25);
$shadow-style-sidebar-left     : 5px 0px 15px -5px rgba(0, 0, 0, 0.25);