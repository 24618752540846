/* ---------------------- */
/*  Timeline */
/* ---------------------- */
.timeline-carousel {
	padding: 0;
	margin: 40px 0 0 0;
	overflow: hidden;
	width: 100%;

	&.slick-dotted.slick-slider {
		margin-bottom: -24px;
	}

	@include r(tablet) {
		padding-bottom: 50px;
	}

	.timeline-spot {
		@extend %timeline-spot;
	}

	.timeline-spot-container {
		@extend %timeline-spot-container;
	}

	.timeline-spot-time {
		@extend %timeline-spot-time;
	}

	.timeline-spot-content {
		@extend %timeline-spot-content;
	}

	// Slick Container
	.slick-list {
		padding: 0 144px!important;
		position: relative;

		@include r(mobile-landscape) {
			padding: 1.5px 0 0 0!important;
			width: 100%;
    		margin: 0;
		}

		&::before {
			background: linear-gradient(-90deg, rgba(255,255,255,0) 0%, #FFFFFF 100%);
			content: '';
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 144px;
			z-index: 10;

			@include r(mobile-landscape) {
				width: 72px;
				height: 77px;
			}
		}

		&::after {
			background: linear-gradient(90deg, rgba(255,255,255,0) 0%, #FFFFFF 100%);
			content: '';
			height: 100%;
			position: absolute;
			right: 0;
			top: 0;
			width: 144px;
			z-index: 10;

			@include r(mobile-landscape) {
				width: 72px;
				height: 77px;
			}
		}
	}

	//Buttons
	.slick-arrow,
	.slick-arrow:hover,
	.slick-arrow:focus {
		background: $orange;
		border-radius: 50%;
		height: 56px;
		opacity: 1;
		overflow: hidden;
		transition: all .35s ease-in-out;
		width: 56px;
		z-index: 11;
		box-shadow: 0 2px 2px 0 rgba(0,0,0,0.06);
	}
	.slick-prev {
		left: 48px;

		&::before {
			content: "\6f";
			font-family: "w-icons";
			font-size: 16px;
		}
	}
	.slick-next {
		right: 48px;

		&::before {
			content: "\41";
			font-family: "w-icons";
			font-size: 16px;
		}
	}

	.slick-disabled,
	.slick-disabled:hover,
	.slick-disabled:focus {
		opacity: 0;
	}

	// Dots
	.slick-dots {
		bottom: 0;
		left: 0;
		position: absolute;
		width: 100%;
		min-height: 50px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;

		li {
			margin: 0 8px;
			button {
				&::before {
					font-size: 16px;
					color: $gray;
					transition: all .35s ease-in-out;
				}
			}

			&.slick-active  {
				button {
					&::before {
						color: $gray;
					}
				}
			}
		}
	}
}

// Timeline Item
%timeline-spot {
	align-items: flex-end;
	display: flex!important;
	height: 588px;
	outline: none;
	position: relative;
	width: 270px;

	@include r(mobile-landscape) {
		height: auto;
		width: 100%;
	}

	// Not Pair Items
	&:nth-child(even) {
		align-items: flex-end;

		.timeline-spot-container {
			position: relative;

			&::before {
				content: '';
				height: 3px;
				background-color: #DADBDB;
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				transform: translateY(-50%);

				@include r(mobile-landscape) {
					display: block;
					transform: translateY(0);
				}
			}
		}

		.timeline-spot-time {
			padding-top: 46px;

			@include r(mobile-landscape) {
				padding-top: 46px;
				padding-bottom: 24px;
			}

			&::before {
				top: 0;
			}
		}

		.timeline-spot-content {
			bottom: 0;
		}
	}

	// Pair Items
	&:nth-child(odd) {
		align-items: flex-start;
		width: 500px;

		.timeline-spot-container {
			position: relative;

			&::before {
				content: '';
				height: 3px;
				background-color: #DADBDB;
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				transform: translateY(50%);

				@include r(mobile-landscape) {
					bottom: calc(100% - 3px);
					transform: translateY(0);
				}
			}
		}

		.timeline-spot-time {
			padding-bottom: 46px;
			align-self: flex-end;
			text-align: center;

			@include r(mobile-landscape) {
				padding-top: 46px;
				padding-bottom: 24px;
			}

			&::before {
				bottom: 0;

				@include r(mobile-landscape) {
					bottom: calc(100% - 32px);
				}
			}
		}

		.timeline-spot-content {
			top: 0;
		}
	}

	// First Child 
	&:first-child {
		position: relative;

		&::before {
			background-color: #DADBDB;
			border-radius: 1.5px;
			content: '';
			height: 17px;
			left: 0;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 3px;

			@include r(mobile-landscape) {
				display: none;
			}
		}

		.timeline-spot-container {
			&::before {
				border-radius: 1.5px 0 0 1.5px;

				@include r(mobile-landscape) {
					left: 50%;
					top: 0;
					width: 50%;
					transform: translateY(0) translateX(0);
				}
			}
		}
	}

	// Last Child 
	&:last-child {
		&::before {
			background-color: #DADBDB;
			border-radius: 1.5px;
			content: '';
			height: 17px;
			right: 0;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 3px;

			@include r(mobile-landscape) {
				display: none;
			}
		}

		.timeline-spot-container {
			&::before {
				border-radius: 0 1.5px 1.5px 0;

				@include r(mobile-landscape) {
					right: 50%;
					top: 0;
					width: 50%;
					transform: translateY(0) translateX(0);
				}
			}
		}
	}
}

%timeline-spot-container {
	display: flex;
	height: 50%;
	position: relative;
	width: 100%;

	@include r(mobile-landscape) {
		display: block;
		height: 100%;
	}
}

%timeline-spot-time {
	@include font-size(2.5);
	align-self: flex-start;
	font-weight: 500;
	position: relative;
	text-align: center;
	width: 100%;

	&:before {
		background-color: #DADBDB;
		border-radius: 1.5px;
		content: '';
		height: 32px;
		left: 50%;
		position: absolute;
		transform: translateX(-50%);
		width: 3px;
	}
}

%timeline-spot-content {
	align-items: center;
	background-color: $gray-background;
	border-radius: 2px;
	display: flex;
	height: 194px;
	justify-content: center;
	left: 50%;
	overflow: hidden;
	position: absolute;
	transform: translateX(-50%);
	width: 562px;

	@include r(mobile-landscape) {
		display: block;
		height: auto;
		left: 0;
		margin: 0 24px;
		position: relative;
		transform: translateX(0);
		width: calc(100% - 48px);
	}

	.timeline-spot-image {
		@extend %timeline-spot-image;
	}

	.timeline-spot-info {
		@extend %timeline-spot-info;
	}
}

%timeline-spot-image {
	background-size: cover;
	height: 100%;
	width: 226px;

	img {
		height: auto;
		margin: 0;
		padding: 0;
		width: 100%;
		opacity: 0;
	}

	@include r(mobile-landscape) {
		width: 100%;
	}
}

%timeline-spot-info {
	height: 100%;
	max-height: 194px;
	overflow: hidden;
	padding: 16px 24px;
	width: 336px;

	@include r(mobile-landscape) {
		width: 100%;
		max-height: 1000px;
		padding: 16px;
	}

	* {
		margin-bottom: 0;
		padding-bottom: 0;
	}
}