/* ---------------------- */
/*  Promotions */
/* ---------------------- */
.promotions-list {
	overflow: hidden;

  @include r(mobile-landscape) {
    margin: 0!important;
    padding: 0 0 50px 0;
    width: 100%;

    // Dots
    .slick-dots {
      align-items: center;
      bottom: 0;
      display: flex;
      flex-wrap: wrap;
      height: 50px;
      justify-content: center;
      left: 0;
      position: absolute;
      width: 100%;

      li {
        margin: 0 8px;

        button {

          &::before {
            color: $gray;
            font-size: 16px;
            transition: all .35s ease-in-out;
          }
        }

        &.slick-active  {

          button {

            &::before {
              color: $gray;
            }
          }
        }
      }
    }
  }
}

.promotion__card {
  border-radius: 2px;
  box-shadow: $shadow-style-card;
  margin: 0 0 32px 0;
  overflow: hidden;
  padding: 0;

  @include r(mobile-landscape) {
    margin-bottom: 2px;
  }

  a {
    display: block;
    margin: 0;
    padding: 0;
  }

  img {
    height: auto;
    width: 100%;
  }
}