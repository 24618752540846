.site__footer {
	background: #F7F7F7;
	margin: 0;
	padding: 48px 0 0 0;
}

// Logo Footer
.site__footer-logo {
	display: inline-block;
	margin: 0;
	max-width: 208px;
	padding: 0;

	@include r(mobile) {
		margin-bottom: 16px;
	}
}


// Social Networks
.footer__social-networks {
	margin: 0;
	padding: 0;
	display: block;
	font-size: 0;

	li {
		margin: 0 16px 16px 0;
		padding: 0;
		display: inline-block;

		&:last-child {
			margin-right: 0;
		}
	}

	a {
		border-radius: 50%;
		border: 1px solid #B7B8B8;
		color: $gray;
		display: inline-block;
		font-size: 18px;
		height: 40px;
		line-height: 40px;
		margin: 0;
		padding: 0;
		text-align: center;
		transition: all .35s ease-in-out;
		vertical-align: middle;
		width: 40px;

		&:hover {
			color: white;
			background: $gray;
		}
	}

	i {
		line-height: 38px;
	}
}


// Footer Bottom container
.footer-bottom {
	align-items: center;
	border-top: 1px solid rgba($gray,0.25);
	display: flex;
	height: 84px;
	justify-content: space-between;
	padding-bottom: 26px;
	padding-top: 26px;
	width: 100%;

	@include r(mobile) {
		height: auto;
		display: block;
		padding-bottom: 48px;
		text-align: center;
	}
}

// Copyright
.site__copyright {
	@include r(mobile) {
		display: block;
	}

	a {
		&:hover {
			color: $green-dark;
		}
	}
}