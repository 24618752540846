/* ------------------------------------------------------------ */
/*  Typography */
/* ------------------------------------------------------------ */

body,
button,
input,
select,
textarea {
  color: $default-text-color;
  font-family: $default-font-family;

  @include font-size(1.6);
  line-height: 2rem;
  font-weight: $font-weight-regular;
  font-style: normal;

  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: "pnum";
  font-variant-numeric: proportional-nums;

  @include r(popular-down) {
    @include font-size(1.4);
  }
}


// Headings
h1, h2, h3, h4, h5, h6 {
  font-family: $default-font-family;
  font-weight: $font-weight-medium;
}


h1, h2, h3, h4 {
  color: $gray;
}

h1 {
  @include font-size(5.6);
  margin-bottom: 40px;
}

h2 {
  @include font-size(4);
  font-weight: $font-weight-semibold;
  margin-bottom: 50px;

  @include r(tablet) {
    @include font-size(3);
  }

  @include r(mobile) {
    @include font-size(2);
  }
}

h3 {
  @include font-size(3.2);
  margin-bottom: 24px;

  @include r(tablet) {
    @include font-size(2);
    margin-bottom: 16px;
  }
}

h4 {
  @include font-size(2.5);
  margin-bottom: 16px;

  @include r(tablet) {
    @include font-size(2.0);
  }
}

h5 {
  @include font-size(2.0);

  @include r(tablet) {
    @include font-size(1.7);
  }

  @include r(mobile) {
    @include font-size(1.4);
  }
}

h6 {
  @include font-size(1.8);

  @include r(mobile) {
    @include font-size(1.3);
  }
}

// Paragraph and list items
p {
  margin-bottom: 24px;
}
p,
li {
  line-height: 2.7rem;
}

// List spacing
.ul-li-pdTB8 {
  margin: 0;
  padding: 0 0 0 18px;

  li {
    padding: 8px 0;
  }
}


// Text Sizes

.fs-56 { @include font-size(5.6) }
.fs-48 { @include font-size(4.8) }
.fs-36 { @include font-size(3.6) }
.fs-28 { @include font-size(2.8) }
.fs-20 { @include font-size(2.0) }
.fs-18 { @include font-size(1.8) }
.fs-16 { @include font-size(1.6) }


// Text Heading

.text--heading { @include font-size(4.8); }
.text--heading-bigger { @include font-size(5.6); }
.text--heading-smaller {
  @include font-size(2.0);
  line-height: 0.83;
  letter-spacing: 0.6px;
}


// Text Positions

.text--align-left { text-align: left; }
.text--align-right { text-align: right; }
.text--align-center { text-align: center; }


// Text Variants
.text-bigger {
  * {
    @include font-size(1.8);
    line-height: 30px;

    @include r(tablet) {
      @include font-size(1.6);
      line-height: 27px;
    }
  }
}
.text--underline { text-decoration: underline; }
.text--uppercase { text-transform: uppercase; }
.text--small {
  @include font-size(1.3);
  line-height: 22px;
}
.text-italic-light {
  margin: 0;
  padding: 40px 20px;

  * {
    @include font-size(3.2);
    font-family: $default-font-family-italic-light;
    font-weight: $font-weight-light;
    line-height: 52px;
    margin: 0;
    padding: 0;

    @include r(tablet) {
      @include font-size(1.8);
      line-height: 32px;
    }
  }

  &--smaller {
    margin: 0;
    padding: 20px 10px;

    * {
      @include font-size(2.5);
      font-family: $default-font-family-italic-light;
      font-weight: $font-weight-light;
      line-height: 35px;
      margin: 0;
      padding: 0;

      @include r(tablet) {
        @include font-size(2);
        line-height: 30px;
      }
    }
  }
}


//  Text colors

.text-white { 
  color: white;

  * {
    color: white;
  }
}
.text-black { color: black; }
.text-gray-light { color: $gray-light; }
.text-gray-dark { color: $gray-dark; }
.text-gray-smoke { 
  color: $gray-smoke;

  * {
    color: $gray-smoke;
  }
}
.text-green { color: $green; }
.text-yellow { color: $yellow; }
.text-highlight {
  margin-bottom: 24px;
  line-height: 30px;

  span {
    color: $gray-smoke;
  }

  @include r(tablet-landscape) {
    @include font-size(2);
    line-height: 25px;
  }
}
    

// Display

.display--inline-block { display: inline-block; }
.vertical-align--middle { vertical-align: middle; }