/* ------------------------------------------------------------ */
/*  members card */
/* ------------------------------------------------------------ */
.members-card {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: calc(100% - 32px);
    justify-content: space-between;
    margin: 0 0 32px 0;
    padding: 0;
    position: relative;
    width: 100%;

    .members-card__image {
        display: block;
        margin: 0;
        padding: 0;
        width: 100%;

        img {
            height: auto;
            margin: 0;
            padding: 0;
            width: 100%;
        }
    }

    .members-card__info {
        align-items: flex-start;
        background: $gray-background;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0;
        padding: 16px;
    }

    .members-card__name {
        @include font-size(2);
        margin: 0;
        padding: 0;
    }

    .members-card__charge {
        @include font-size(1.6);
        display: block;
        line-height: 27px;
        margin: 0 0 8px 0; 
    }

    .members-card__read-more {
        @include font-size(1.6);
        color: $orange;
        outline: none;
        i {
            margin-left: 8px;
            @include font-size(1.2);
        }
    }
}  

// Modal Member
.modal-member {

    .modal-header {
        margin: 0;
        padding: 0;
        position: relative;
        flex-wrap: wrap;
    }

    .modal-body {
        margin: 0;
        padding: 0;
    }

    .members-modal__image {
        margin: 0;
        padding: 0;
        width: 100%;

        img {
            height: auto;
            margin: 0;
            padding: 0;
            width: 100%;
        }
    }

    .members-modal__info {
        margin: 0;
        padding: 24px;
        width: 100%;
        background: $gray-lighter;

        @include r(tablet) {
          padding: 16px;
        }
    }

    .member-name {
        margin: 0 0 8px 0;
        padding: 0;

        @include r(tablet) {
          @include font-size(2);
        }
    }

    .member-position {
        @include font-size(2.5);
        display: block;
        margin: 0;
        padding:0 0 17px 0;
        position: relative;
        width: 100%;

        @include r(tablet) {
          @include font-size(1.6);
        }

        &::after {
          background: $yellow;
          bottom: 0;
          content: '';
          height: 2px;
          left: 0;
          margin: 0;
          padding: 0;
          position: absolute;
          width: 16px;
        }   
    }

    .members-modal--wrapper {
        height: 340px;
        overflow: hidden;
        padding: 0 24px;
        transition: all .35s ease-in-out;

        @include r(tablet) {
          padding: 0 16px;
        }

        @include r(mobile) {
          height: 300px;
        }

        &.scrolling {
            box-shadow:inset 0 2px 2px 0 rgba(0,0,0,0.16);
        }
        

        .baron__scroller {
            width: 100%!important;
            min-width: 100%!important;
        }

        .baron__track {
            border-radius: 1px;
            bottom: 0;
            height: calc(100% - 20px);
            right: 10px;
            top: 10px;
            width: 2px;
            overflow: hidden;
        }
    }
}