/* ------------------------------------------------------------ */
/*  Exe card */
/* ------------------------------------------------------------ */
.exe-card {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: calc(100% - 40px);
    justify-content: space-between;
    margin: 0 0 40px 0;
    padding: 0;
    position: relative;
    width: 100%;

    .exe-card-image {
        border-radius: 2px;
        display: flex;
        margin: 0;
        overflow: hidden;
        padding: 0;
        position: relative;
        width: 100%;

        img {
            height: auto;
            margin: 0;
            position: relative;
            width: 100%;
        }
    }

    .exe-card-info {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .exe-card-title {
        @include font-size(2);
        font-weight: bold;
        margin: 0;
        padding: 16px 0;
    }

    .exe-card-content {
      margin: 0 0 8px 0;
      padding: 0;
      overflow: hidden;
      flex-grow: 1;

      * {
        &:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
        } 
      }
    }

    .exe-card-more-info {
        @include font-size(1.6);
        align-items: center;
        align-self: flex-start;
        color: $orange;
        display: inline-flex;
        margin: 0;
        padding: 0;
        transition: all .35s ease-in-out;
        z-index: 2;

        i {
            @include font-size(1.6);
            align-items: center;
            color: $orange;
            display: flex;
            justify-content: center;
            margin-left: 8px;
            transition: all .35s ease-in-out;
            vertical-align: middle;
        }
    }
}   