@charset "UTF-8";

@font-face {
  font-family: "w-icons";
  src:url("../fonts/w-icons.eot");
  src:url("../fonts/w-icons.eot?#iefix") format("embedded-opentype"),
    url("../fonts/w-icons.woff") format("woff"),
    url("../fonts/w-icons.ttf") format("truetype"),
    url("../fonts/w-icons.svg#w-icons") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "w-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  vertical-align: top;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="w-icon-"]:before,
[class*=" w-icon-"]:before {
  font-family: "w-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.w-icon {
	display: inline-block;
}
.w-icon-ic-arrowback:before {
  content: "\61";
}
.w-icon-ic-arrowdown:before {
  content: "\62";
}
.w-icon-ic-arrowright:before {
  content: "\63";
}
.w-icon-ic-call:before {
  content: "\64";
}
.w-icon-ic-cancel:before {
  content: "\65";
}
.w-icon-ic-checkbox-checked:before {
  content: "\66";
}
.w-icon-ic-checkbox-unchecked:before {
  content: "\67";
}
.w-icon-ic-down:before {
  content: "\68";
}
.w-icon-ic-download:before {
  content: "\69";
}
.w-icon-ic-dropdown:before {
  content: "\6a";
}
.w-icon-ic-facebook:before {
  content: "\6b";
}
.w-icon-ic-id:before {
  content: "\6c";
}
.w-icon-ic-instagram:before {
  content: "\6d";
}
.w-icon-ic-interest:before {
  content: "\6e";
}
.w-icon-ic-left:before {
  content: "\6f";
}
.w-icon-ic-linkedin:before {
  content: "\70";
}
.w-icon-ic-menu:before {
  content: "\71";
}
.w-icon-ic-message:before {
  content: "\72";
}
.w-icon-ic-money:before {
  content: "\73";
}
.w-icon-ic-person:before {
  content: "\74";
}
.w-icon-ic-pint:before {
  content: "\75";
}
.w-icon-ic-play:before {
  content: "\76";
}
.w-icon-ic-plus:before {
  content: "\77";
}
.w-icon-ic-question:before {
  content: "\78";
}
.w-icon-ic-radiobutton:before {
  content: "\79";
}
.w-icon-ic-radiobutton-unchecked:before {
  content: "\7a";
}
.w-icon-ic-right:before {
  content: "\41";
}
.w-icon-ic-search:before {
  content: "\42";
}
.w-icon-ic-twitter:before {
  content: "\43";
}
.w-icon-ic-youtube:before {
  content: "\44";
}
.w-icon-ic-checked:before {
  content: "\45";
}
.w-icon-ic-arrowdown-white-left:before {
  content: "\46";
}
.w-icon-ic-arrowdown-white-right:before {
  content: "\47";
}