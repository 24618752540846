/* ------------------------------------------------------------ */
/*  Base */
/* ------------------------------------------------------------ */

html {
  font-size: 62.5%;
}

html,
body {
  height: 100%;
}

body {
  background: white;
}

/* ------------------------------------------------------------ */
/*  Links */
/* ------------------------------------------------------------ */

a {
  color: $default-text-color;
  transition: all .5s ease-in-out;
  
  &:hover,
  &:active {
    color: lighten($secondary-main-color, 10%);
    text-decoration: none;
  }

  &:focus,
  &:focus:active {
    color: $default-text-color;
  }
}

b, strong {
  font-weight: $font-weight-bold;
}

img {
 max-width: 100%;
}

