/* ------------------------------------------------------------ */
/*  Tarjeta teaser list */
/* ------------------------------------------------------------ */
.tarjeta-teaser-list {
    p {
        margin-bottom: 16px;
    }

    ul {
        margin: 0 0 16px 18px;
        padding: 0;

        li {
            line-height: 1.69;
            margin: 0;
            padding: 0 0 8px 0;
        }
    }

    &:first-child {
        padding-top: 40px;

        .tarjetas-teaser {

            &:first-child {
                padding-top: 0;
            }
        } 
    }

    &:last-child {
        padding-bottom: 40px;
        
        .tarjetas-teaser {

            &:last-child {
                padding-bottom: 0;

                &::after {
                    display: none;
                }
            }
        }
    }
    
    .tarjetas-teaser {
        margin: 0;
        padding: 80px 0;
        position: relative;

        @include r(tablet-landscape) {
            padding: 40px 0;
        }

        &::after {
            background-color: rgba(232,232,232,1);
            bottom: -0.5px;
            content: '';
            height: 1px;
            left: 0;
            margin: 0;
            padding: 0;
            position: absolute;
            width: 100%;
        }
    }


    .tarjetas-teaser-title {
        @include font-size(2.5);
        line-height: 30px;
        margin-bottom: 8px;

        @include r(tablet-landscape) {
            @include font-size(2.5);
            margin-bottom: 16px;
        }
    }

    .tarjetas-teaser-buttons {
        font-size: 0;

        .btn {
            margin-right: 16px;
            min-width: 154px;

            &:last-child {
                margin-right: 0;
            }

            @include r(359px) {
                min-width: 136px;
            }
        }
    }
}   