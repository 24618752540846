/* ------------------------------------------------------------ */
/*  Submenu Navigation */
/* ------------------------------------------------------------ */
.submenu-parent {
	> a {
		overflow: hidden;
		position: relative;
		
		&::after {
			border-bottom: 8px solid $green-dark;
			border-left: 9px solid transparent;
			border-right: 9px solid transparent;
			bottom: 0;
			content: '';
			display: block;
			height: 8px;
			left: 50%;
			margin: 0;
			padding: 0;
			position: absolute;
			transform: translate(-50%, 8px);
			transition: all .4s ease-in-out;
			width: 18px;
		}
	}

	&:hover {

		> a {
			&::after {
				transform: translate(-50%, 0);
			}
		}

		.sub-menu {
			max-height: 1000px;
			padding: 32px 0;
		}
	}

	.sub-menu {
		background: $green-dark;
		display: block;
		left: 0;
		margin: 0;
		max-height: 0;
		overflow: hidden;
		padding: 0;
		position: absolute;
		top: 100%;
		transition: all .4s ease-in-out;	
		width: 100%;
		z-index: 10;

		.sub-menu-header {
			@include font-size(1.4);
			color: rgba(white,.75);
			display: block;
			margin: 0 0 24px 0;
			padding: 0 0 10px 0;
			position: relative;

			&::after {
				background: rgba(white, .25);
				bottom: 0;
				content: '';
				height: 2px;
				left: 0;
				margin: 0;
				padding: 0;
				position: absolute;
				width: 14px;
			}
		}

		ul {
			display: block;
			margin: 0;
			padding: 0;
		}

		li {
			display: block;
			margin: 0 0 16px 0;
			padding: 0;

			&:last-child {
				margin-bottom: 0;
			}
		}

		a {
			@include font-size(1.6);
			color: white;
			display: block;
			font-weight: $font-weight-medium;
			padding: 0;
			position: relative;
		}
	}
}