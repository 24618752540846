/* ------------------------------------------------------------ */
/*  Card elite detail list */
/* ------------------------------------------------------------ */
.card-elite-detail-list-container {
    position: relative;
    overflow: hidden;

    @include r(tablet-landscape) {
        margin-bottom: 12px;
    }

    &::after {
        background: linear-gradient(180deg, rgba(168,171,170,0) 0%,
                    rgba(152,155,154,0.25) 26.51%,
                    rgba(138,141,140,0.25) 50.2%,
                    rgba(127,130,129,0.25) 70.15%,
                    rgba(111,114,113,0) 100%);
        content: '';
        height: 100%;
        left: 50%;
        opacity: 1;
        position: absolute;
        bottom: 1px;
        width: 1px;
        z-index: 1;

        @include r(tablet-landscape) {
            opacity: 0;
        }
    }
}

.card-elite-detail-list {
    position: relative;
    z-index: 2;

    .card-detail-block {
        margin: 0 0 1px 0;
        padding: 0;
        position: relative;
        z-index: 2;

        &::after {
            background: linear-gradient(270deg, rgba(168,171,170,0) 0%,
                        rgba(161,164,163,0.25) 20.23%,
                        rgba(139,142,141,0.25) 52.37%,
                        rgba(120,123,122,0.25) 77.58%,
                        rgba(111,114,113,0) 100%);
            content: '';
            top: 0;
            height: 1px;
            left: 0;
            opacity: 1;
            position: absolute;
            width: 100%;
            z-index: 1;

            @include r(tablet-landscape) {
                opacity: 0;
            }
        }
    }

    .card-detail-info {
        margin: 0;
        padding-top: 80px;
        padding-bottom: 80px;
        min-height: 334px;
        position: relative;
        z-index: 2;

        @include r(tablet-landscape) {
            padding: 40px 0;
            min-height: 1px;
            margin-bottom: 1px;
        }

        &::after {
            background: rgba($gray,0.25);
            content: '';
            top: 0;
            height: 1px;
            left: -15px;
            opacity: 0;
            position: absolute;
            width: calc(100% + 30px);
            z-index: 1;

            @include r(tablet-landscape) {
                opacity: 1;
            }
        }

        img {
            width: 100%;
            max-width: 130px;

            @include r(mobile-landscape) {
                max-width: 96px;
            }
        }

        h5 {
            @include font-size(2);
            margin: 0;
        }

        p {
            margin: 0;
            padding: 16px 0 0 0;

            &:first-child {
                padding-top: 24px; 
            }
        }
    }
}   