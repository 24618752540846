/* ------------------------------------------------------------ */
/*  Two Columns Product List */
/* ------------------------------------------------------------ */
.two-columns-product-list {
	
	.product-card-list-title {
		margin: 0 0 24px 0;
	}

	.product-card-list {
		align-items: stretch;
		border-radius: 2px;
		display: flex;
		flex-wrap: wrap;
		margin: 0;
		overflow: hidden;
		padding: 0;
		width: 100%;

		.left-column {
		  	margin: 0;
		  	padding: 0;
		  	width: 57%;

		  	@include r(tablet-landscape) {
				width: 100%;
			}
		}

		.right-column {
			background: $gray-background;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			margin: 0;
			padding: 24px;
			width: 43%;

			@include r(tablet-landscape) {
				background: transparent;
				padding: 0;
				width: 100%;
			}
		}
	}
}


// Product Image
%product-card-image {
	border-radius: 2px;
	margin: 0;
	overflow: hidden;
	padding: 0;
	position: relative;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	min-height: 104px;

	img {
		height: auto;
		margin: 0;
		padding: 0;
		position: relative;
		width: 100%;
		opacity: 0;
	}

	.product-card-image-hover {
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 100%;
		left: 0;
		margin: 0;
		padding: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1;
		display: none;
	}

	&::before {
		background: rgba($green, .8%);
		content: '';
		height: 100%;
		left: 0;
		margin: 0;
		opacity: 0;
		padding: 0;
		position: absolute;
		top: 0;
		transition: .35s all ease-in-out;
		width: 100%;
		z-index: 3;
	}

	&::after {
		background: linear-gradient(180deg, rgba(51,51,51,0.25) 0%, rgba(55,56,56,0.86) 100%);
		content: '';
		height: 100%;
		left: 0;
		margin: 0;
		opacity: 1;
		padding: 0;
		position: absolute;
		top: 0;
		transition: .35s all ease-in-out;
		width: 100%;
		z-index: 2;
	}
}

.product-card-main {
	display: block;
	margin: 0;
	overflow: hidden;
	padding: 0;
	position: relative;

	@include r(tablet-landscape) {
		margin-bottom: 16px;

		&::before {
			content: '';
			display: block;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 3;
		}
	}

	.product-card-link {
		display: block;
		margin: 0;
		padding: 0;
		position: relative;
		z-index: 2;
	}

	.product-card-image {
		@extend %product-card-image;
		border-radius: 2px 0 0 2px;
	}

	.product-card-content {
		bottom: 0;
		left: 0;
		margin: 0;
		overflow: hidden;
		padding: 32px;
		position: absolute;
		width: 100%;
		z-index: 2;

		@include r(desktop-minimun) {
			padding: 24px;
		}

		@include r(tablet-landscape) {
			padding: 16px;
		}

		.product-title {
			@include font-size(3.2);
			color: white;
			margin: 0 0 8px 0;
			padding: 0;
			text-align: left;
		}

		p {
			margin: 0 0 16px 0;
			color: white;
		}

		.product-more-info {
			@include font-size(1.3);
		}
	}
}

.product-card {
	border-radius: 2px;
	cursor: pointer;
	display: block;
	margin: 0;
	overflow: hidden;
	padding: 0;
	position: relative;

	@include r(tablet-landscape) {
		margin-bottom: 16px;
	}

	&:last-child {
		@include r(tablet-landscape) {
			margin-bottom: 0;
		}
	}

	&:hover {
		.product-card-image {
			&::before {
		  		opacity: 1;

		  		@include r(tablet-landscape) {
					opacity: 0;
				}
		  	}

		  	&::after {
		  		opacity: 0;

		  		@include r(tablet-landscape) {
					opacity: 1;
				}
	  		}
		}
	}

	&::before {
		content: '';
		display: block;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 3;
	}

	.product-card-link {
		display: block;
		margin: 0;
		padding: 0;
		position: relative;
		z-index: 2;
	}

	.product-card-image {
		@extend %product-card-image;
		padding-top: 30%;
		transition: all .5s ease;

		@include r(tablet-landscape) {
			min-height: 140px;
			padding-top: 0;
		}

		@include r(mobile) {
			min-height: 104px;
			padding-top: 0;
		}

		img {
			display: none;
		}
	}

	// Product Card content
	.product-card-content {
		bottom: 0;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		height: 100%;
		justify-content: center;
		left: 0;
		margin: 0;
		overflow: hidden;
		padding: 16px 32px;
		position: absolute;
		width: 100%;
		z-index: 2;

		@include r(desktop-minimun) {
			padding: 16px 24px;
		}

		@include r(tablet-landscape) {
			flex-wrap: nowrap;
			padding: 16px;
		}

		.product-title {
			@include font-size(2);
			color: white;
			display: block;
			line-height: normal;
			margin: 0;
			padding: 0;
			text-align: left;
			transition: all .5s ease;

			@include r(desktop-minimun) {
				@include font-size(1.6);
			}

			@include r(tablet-landscape) {
				@include font-size(2);
			}
		}

		.product-info,
		.product-more-info {
			max-height: 0;
			overflow: hidden;
		}

		.product-info {
			max-height: 0;
			opacity: 0;
			overflow: hidden;
			transition: all .5s ease;

			p {
				@include font-size(1.6);
				line-height: 1.63;
				color: white;
				margin: 0;
			}
		}
	}


	&--active {

		&::before {
			z-index: 2;
		}

		.product-card-link {
			z-index: 3;
		}

		.product-card-image {
			padding-top: 75%;
		}

		// Product Card content
		.product-card-content {
			justify-content: flex-end;

			.product-title {
				margin-bottom: 8px;
				transition: all .35s ease;
			}

			.product-info {
				max-height: 600px;
				opacity: 1;
				transition: all .35s ease;
			}
		}
	}
}