.textwidget {
  table {
    text-align: center;
    width: 100%;
  }

  th, td {
    text-align: center;
  }

  td {
    padding: 8px;
  }

  thead {
    background-color: $gray-dark;
    color: white;
  }

  th {
    padding: 16px 8px;
  }

  tbody {
    background-color: $gray-background-variant;

    td {
      border: 1px solid rgba(0,0,0,0.12);
      border-width: 0 1px 1px 0;

      &:last-child {
        border-width: 0px 0 1px 0;
      }
    }
  }

  tfoot {
    td {

    }

    strong {
      color: $gray-dark;
    }

    tr:last-child {
      strong {
        font-weight: $font-weight-bold;
      }

      td {
        border-top: 1px solid rgba(0,0,0,0.12);

        &:first-child {
          border: 0;
        }
      }
    }
  }
}


.page-tasas-de-referencia {
  table {
    text-align: center;
    width: auto;
  }

  th, td {

    strong, b {
      color: $gray-dark;
      font-weight: $font-weight-bold;
    }
  }

  tbody {
    background-color: $gray-background-variant;

    td {
      border: none;
    }
  }
}