.file-card {
    border-radius: 2px;
    display: block;
    margin: 0 0 40px 0;
    overflow: hidden;
    padding: 0;
    position: relative;

    @include r(mobile-landscape) {
        margin-bottom: 16px;
    }

    .file-card__image {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        display: block;
        margin: 0;
        overflow: hidden;
        padding: 136% 0 0 0;
        position: relative;
        width: 100%;

        @include r(mobile) {
            padding: 154% 0 0 0;
        }

        &::before {
            background: linear-gradient(180deg,
                        rgba(51, 51, 51, 0) 0%,
                        rgba(55, 56, 56, 0.86) 100%);
            content: "";
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 2;
        }

        img {
            height: auto;
            margin: 0;
            padding: 0;
            position: absolute;
            width: 100%;
            z-index: 1;
            top: 0;
            left: 0;
            opacity: 0;
        }
    }

    .file-card__info {
        bottom: 0;
        left: 0;
        position: absolute;
        text-align: center;
        transform: translateY(42px);
        transition: all .3s ease-in-out;
        width: 100%;
        z-index: 2;

        @include r(tablet) {
            transform: translateY(0);
        }
    }

    &:hover {
        .file-card__info {
            transform: translateY(0);
        }
    }

    .file-card__title {
        @include font-size(1.8);
        color: white; 
        margin: 0;
        padding: 16px;
        text-align: center;
        border-bottom: 1px solid white;

        @include r(mobile) {
            @include font-size(1.6);
        }
    }

    .btn {
        border-radius: 0 0 2px 2px;

        @include r(mobile) {
            @include font-size(1.3);
        }
    }
}