/* ------------------------------------------------------------ */
/*  Top Navigation */
/* ------------------------------------------------------------ */

.site__top-menu-wrapper {
	margin: 0;
	padding: 0;

	&::after {
		background: rgba(111,114,113,.2);
		content: '';
		display: inline-block;
		height: 16px;
		margin: 0 12px;
		padding: 0;
		vertical-align: middle;
		width: 1px;
	}

	@include r(tablet-landscape) {
		display: none;
	}
}

.site__top-menu {
	display: inline-block;
	font-size: 0;
	margin: 0;
	padding: 0;
	vertical-align: middle;

	li {
		display: inline-block;
		margin: 0 12px;
		padding: 0;
		vertical-align: middle;
	}

	a {
		@include font-size(1.8);
		color: $gray;
		display: block;
		font-weight: $font-weight-medium;
		line-height: 20px;
		margin: 0;
		padding: 30px 0;
		transition: all .25s ease-in-out;

		&:hover {
			color: $green;
		}

		@include r(desktop-minimun) {
			@include font-size(1.4);
		}
	}

	.submenu-parent {
		.sub-menu {
			margin-top: -12px;
		}
	}
}