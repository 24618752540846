/* ------------------------------------------------------------ */
/*  Card elite benefit list */
/* ------------------------------------------------------------ */
.card-elite-benefits-list-container {
    h3 {
        margin-bottom: 40px;
    }
    
    .card-elite-benefit {
        background: $gray-light-odd;
        border-radius: 2px;
        height: calc(100% - 32px);
        margin: 0 0 32px 0;
        padding: 24px;

        h5 {
            @include font-size(2);
        }

        p {
            margin: 0;
            padding: 0;
        }
    }
}