/* ------------------------------------------------------------ */
/*  Mobile Navigation */
/* ------------------------------------------------------------ */
.site__mobile-menu-wrapper {
	background: $green; 
	display: none;
	height: 100%;
	left: -100%;
	margin: 0;
	padding: 0;
	position: fixed;
	top: 0;
	transition: all .35s ease-in-out;
	width: 320px;
	z-index: 20;
	opacity: 0;

	@include r(tablet-landscape) {
		display: block;
	}

	&--active {
		left: 0;
		opacity: 1;
	}

	&.parent-opened {
		
		.site__mobile-menu-header {
			opacity: 0;
			z-index: 19;
			transition: opacity .4s ease-in-out 0s,
						z-index .01s ease-in-out .35s;
		}

		.site__mobile-menu-content {
			z-index: 20;
			transition: z-index .01s ease-in-out .35s;
		}
	}
}

.site__mobile-menu-header {
	background: $green;
	height: 80px;
	left: 0;
	opacity: 1;
	padding: 16px;
	position: absolute;
	top: 0;
	transition: opacity .35s ease-in-out .02s,
				z-index .01s ease-in-out 0s;
	width: 100%;
	z-index: 20;

	.site__mobile-menu__button {
		@include font-size(1.6);
		background: transparent;
		border: none;
		color: white;
		height: 24px;
		line-height: 24px;
		margin: 0 16px 0 0;
		overflow: hidden;
		padding: 0;
		width: 24px;
	}
}

.site__mobile-menu-picker {
	background: rgba(black, .24);
	border-radius: 2px;
	font-size: 0;
	margin: 0;
	overflow: hidden;
	padding: 0;

	li {
		display: inline-block;
		margin: 0;
		padding: 0;
		vertical-align: middle;
		width: 50%;

		&.active {
			a {
				background: white;
				color: $gray;
			}
		}
	}

	a {
		@include font-size(1.2);
		color: white;
		display: block;
		font-weight: $font-weight-medium;
		line-height: 32px;
		margin: 0;
		padding: 0 24px;
		text-align: center;
		text-transform: uppercase;
		transition: all .25s ease-in-out;
		width: 100%;

		&:hover {
			background: rgba(white, .8);
			color: $gray;
		}
	}
}

.site__mobile-menu-content {
	-webkit-overflow-scrolling: touch;
	height: 100%;
	overflow-x: hidden;
	overflow-y: scroll;
	padding: 80px 0 0 0;
	position: relative;
	transition: z-index .01s ease-in-out 0s;
	z-index: 19;

	ul {
		display: block;
		margin: 0;
		padding: 0 24px; 
	}

	.site__mobile-menu {
		position: relative;

		&:nth-child(odd) {
			background: $green-dark;
		}

		li {
			border-bottom: 1px solid rgba(255,255,255,0.10);
			display: block;
			margin: 0;
			padding: 0;

			&:last-child {
				border: none;
			}
		}

		a {
			@include font-size(1.6);
			color: white;
			display: block;
			line-height: 24px;
			margin: 0;
			padding: 16px 0;
			position: relative;
		}
	}

	.site__mobile-menu-parent {
		> a {
			@include font-size(1.6);
			color: white;
			display: block;
			line-height: 24px;
			margin: 0;
			padding: 16px 0;
			position: relative;

			i {
				margin: 0;
				padding: 0;
				position: absolute;
				top: 50%;
				right: 0;
				opacity: .4;
				transform: translate(0,-50%);

				&::before {
					vertical-align: middle;
				}
			}
		}
	}

	.site__mobile-menu__sub-menu {
		background: $green-dark; 
		display: block;
		height: 100%;
		left: -100%;
		margin: 0;
		opacity: 0;
		padding: 0;
		position: fixed;
		top: 0;
		transition: all .35s ease-in-out;
		width: 320px;
		z-index: 21;

		&--active {
			left: 0;
			opacity: 1;
		}

		> li {
			border: none;
			margin: 0 0 16px 0;
			padding: 0;
			position: relative;

			&::after {
				background: rgba(255, 255, 255, 0.10);
				bottom: 0;
				content: '';
				height: 1px;
				left: 24px;
				margin: 0;
				padding: 0;
				position: absolute;
				width: calc(100% - 48px);
			}

			&:last-child {
				&::after {
					display: none;
				}
			}
		}
	}

	.site__mobile-menu__sub-menu-title {
		margin: 0;
		padding: 0;
		height: 80px;

		a {
			@include font-size(1.4);
			background: $green;
			line-height: 24px;
			margin: 0;
			padding: 28px 20px 28px 56px;
			text-transform: uppercase;

			i {
				left: 0;
				margin: 0;
				padding: 0;
				position: absolute;
				text-align: center;
				top: 50%;
				transform: translate(0,-50%);
				width: 56px;

				&::before {
					vertical-align: middle;
				}
			}
		}
	}

	.site__mobile-menu__sub-menu-content {
		height: calc(100% - 80px);
		overflow-x: hidden;
		overflow-y: scroll;
		padding: 16px 0 0 0;
		-webkit-overflow-scrolling: touch;

		> li {
			margin: 0 0 16px 0;
			padding: 0;
			position: relative;
			border: none;

			&::after {
				background: rgba(255,255,255,0.10);
				bottom: 0;
				content: '';
				height: 1px;
				left: 24px;
				position: absolute;
				width: calc(100% - 48px);
			}

			&:last-child {
				margin-bottom: 0;

				&::after {
					opacity: 0;
				}
			}
		}
	}

	.site__mobile-menu__sub-menu-header {
		@include font-size(1.4);
		color: rgba(white,.75);
		display: block;
		margin: 0 24px;
		padding: 5px 0;
		position: relative;

		&::after {
			background: rgba(white, .25);
			bottom: 0;
			content: '';
			height: 2px;
			left: 0;
			margin: 0;
			padding: 0;
			position: absolute;
			width: 14px;
		}
	}
}


// Mobile Menu Overlay Effect
.site {

	.close-mobile-overlay {
		display: none;

		@include r(tablet-landscape) {
			background: rgba(0,0,0,.24);
			content: '';
			display: block;
			height: 0;
			left: 0;
			margin: 0;
			opacity: 0;
			padding: 0;
			position: fixed;
			transition: height .01s ease-in-out .4s,
						width .01s ease-in-out .4s,
						opacity .35s ease-in-out .01s;
			top: 0;
			width: 0;
			z-index: 19;
		}
	}

	&__mobile-menu--is-open {
		min-height: 100%;
		overflow: hidden;
		position: relative;

		body {
			min-height: 100%;
			overflow: hidden;
			position: relative;

			.close-mobile-overlay {
				height: 100%;
				opacity: 1;
				width: 100%;
				transition: height .01s ease-in-out .01s,
							width .01s ease-in-out .01s,
							opacity .35s ease-in-out .01s;
			}
		}
	}
}