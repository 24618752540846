// my responsive mixin

@mixin r($breakpoint) {
  @if $breakpoint == "desktop-minimun" {  // 1140 px
    @media screen and (min-width: 0px) and (max-width: 1140px) {
      @content;
    }
  }

  @if $breakpoint == "tablet-landscape" {  // 991 px
    @media screen and (min-width: 0px) and (max-width: 991px) {
      @content;
    }
  }

  @if $breakpoint == "tablet" {  // 959 - 768 px
    @media screen and (min-width: 0px) and (max-width: 959px) {
      @content;
    }
  }

  @if $breakpoint == "mobile-landscape" {  // 767 px
    @media screen and (min-width: 0px) and (max-width: 767px) {
      @content;
    }
  }

  @if $breakpoint == "mobile" {  // 480 px
    @media screen and (min-width: 0px) and (max-width: 480px) {
      @content;
    }
  }

  @else {
    @media (max-width: $breakpoint) {
      @content;
    }
  }
}
// @warn "- #{$breakpoint} - is an invalid media query.";