/* ------------------------------------------------------------ */
/*  Modals */
/* ------------------------------------------------------------ */

// Animation
/* Important part */

.modal {
  z-index: 100001;
}

.modal-content {
  border: none;
}

.modal-header {
  border: none;
  
  .close {
    background: $gray;
    border-radius: 2px;
    color: white;
    height: 32px;
    margin: 0;
    opacity: 1;
    padding: 0;
    position: absolute;
    right: -48px;
    text-align: center;
    text-shadow: 0 0 0 transparent;
    top: 0;
    width: 32px;

    @include r(mobile) {
      background: transparent;
      right: 0;
    }
  }
}

.modal-footer {
  > :not(:first-child) {
    margin-left: 12px;
  }
}

.modal-calculadora {

  .modal-dialog {
    max-width: 445px;
  }

  img {
    margin-bottom: 40px;

    @include r(mobile-landscape) {
      max-width: 150px;
      margin-bottom: 32px;
    }
  }

  h5 {
    @include font-size(2);
    margin: 0 0 24px 0;

    @include r(mobile-landscape) {
      @include font-size(1.8);
      margin-bottom: 8px;
    }
  }

  p {
    @include font-size(1.3);
    font-weight: 500;
    line-height: 22px;
    margin: 0 0 16px 0;
  }

  .big-text {
    @include font-size(1.6);
    font-weight: normal;
    line-height: 1.69;
    margin-bottom: 24px;
  }

  .modal-content {
    margin: 0;
    padding: 32px;

    @include r(mobile-landscape) {
      padding: 40px 24px;
    }
  }

  .modal-body,
  .modal-footer {
    margin: 0;
    padding: 0;
    border: none;
  }

  .form-container {
    margin-bottom: 24px;
  }

  .form-group {
    padding: 0;

    &::after {
      background: rgb(183, 184, 184);
    }
  }

  .input-label {

    &::after {
      background: $green;
    }

    label {
      color: $green;
    }

    &--is-empty {

      &::after {
        background: $gray;
      }

      label {
        color: $gray;
      }
    }

    &--is-focused {

      &::after {
        background: $green;
      }

      label {
        color: $green;
      }
    }
  }
}