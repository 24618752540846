/*!
Site Name: Banco Caribe
Site URI: http://www.bancocaribe.com.do/
Author: Soluciones GBH
Author URI: http://solucionesgbh.com/
Description: A leading provider of state-of-the-art communications technologies.
Version: 1.0
*/
/* ------------------------------------------------------------ */
/*  Vendors */
/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/*  Vendors */
/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/*  Bootstrap vendors */
/* ------------------------------------------------------------ */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
  width: device-width;
}

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014 \00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px);
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
.input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
.input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
.input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
.input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
.input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
.input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
.input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
.input-group-lg > .input-group-append > .form-control-plaintext.btn {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px);
}

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px);
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
.custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip, .was-validated
.custom-select:valid ~ .valid-feedback,
.was-validated
.custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #71dd8a;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip, .was-validated
.custom-select:invalid ~ .invalid-feedback,
.was-validated
.custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #efa2a9;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn:hover, .btn:focus {
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
  background-image: none;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}

.fade.show {
  opacity: 1;
}

.collapse {
  display: none;
}

.collapse.show {
  display: block;
}

tr.collapse.show {
  display: table-row;
}

tbody.collapse.show {
  display: table-row-group;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

.dropup,
.dropdown {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 0 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after {
  margin-left: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file:focus {
  z-index: 3;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::before {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label,
.input-group > .custom-file:not(:first-child) .custom-file-label::before {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  margin-bottom: 0;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  user-select: none;
  background-color: #dee2e6;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(128, 189, 255, 0.5);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-control {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-file-input:focus ~ .custom-file-control::before {
  border-color: #80bdff;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(calc(2.25rem + 2px) - 1px * 2);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .dropdown-menu-right {
  right: 0;
  left: auto;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-top,
  .card-group > .card:first-child .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-bottom,
  .card-group > .card:first-child .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-top,
  .card-group > .card:last-child .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-bottom,
  .card-group > .card:last-child .card-footer {
    border-bottom-left-radius: 0;
  }
  .card-group > .card:only-child {
    border-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-top,
  .card-group > .card:only-child .card-header {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-bottom,
  .card-group > .card:only-child .card-footer {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

.badge-primary[href]:hover, .badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #0062cc;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #545b62;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-success[href]:hover, .badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-info[href]:hover, .badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.badge-warning[href]:hover, .badge-warning[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #d39e00;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-danger[href]:hover, .badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

.badge-light[href]:hover, .badge-light[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

.badge-dark[href]:hover, .badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item:hover, .list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover, .close:focus {
  color: #000;
  text-decoration: none;
  opacity: .75;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}

.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important;
  }
}

@font-face {
  font-family: "w-icons";
  src: url("../fonts/w-icons.eot");
  src: url("../fonts/w-icons.eot?#iefix") format("embedded-opentype"), url("../fonts/w-icons.woff") format("woff"), url("../fonts/w-icons.ttf") format("truetype"), url("../fonts/w-icons.svg#w-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "w-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  vertical-align: top;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="w-icon-"]:before,
[class*=" w-icon-"]:before {
  font-family: "w-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.w-icon {
  display: inline-block;
}

.w-icon-ic-arrowback:before {
  content: "\61";
}

.w-icon-ic-arrowdown:before {
  content: "\62";
}

.w-icon-ic-arrowright:before {
  content: "\63";
}

.w-icon-ic-call:before {
  content: "\64";
}

.w-icon-ic-cancel:before {
  content: "\65";
}

.w-icon-ic-checkbox-checked:before {
  content: "\66";
}

.w-icon-ic-checkbox-unchecked:before {
  content: "\67";
}

.w-icon-ic-down:before {
  content: "\68";
}

.w-icon-ic-download:before {
  content: "\69";
}

.w-icon-ic-dropdown:before {
  content: "\6a";
}

.w-icon-ic-facebook:before {
  content: "\6b";
}

.w-icon-ic-id:before {
  content: "\6c";
}

.w-icon-ic-instagram:before {
  content: "\6d";
}

.w-icon-ic-interest:before {
  content: "\6e";
}

.w-icon-ic-left:before {
  content: "\6f";
}

.w-icon-ic-linkedin:before {
  content: "\70";
}

.w-icon-ic-menu:before {
  content: "\71";
}

.w-icon-ic-message:before {
  content: "\72";
}

.w-icon-ic-money:before {
  content: "\73";
}

.w-icon-ic-person:before {
  content: "\74";
}

.w-icon-ic-pint:before {
  content: "\75";
}

.w-icon-ic-play:before {
  content: "\76";
}

.w-icon-ic-plus:before {
  content: "\77";
}

.w-icon-ic-question:before {
  content: "\78";
}

.w-icon-ic-radiobutton:before {
  content: "\79";
}

.w-icon-ic-radiobutton-unchecked:before {
  content: "\7a";
}

.w-icon-ic-right:before {
  content: "\41";
}

.w-icon-ic-search:before {
  content: "\42";
}

.w-icon-ic-twitter:before {
  content: "\43";
}

.w-icon-ic-youtube:before {
  content: "\44";
}

.w-icon-ic-checked:before {
  content: "\45";
}

.w-icon-ic-arrowdown-white-left:before {
  content: "\46";
}

.w-icon-ic-arrowdown-white-right:before {
  content: "\47";
}

.baron__clipper {
  position: relative;
  overflow: hidden;
}

.baron__scroller {
  overflow-y: scroll;
  -ms-overflow-style: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  border: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  /* remove line to customize scrollbar in iOs */
}

.baron__scroller::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.baron__track {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

.baron._scrollbar .baron__track {
  display: block;
}

.baron__free {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.baron__bar {
  display: none;
  position: absolute;
  right: 0;
  z-index: 1;
  width: 10px;
  background: #999;
}

.baron._scrollbar .baron__bar {
  display: block;
}

.baron__control {
  display: none;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* ------------------------------------------------------------ */
/*  Helpers */
/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/*  Settings */
/* ------------------------------------------------------------ */
/* ---------------------- */
/*  Color palettes */
/* ---------------------- */
/* ---------------------- */
/*  Fonts */
/* ---------------------- */
@font-face {
  font-family: "HelveticaNeue";
  src: url("../fonts/HelveticaNeue.eot?") format("eot"), url("../fonts/HelveticaNeue.woff2") format("woff2"), url("../fonts/HelveticaNeue.woff") format("woff"), url("../fonts/HelveticaNeue.ttf") format("truetype"), url("../fonts/HelveticaNeue.svg#HelveticaNeue") format("svg");
}

@font-face {
  font-family: "HelveticaNeue";
  font-weight: bold;
  src: url("../fonts/HelveticaNeue-Bold.eot?") format("eot"), url("../fonts/HelveticaNeue-Bold.woff2") format("woff2"), url("../fonts/HelveticaNeue-Bold.woff") format("woff"), url("../fonts/HelveticaNeue-Bold.ttf") format("truetype"), url("../fonts/HelveticaNeue-Bold.svg#HelveticaNeue") format("svg");
}

@font-face {
  font-family: "HelveticaNeue";
  font-weight: 500;
  src: url("../fonts/HelveticaNeue-Medium.eot?") format("eot"), url("../fonts/HelveticaNeue-Medium.woff2") format("woff2"), url("../fonts/HelveticaNeue-Medium.woff") format("woff"), url("../fonts/HelveticaNeue-Medium.ttf") format("truetype"), url("../fonts/HelveticaNeue-Medium.svg#HelveticaNeue") format("svg");
}

@font-face {
  font-family: "HelveticaNeueItalic";
  src: url("../fonts/HelveticaNeue-Italic.eot?") format("eot"), url("../fonts/HelveticaNeue-Italic.woff2") format("woff2"), url("../fonts/HelveticaNeue-Italic.woff") format("woff"), url("../fonts/HelveticaNeue-Italic.ttf") format("truetype"), url("../fonts/HelveticaNeue-Italic.svg#HelveticaNeueItalic") format("svg");
}

@font-face {
  font-family: "HelveticaNeueLightItalic";
  src: url("../fonts/HelveticaNeue-LightItalic.eot?") format("eot"), url("../fonts/HelveticaNeue-LightItalic.woff2") format("woff2"), url("../fonts/HelveticaNeue-LightItalic.woff") format("woff"), url("../fonts/HelveticaNeue-LightItalic.ttf") format("truetype"), url("../fonts/HelveticaNeue-LightItalic.svg#HelveticaNeueLightItalic") format("svg");
}

/* ------------------------------------------------------------ */
/*  Defaults */
/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/*  Base */
/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/*  Base */
/* ------------------------------------------------------------ */
html {
  font-size: 62.5%;
}

html,
body {
  height: 100%;
}

body {
  background: white;
}

/* ------------------------------------------------------------ */
/*  Links */
/* ------------------------------------------------------------ */
a {
  color: #6f7271;
  transition: all .5s ease-in-out;
}

a:hover, a:active {
  color: #ffda33;
  text-decoration: none;
}

a:focus, a:focus:active {
  color: #6f7271;
}

b, strong {
  font-weight: bold;
}

img {
  max-width: 100%;
}

/* ---------------------- */
/*  Aligns */
/* ---------------------- */
.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* ---------------------- */
/*  Aligns */
/* ---------------------- */
.lc-mpb-0 *:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}

/* ------------------------------------------------------------ */
/*  Floats */
/* ------------------------------------------------------------ */
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

/* --------------------- */
/* Margins and paddings */
/* --------------------- */
.nmgB {
  margin-bottom: 0;
}

.mgB16 {
  margin-bottom: 16px;
}

.mg0 {
  margin: 0;
}

.mg1 {
  margin: 100px;
}

.mgG {
  margin: 10px;
}

.mgB {
  margin-bottom: 10px;
}

.mgT {
  margin-top: 10px;
}

.mgL {
  margin-left: 10px;
}

.mgR {
  margin-right: 10px;
}

.mgB15 {
  margin-bottom: 15px;
}

.mgB2 {
  margin-bottom: 20px;
}

.mgB24 {
  margin-bottom: 24px;
}

.mgB3 {
  margin-bottom: 30px;
}

.mgB32 {
  margin-bottom: 32px;
}

.mgB4 {
  margin-bottom: 40px;
}

.mgB5 {
  margin-bottom: 50px;
}

.mgB6 {
  margin-bottom: 60px;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .mgB6 {
    margin-bottom: 30px;
  }
}

@media (max-width: tablet) {
  .mgB6 {
    margin-bottom: 30px;
  }
}

.mgT15 {
  margin-top: 15px;
}

.mgT2 {
  margin-top: 20px;
}

.mgT5 {
  margin-top: 50px;
}

.nmgB {
  margin-bottom: 0;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .mgB-sm-32 {
    margin-bottom: 32px;
  }
}

@media (max-width: tablet-landscape) {
  .mgB-sm-32 {
    margin-bottom: 32px;
  }
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .mgB-sm-24 {
    margin-bottom: 24px;
  }
}

@media (max-width: tablet-landscape) {
  .mgB-sm-24 {
    margin-bottom: 24px;
  }
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .mgB-sm-16 {
    margin-bottom: 16px;
  }
}

@media (max-width: tablet-landscape) {
  .mgB-sm-16 {
    margin-bottom: 16px;
  }
}

.pd0 {
  padding: 0;
}

.pd1 {
  padding: 100px;
}

.pdG {
  padding: 10px;
}

.pdB {
  padding-bottom: 10px;
}

.pdT {
  padding-top: 10px;
}

.pdL {
  padding-left: 10px;
}

.pdR {
  padding-right: 10px;
}

.pdB15 {
  padding-bottom: 15px;
}

.pdB2 {
  padding-bottom: 20px;
}

.pdB5 {
  padding-bottom: 40px;
}

.pdB5 {
  padding-bottom: 50px;
}

.pdT15 {
  padding-top: 15px;
}

.pdT2 {
  padding-top: 20px;
}

.pdT5 {
  padding-top: 50px;
}

.pdT32 {
  padding-top: 32px;
}

.pdT48 {
  padding-top: 48px;
}

.pdTB4 {
  padding-bottom: 40px;
  padding-top: 40px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .pdTB4 {
    padding-bottom: 20px;
    padding-top: 20px;
  }
}

@media (max-width: tablet-landscape) {
  .pdTB4 {
    padding-bottom: 20px;
    padding-top: 20px;
  }
}

.pdTB6 {
  padding-bottom: 60px;
  padding-top: 60px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .pdTB6 {
    padding-bottom: 0px;
    padding-top: 0px;
  }
}

@media (max-width: tablet-landscape) {
  .pdTB6 {
    padding-bottom: 0px;
    padding-top: 0px;
  }
}

.pdTB7 {
  padding-bottom: 72px;
  padding-top: 72px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .pdTB7 {
    padding-bottom: 40px;
    padding-top: 40px;
  }
}

@media (max-width: tablet-landscape) {
  .pdTB7 {
    padding-bottom: 40px;
    padding-top: 40px;
  }
}

.pdTB8 {
  padding-bottom: 80px;
  padding-top: 80px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .pdTB8 {
    padding-bottom: 40px;
    padding-top: 40px;
  }
}

@media (max-width: tablet-landscape) {
  .pdTB8 {
    padding-bottom: 40px;
    padding-top: 40px;
  }
}

.pdTB9 {
  padding-bottom: 90px;
  padding-top: 90px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .pdTB9 {
    padding-bottom: 40px;
    padding-top: 40px;
  }
}

@media (max-width: tablet-landscape) {
  .pdTB9 {
    padding-bottom: 40px;
    padding-top: 40px;
  }
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .pdB-sm-16 {
    padding-bottom: 16px;
  }
}

@media (max-width: tablet-landscape) {
  .pdB-sm-16 {
    padding-bottom: 16px;
  }
}

.pdR15 {
  padding-right: 15px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .pdR15 {
    padding-right: 0;
  }
}

@media (max-width: tablet-landscape) {
  .pdR15 {
    padding-right: 0;
  }
}

.pdL15 {
  padding-left: 15px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .pdL15 {
    padding-left: 0;
  }
}

@media (max-width: tablet-landscape) {
  .pdL15 {
    padding-left: 0;
  }
}

.pdR24 {
  padding-right: 24px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .pdR24 {
    padding-right: 0;
  }
}

@media (max-width: tablet-landscape) {
  .pdR24 {
    padding-right: 0;
  }
}

.pdR56 {
  padding-right: 56px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .pdR56 {
    padding-right: 0;
  }
}

@media (max-width: tablet-landscape) {
  .pdR56 {
    padding-right: 0;
  }
}

.pdL56 {
  padding-left: 56px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .pdL56 {
    padding-left: 0;
  }
}

@media (max-width: tablet-landscape) {
  .pdL56 {
    padding-left: 0;
  }
}

/* ------------------------------------------------------------ */
/*  Miscellaneous */
/* ------------------------------------------------------------ */
.img-pdR24 {
  padding-right: 24px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .img-pdR24 {
    padding-right: 0;
  }
}

@media (max-width: tablet-landscape) {
  .img-pdR24 {
    padding-right: 0;
  }
}

.img-pdL24 {
  padding-left: 24px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .img-pdL24 {
    padding-left: 0;
  }
}

@media (max-width: tablet-landscape) {
  .img-pdL24 {
    padding-left: 0;
  }
}

.img-pdR16 {
  padding-right: 16px;
}

.img-pdL16 {
  padding-left: 16px;
}

.img-w-160 img {
  width: 100%;
  max-width: 160px;
}

.no-border {
  border: 0 !important;
}

.no-comments {
  display: none;
}

.flex-grow-2 {
  flex-grow: 2;
}

.showcase_mobile-text-container--border {
  position: relative;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .showcase_mobile-text-container--border::after {
    background-color: #ececec;
    bottom: 0;
    content: '';
    height: 1px;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 100%;
  }
}

@media (max-width: tablet-landscape) {
  .showcase_mobile-text-container--border::after {
    background-color: #ececec;
    bottom: 0;
    content: '';
    height: 1px;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 100%;
  }
}

.showcase_mobile-text-container--border .showcase_mobile-text {
  padding: 24px 0;
}

.showcase_mobile-text {
  padding: 16px 0 0 0;
}

.showcase_mobile-text * {
  margin-bottom: 0;
  padding-bottom: 0;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .showcase_mobile-text .mgb {
    margin-bottom: 24px;
  }
}

@media (max-width: tablet) {
  .showcase_mobile-text .mgb {
    margin-bottom: 24px;
  }
}

.text-decoration {
  text-decoration: none;
  list-style: none;
}

.slick-list {
  overflow: hidden;
}

.overflow-hidden {
  overflow: hidden;
}

.buttons .btn {
  margin: 0 8px;
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .buttons .btn {
    margin: 0 8px 16px 8px;
  }
}

.buttons .btn:first-child {
  margin-left: 0;
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .buttons .btn:first-child {
    margin-left: 8px;
  }
}

.buttons .btn:last-child {
  margin-right: 0;
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .buttons .btn:last-child {
    margin-right: 8px;
    margin-bottom: 0;
  }
}

/* ---------------------- */
/*  Backgrounds */
/* ---------------------- */
.background--yellow {
  background-color: #ffd100;
}

.background--yellow-light {
  position: relative;
  background-color: rgba(255, 209, 0, 0.75);
}

.background--yellow-light-icon {
  width: 52%;
  height: 65%;
  position: absolute;
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: container;
  opacity: 0.5;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .background--yellow-light-icon {
    position: absolute;
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.5;
    width: 95%;
    height: 45%;
  }
}

@media (max-width: tablet) {
  .background--yellow-light-icon {
    position: absolute;
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.5;
    width: 95%;
    height: 45%;
  }
}

.background--green {
  background-color: #006835;
}

.background--green .image {
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.background--gray {
  background-color: #f7f7f7;
}

.background--gray-smoke {
  background-color: #373838;
}

.background--gray-dark {
  background-color: #4a4d4d;
}

.background--gray-light-odd {
  background-color: #242525;
}

.background-top {
  background-position: top center;
  background-repeat: no-repeat !important;
  background-size: 100% auto;
}

.background-bottom {
  background-position: bottom center;
  background-repeat: no-repeat !important;
  background-size: 100% auto;
}

.background-center {
  background-position: center center;
  background-repeat: no-repeat !important;
  background-size: 100% auto;
}

.background-repeat-y {
  background-position: center center;
  background-repeat: repeat-y !important;
  background-size: 100% auto;
}

.background-repeat-y.panel-row-style {
  background-repeat: repeat-y !important;
}

.background-size-contain {
  background-size: cover;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .background-size-contain {
    background-size: contain;
  }
}

@media (max-width: tablet) {
  .background-size-contain {
    background-size: contain;
  }
}

.br2 {
  border-radius: 2px;
}

.bs-img {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .mgb-img {
    margin-bottom: 24px;
  }
}

@media (max-width: tablet-landscape) {
  .mgb-img {
    margin-bottom: 24px;
  }
}

.img-none {
  opacity: 0;
}

.img-box-shadow {
  box-shadow: 2px 2px 2px -1px #c9c9c9, 4px 4px 4px -2px #cccccc, 6px 6px 6px -3px #cfcfcf, 8px 8px 8px -4px #d1d1d1, 10px 10px 10px -5px #d4d4d4, 12px 12px 12px -6px #d6d6d6, 14px 14px 14px -7px #d9d9d9, 16px 16px 16px -8px #dbdbdb, 18px 18px 18px -9px #dedede, 20px 20px 20px -10px #e0e0e0, 22px 22px 22px -11px #e3e3e3, 24px 24px 24px -12px #e6e6e6, 26px 26px 26px -13px #e8e8e8, 28px 28px 28px -14px #ebebeb, 30px 30px 30px -15px #ededed, 32px 32px 32px -16px #f0f0f0, 34px 34px 34px -17px #f2f2f2, 36px 36px 36px -18px whitesmoke, 38px 38px 38px -19px #f7f7f7, 40px 40px 40px -20px #fafafa, 42px 42px 42px -21px #fcfcfc, 44px 44px 44px -22px white, 46px 46px 46px -23px white, 48px 48px 48px -24px white, 50px 50px 50px -25px white;
  border-radius: 6.5%;
  display: inline-block;
  margin: 0 0 28px 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  transition: all .35s ease-in-out;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .img-box-shadow {
    margin-bottom: 40px;
    max-width: calc(74% + 30px);
  }
}

@media (max-width: tablet-landscape) {
  .img-box-shadow {
    margin-bottom: 40px;
    max-width: calc(74% + 30px);
  }
}

.img-box-shadow-hover {
  border-radius: 6.5%;
  display: inline-block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  transition: all .35s ease-in-out;
  transform: translateY(0);
}

.img-box-shadow-hover:hover {
  box-shadow: 2px 2px 2px -1px #c9c9c9, 4px 4px 4px -2px #cccccc, 6px 6px 6px -3px #cfcfcf, 8px 8px 8px -4px #d1d1d1, 10px 10px 10px -5px #d4d4d4, 12px 12px 12px -6px #d6d6d6, 14px 14px 14px -7px #d9d9d9, 16px 16px 16px -8px #dbdbdb, 18px 18px 18px -9px #dedede, 20px 20px 20px -10px #e0e0e0, 22px 22px 22px -11px #e3e3e3, 24px 24px 24px -12px #e6e6e6, 26px 26px 26px -13px #e8e8e8, 28px 28px 28px -14px #ebebeb, 30px 30px 30px -15px #ededed, 32px 32px 32px -16px #f0f0f0, 34px 34px 34px -17px #f2f2f2, 36px 36px 36px -18px whitesmoke, 38px 38px 38px -19px #f7f7f7, 40px 40px 40px -20px #fafafa, 42px 42px 42px -21px #fcfcfc, 44px 44px 44px -22px white, 46px 46px 46px -23px white, 48px 48px 48px -24px white, 50px 50px 50px -25px white;
  transform: translateY(-10px);
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .img-box-shadow-hover:hover {
    transform: translateY(0);
  }
}

@media (max-width: tablet-landscape) {
  .img-box-shadow-hover:hover {
    transform: translateY(0);
  }
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .img-box-shadow-hover {
    box-shadow: 2px 2px 2px -1px #c9c9c9, 4px 4px 4px -2px #cccccc, 6px 6px 6px -3px #cfcfcf, 8px 8px 8px -4px #d1d1d1, 10px 10px 10px -5px #d4d4d4, 12px 12px 12px -6px #d6d6d6, 14px 14px 14px -7px #d9d9d9, 16px 16px 16px -8px #dbdbdb, 18px 18px 18px -9px #dedede, 20px 20px 20px -10px #e0e0e0, 22px 22px 22px -11px #e3e3e3, 24px 24px 24px -12px #e6e6e6, 26px 26px 26px -13px #e8e8e8, 28px 28px 28px -14px #ebebeb, 30px 30px 30px -15px #ededed, 32px 32px 32px -16px #f0f0f0, 34px 34px 34px -17px #f2f2f2, 36px 36px 36px -18px whitesmoke, 38px 38px 38px -19px #f7f7f7, 40px 40px 40px -20px #fafafa, 42px 42px 42px -21px #fcfcfc, 44px 44px 44px -22px white, 46px 46px 46px -23px white, 48px 48px 48px -24px white, 50px 50px 50px -25px white;
    margin-bottom: 40px;
    max-width: calc(74% + 30px);
  }
}

@media (max-width: tablet-landscape) {
  .img-box-shadow-hover {
    box-shadow: 2px 2px 2px -1px #c9c9c9, 4px 4px 4px -2px #cccccc, 6px 6px 6px -3px #cfcfcf, 8px 8px 8px -4px #d1d1d1, 10px 10px 10px -5px #d4d4d4, 12px 12px 12px -6px #d6d6d6, 14px 14px 14px -7px #d9d9d9, 16px 16px 16px -8px #dbdbdb, 18px 18px 18px -9px #dedede, 20px 20px 20px -10px #e0e0e0, 22px 22px 22px -11px #e3e3e3, 24px 24px 24px -12px #e6e6e6, 26px 26px 26px -13px #e8e8e8, 28px 28px 28px -14px #ebebeb, 30px 30px 30px -15px #ededed, 32px 32px 32px -16px #f0f0f0, 34px 34px 34px -17px #f2f2f2, 36px 36px 36px -18px whitesmoke, 38px 38px 38px -19px #f7f7f7, 40px 40px 40px -20px #fafafa, 42px 42px 42px -21px #fcfcfc, 44px 44px 44px -22px white, 46px 46px 46px -23px white, 48px 48px 48px -24px white, 50px 50px 50px -25px white;
    margin-bottom: 40px;
    max-width: calc(74% + 30px);
  }
}

/* ------------------------------------------------------------ */
/*  Typography */
/* ------------------------------------------------------------ */
body,
button,
input,
select,
textarea {
  color: #6f7271;
  font-family: "HelveticaNeue", Helvetica, sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: normal;
  font-style: normal;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: "pnum";
  font-variant-numeric: proportional-nums;
}

@media (max-width: popular-down) {
  body,
  button,
  input,
  select,
  textarea {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: "HelveticaNeue", Helvetica, sans-serif;
  font-weight: 500;
}

h1, h2, h3, h4 {
  color: #6f7271;
}

h1 {
  font-size: 56px;
  font-size: 5.6rem;
  margin-bottom: 40px;
}

h2 {
  font-size: 40px;
  font-size: 4rem;
  font-weight: 600;
  margin-bottom: 50px;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  h2 {
    font-size: 30px;
    font-size: 3rem;
  }
}

@media (max-width: tablet) {
  h2 {
    font-size: 30px;
    font-size: 3rem;
  }
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  h2 {
    font-size: 20px;
    font-size: 2rem;
  }
}

h3 {
  font-size: 32px;
  font-size: 3.2rem;
  margin-bottom: 24px;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  h3 {
    font-size: 20px;
    font-size: 2rem;
    margin-bottom: 16px;
  }
}

@media (max-width: tablet) {
  h3 {
    font-size: 20px;
    font-size: 2rem;
    margin-bottom: 16px;
  }
}

h4 {
  font-size: 25px;
  font-size: 2.5rem;
  margin-bottom: 16px;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  h4 {
    font-size: 20px;
    font-size: 2rem;
  }
}

@media (max-width: tablet) {
  h4 {
    font-size: 20px;
    font-size: 2rem;
  }
}

h5 {
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  h5 {
    font-size: 17px;
    font-size: 1.7rem;
  }
}

@media (max-width: tablet) {
  h5 {
    font-size: 17px;
    font-size: 1.7rem;
  }
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  h5 {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

h6 {
  font-size: 18px;
  font-size: 1.8rem;
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  h6 {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

p {
  margin-bottom: 24px;
}

p,
li {
  line-height: 2.7rem;
}

.ul-li-pdTB8 {
  margin: 0;
  padding: 0 0 0 18px;
}

.ul-li-pdTB8 li {
  padding: 8px 0;
}

.fs-56 {
  font-size: 56px;
  font-size: 5.6rem;
}

.fs-48 {
  font-size: 48px;
  font-size: 4.8rem;
}

.fs-36 {
  font-size: 36px;
  font-size: 3.6rem;
}

.fs-28 {
  font-size: 28px;
  font-size: 2.8rem;
}

.fs-20 {
  font-size: 20px;
  font-size: 2rem;
}

.fs-18 {
  font-size: 18px;
  font-size: 1.8rem;
}

.fs-16 {
  font-size: 16px;
  font-size: 1.6rem;
}

.text--heading {
  font-size: 48px;
  font-size: 4.8rem;
}

.text--heading-bigger {
  font-size: 56px;
  font-size: 5.6rem;
}

.text--heading-smaller {
  font-size: 20px;
  font-size: 2rem;
  line-height: 0.83;
  letter-spacing: 0.6px;
}

.text--align-left {
  text-align: left;
}

.text--align-right {
  text-align: right;
}

.text--align-center {
  text-align: center;
}

.text-bigger * {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 30px;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .text-bigger * {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 27px;
  }
}

@media (max-width: tablet) {
  .text-bigger * {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 27px;
  }
}

.text--underline {
  text-decoration: underline;
}

.text--uppercase {
  text-transform: uppercase;
}

.text--small {
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 22px;
}

.text-italic-light {
  margin: 0;
  padding: 40px 20px;
}

.text-italic-light * {
  font-size: 32px;
  font-size: 3.2rem;
  font-family: "HelveticaNeueLightItalic", Helvetica, sans-serif;
  font-weight: 300;
  line-height: 52px;
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .text-italic-light * {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 32px;
  }
}

@media (max-width: tablet) {
  .text-italic-light * {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 32px;
  }
}

.text-italic-light--smaller {
  margin: 0;
  padding: 20px 10px;
}

.text-italic-light--smaller * {
  font-size: 25px;
  font-size: 2.5rem;
  font-family: "HelveticaNeueLightItalic", Helvetica, sans-serif;
  font-weight: 300;
  line-height: 35px;
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .text-italic-light--smaller * {
    font-size: 20px;
    font-size: 2rem;
    line-height: 30px;
  }
}

@media (max-width: tablet) {
  .text-italic-light--smaller * {
    font-size: 20px;
    font-size: 2rem;
    line-height: 30px;
  }
}

.text-white {
  color: white;
}

.text-white * {
  color: white;
}

.text-black {
  color: black;
}

.text-gray-light {
  color: #939594;
}

.text-gray-dark {
  color: #4a4d4d;
}

.text-gray-smoke {
  color: #373838;
}

.text-gray-smoke * {
  color: #373838;
}

.text-green {
  color: #006835;
}

.text-yellow {
  color: #ffd100;
}

.text-highlight {
  margin-bottom: 24px;
  line-height: 30px;
}

.text-highlight span {
  color: #373838;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .text-highlight {
    font-size: 20px;
    font-size: 2rem;
    line-height: 25px;
  }
}

@media (max-width: tablet-landscape) {
  .text-highlight {
    font-size: 20px;
    font-size: 2rem;
    line-height: 25px;
  }
}

.display--inline-block {
  display: inline-block;
}

.vertical-align--middle {
  vertical-align: middle;
}

/* ------------------------------------------------------------ */
/*  Components */
/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/*  Components */
/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/*  Buttons */
/* ------------------------------------------------------------ */
.btn, .btn--primary, .btn--yellow, .btn--green, .btn--white, .btn--white-showcase, .btn--gray, .btn--gray-showcase, .btn--phone {
  font-size: 13px;
  font-size: 1.3rem;
  background: transparent;
  border-radius: 2px;
  border: none;
  box-shadow: 0 0 0 0 transparent;
  font-family: "HelveticaNeue", Helvetica, sans-serif;
  font-weight: bold;
  line-height: 40px;
  outline: 0;
  padding: 0 16px;
  position: relative;
  text-transform: uppercase;
  transition: all .35s ease-in-out;
}

.btn *, .btn--primary *, .btn--yellow *, .btn--green *, .btn--white *, .btn--white-showcase *, .btn--gray *, .btn--gray-showcase *, .btn--phone * {
  transition: all .35s ease-in-out;
}

@media screen and (min-width: 0px) and (max-width: 1140px) {
  .btn, .btn--primary, .btn--yellow, .btn--green, .btn--white, .btn--white-showcase, .btn--gray, .btn--gray-showcase, .btn--phone {
    font-size: 12px;
    font-size: 1.2rem;
    height: auto;
  }
}

@media (max-width: desktop-minimun) {
  .btn, .btn--primary, .btn--yellow, .btn--green, .btn--white, .btn--white-showcase, .btn--gray, .btn--gray-showcase, .btn--phone {
    font-size: 12px;
    font-size: 1.2rem;
    height: auto;
  }
}

.btn:hover, .btn--primary:hover, .btn--yellow:hover, .btn--green:hover, .btn--white:hover, .btn--white-showcase:hover, .btn--gray:hover, .btn--gray-showcase:hover, .btn--phone:hover, .btn:focus, .btn--primary:focus, .btn--yellow:focus, .btn--green:focus, .btn--white:focus, .btn--white-showcase:focus, .btn--gray:focus, .btn--gray-showcase:focus, .btn--phone:focus, .btn:active, .btn--primary:active, .btn--yellow:active, .btn--green:active, .btn--white:active, .btn--white-showcase:active, .btn--gray:active, .btn--gray-showcase:active, .btn--phone:active {
  box-shadow: 0 0 0 0 transparent;
  outline: 0;
}

.btn:active:focus, .btn--primary:active:focus, .btn--yellow:active:focus, .btn--green:active:focus, .btn--white:active:focus, .btn--white-showcase:active:focus, .btn--gray:active:focus, .btn--gray-showcase:active:focus, .btn--phone:active:focus, .btn:active:hover, .btn--primary:active:hover, .btn--yellow:active:hover, .btn--green:active:hover, .btn--white:active:hover, .btn--white-showcase:active:hover, .btn--gray:active:hover, .btn--gray-showcase:active:hover, .btn--phone:active:hover {
  box-shadow: 0 0 0 0 transparent;
  outline: 0;
}

.btn * {
  display: inline-block;
  vertical-align: middle;
}

.btn--disabled {
  cursor: default;
  opacity: .38;
  pointer-events: none;
}

.btn--24 {
  padding-left: 24px;
  padding-right: 24px;
}

.btn--30 {
  padding-left: 30px;
  padding-right: 30px;
}

.btn--36 {
  padding-left: 36px;
  padding-right: 36px;
}

.btn--icon.btn--24 {
  padding-left: 16px;
}

.btn--icon.btn--36 {
  padding-left: 16px;
}

.btn--icon i {
  font-size: 20px;
  font-size: 2rem;
  line-height: 40px;
  margin: 0 10px 0 0;
  text-align: center;
  vertical-align: top;
}

@media screen and (min-width: 0px) and (max-width: 1140px) {
  .btn--icon i {
    font-size: 19px;
    font-size: 1.9rem;
  }
}

@media (max-width: desktop-minimun) {
  .btn--icon i {
    font-size: 19px;
    font-size: 1.9rem;
  }
}

.btn--icon i::before {
  display: inline-block;
  vertical-align: top;
}

.btn--primary {
  font-size: 14px;
  font-size: 1.4rem;
  padding: 0;
}

.btn--primary * {
  color: #4a4d4d;
}

.btn--primary:hover, .btn--primary:focus, .btn--primary:active {
  background: transparent;
  color: #4a4d4d;
  outline: 0;
}

.btn--primary:active:focus, .btn--primary:active:hover {
  background: transparent;
  color: #4a4d4d;
}

.btn--primary.btn--icon i {
  font-size: 14px;
  font-size: 1.4rem;
}

.btn--yellow {
  background: #ffd100;
  color: #4a4d4d;
}

.btn--yellow * {
  color: #4a4d4d;
}

.btn--yellow:hover, .btn--yellow:focus, .btn--yellow:active {
  background: #bd9a00;
  color: white;
}

.btn--yellow:hover *, .btn--yellow:focus *, .btn--yellow:active * {
  color: white;
}

.btn--yellow:active:focus, .btn--yellow:active:hover {
  background: #bd9a00;
  color: white;
}

.btn--yellow:active:focus *, .btn--yellow:active:hover * {
  color: white;
}

.btn--yellow-outline {
  border: 1px solid #ffd100;
}

.btn--yellow-outline * {
  color: #4a4d4d;
}

.btn--green {
  background: #006835;
  color: white;
}

.btn--green * {
  color: white;
}

.btn--green:hover, .btn--green:focus, .btn--green:active {
  background: #004d27;
  color: white;
}

.btn--green:hover *, .btn--green:focus *, .btn--green:active * {
  color: white;
}

.btn--green:active:focus, .btn--green:active:hover {
  background: #004d27;
  color: white;
}

.btn--green:active:focus *, .btn--green:active:hover * {
  color: white;
}

.btn--green-outline {
  background: transparent;
  border: 2px solid #006835;
  color: #006835;
  line-height: 36px;
}

.btn--green-outline * {
  color: #006835;
}

.btn--green-outline:hover {
  background: #006835;
  color: white;
}

.btn--white, .btn--white-showcase {
  background: white;
  color: #6f7271;
}

.btn--white *, .btn--white-showcase * {
  color: #6f7271;
}

.btn--white:hover, .btn--white-showcase:hover, .btn--white:focus, .btn--white-showcase:focus, .btn--white:active, .btn--white-showcase:active {
  background: #d6d6d6;
  color: #6f7271;
}

.btn--white:hover *, .btn--white-showcase:hover *, .btn--white:focus *, .btn--white-showcase:focus *, .btn--white:active *, .btn--white-showcase:active * {
  color: #6f7271;
}

.btn--white:active:focus, .btn--white-showcase:active:focus, .btn--white:active:hover, .btn--white-showcase:active:hover {
  background: #d6d6d6;
  color: #6f7271;
}

.btn--white:active:focus *, .btn--white-showcase:active:focus *, .btn--white:active:hover *, .btn--white-showcase:active:hover * {
  color: #6f7271;
}

.btn--white-outline {
  border: 1px solid white;
}

.btn--white-outline * {
  color: white;
}

.btn--white-showcase {
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .btn--white-showcase {
    line-height: 30px;
    font-size: 0.9rem;
  }
}

.btn--gray, .btn--gray-showcase {
  background: #6f7271;
  color: white;
}

.btn--gray *, .btn--gray-showcase * {
  color: white;
}

.btn--gray:hover, .btn--gray-showcase:hover, .btn--gray:focus, .btn--gray-showcase:focus, .btn--gray:active, .btn--gray-showcase:active {
  background: #525453;
  color: white;
}

.btn--gray:hover *, .btn--gray-showcase:hover *, .btn--gray:focus *, .btn--gray-showcase:focus *, .btn--gray:active *, .btn--gray-showcase:active * {
  color: white;
}

.btn--gray:active:focus, .btn--gray-showcase:active:focus, .btn--gray:active:hover, .btn--gray-showcase:active:hover {
  background: #525453;
  color: white;
}

.btn--gray:active:focus *, .btn--gray-showcase:active:focus *, .btn--gray:active:hover *, .btn--gray-showcase:active:hover * {
  color: white;
}

.btn--gray-outline {
  border: 1px solid #6f7271;
}

.btn--gray-outline * {
  color: #6f7271;
}

.btn--gray-showcase {
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .btn--gray-showcase {
    line-height: 30px;
    font-size: 0.9rem;
  }
}

.btn--phone {
  font-size: 16px;
  font-size: 1.6rem;
  align-items: center;
  background: transparent;
  border-radius: 30px;
  border: 1px solid #B7B8B8;
  color: #6f7271;
  display: flex;
  font-weight: normal;
  justify-content: center;
  line-height: 36px;
  margin-bottom: 24px;
  max-width: 174px;
  padding-bottom: 0;
  padding-top: 0;
  transition: all .35s ease-in-out;
}

@media (max-width: 991px) {
  .btn--phone {
    margin-left: auto;
    margin-right: auto;
  }
}

.btn--phone i {
  font-size: 18px;
  font-size: 1.8rem;
  align-items: center;
  color: #6f7271;
  display: flex;
  height: 18px;
  justify-content: center;
  transition: all .35s ease-in-out;
  width: 18px;
}

.btn--phone i::before {
  align-items: center;
  display: flex;
  height: 18px;
  justify-content: center;
  width: 18px;
}

.btn--phone:hover, .btn--phone:focus:hover {
  background: #6f7271;
  color: white;
}

.btn--phone:hover i, .btn--phone:focus:hover i {
  color: white;
}

/* ------------------------------------------------------------ */
/*  Backgrounds */
/* ------------------------------------------------------------ */
.bg-logo-br {
  position: relative;
  overflow: hidden;
}

.bg-logo-br::before {
  background-image: url(../images/bg_pattern.svg);
  background-position: left 275px;
  background-repeat: no-repeat;
  content: '';
  height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 580px;
  z-index: 0;
  opacity: .5;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .bg-logo-br::before {
    background-position: left top;
    background-size: contain;
    bottom: -170px;
    right: -217px;
    width: 500px;
    height: 310px;
  }
}

@media (max-width: tablet) {
  .bg-logo-br::before {
    background-position: left top;
    background-size: contain;
    bottom: -170px;
    right: -217px;
    width: 500px;
    height: 310px;
  }
}

.bg-logo-br-2 {
  position: relative;
  overflow: hidden;
}

.bg-logo-br-2::before {
  background-image: url(../images/bg_pattern.svg);
  background-position: left 65px;
  background-repeat: no-repeat;
  content: '';
  height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 346px;
  z-index: 0;
  opacity: .2;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .bg-logo-br-2::before {
    background-position: center center;
    background-size: contain;
    bottom: -40px;
    right: -373px;
    width: 477px;
    height: 307px;
  }
}

@media (max-width: tablet) {
  .bg-logo-br-2::before {
    background-position: center center;
    background-size: contain;
    bottom: -40px;
    right: -373px;
    width: 477px;
    height: 307px;
  }
}

.bg-logo-br-3 {
  position: relative;
  overflow: hidden;
}

.bg-logo-br-3::before {
  background-image: url(../images/bg_pattern_gray.svg);
  background-position: bottom right;
  background-repeat: no-repeat;
  bottom: 0;
  content: '';
  height: 268px;
  margin: 0;
  opacity: .06;
  padding: 0;
  position: absolute;
  right: 0;
  transform: rotate(180deg);
  width: 348px;
  z-index: 0;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .bg-logo-br-3::before {
    opacity: 0;
  }
}

@media (max-width: tablet) {
  .bg-logo-br-3::before {
    opacity: 0;
  }
}

.bg-logo-bl {
  position: relative;
  overflow: hidden;
}

.bg-logo-bl::before {
  background-image: url(../images/bg_pattern_gray.svg);
  background-position: right top;
  background-repeat: no-repeat;
  content: '';
  height: 370px;
  left: 0;
  margin: 0;
  opacity: 1;
  padding: 0;
  position: absolute;
  bottom: 0;
  width: 438px;
  z-index: 0;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .bg-logo-bl::before {
    background-position: right center;
    background-size: contain;
    top: 0;
    left: -373px;
    width: 512px;
    height: 326px;
    opacity: .83;
  }
}

@media (max-width: tablet) {
  .bg-logo-bl::before {
    background-position: right center;
    background-size: contain;
    top: 0;
    left: -373px;
    width: 512px;
    height: 326px;
    opacity: .83;
  }
}

.bg-logo-tl {
  position: relative;
  overflow: hidden;
}

.bg-logo-tl::before {
  background-image: url(../images/bg_pattern_gray.svg);
  background-position: bottom right;
  background-repeat: no-repeat;
  content: '';
  height: 266px;
  left: 0;
  margin: 0;
  opacity: .54;
  padding: 0;
  position: absolute;
  top: 0;
  width: 438px;
  z-index: 0;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .bg-logo-tl::before {
    opacity: 0;
  }
}

@media (max-width: tablet) {
  .bg-logo-tl::before {
    opacity: 0;
  }
}

.bg-logo-tr {
  position: relative;
  overflow: hidden;
}

.bg-logo-tr::before {
  background-image: url(../images/bg_pattern_gray.svg);
  background-position: right top;
  background-repeat: no-repeat;
  content: '';
  height: 368px;
  right: 0;
  margin: 0;
  opacity: .1;
  padding: 0;
  position: absolute;
  top: 0;
  width: 348px;
  z-index: 0;
  transform: rotate(180deg);
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .bg-logo-tr::before {
    opacity: 0;
  }
}

@media (max-width: tablet) {
  .bg-logo-tr::before {
    opacity: 0;
  }
}

.bg-content {
  position: relative;
  z-index: 1;
}

.form-group {
  padding: 0;
  margin: 0 0 8px 0;
  position: relative;
}

.form-group:last-child {
  padding-top: 8px;
  margin-bottom: 0;
}

.form-group button {
  background: white;
  color: #6f7271;
  margin: 0;
  padding: 0;
  width: 100%;
}

.form-control__select {
  padding: 0;
  position: relative;
}

.form-control__select::after {
  border-bottom: none;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid white;
  content: '';
  display: block;
  height: 8px;
  margin: 0;
  opacity: .84;
  padding: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all .4s ease-in-out;
  width: 10px;
  z-index: 1;
}

.form-control__select select {
  font-size: 16px;
  font-size: 1.6rem;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  font-weight: normal;
  padding-right: 24px;
  position: relative;
  z-index: 2;
}

.form-control__select select option {
  color: white;
  padding: 5px;
}

input[type='number'] {
  -moz-appearance: textfield;
  /*For FireFox*/
}

input[type='number']::-webkit-inner-spin-button {
  /*For Webkits like Chrome and Safari*/
  -webkit-appearance: none;
  margin: 0;
}

.input-label {
  display: block;
  margin: 0;
  padding: 16px 0 0 0;
  position: relative;
}

.input-label::after {
  border-radius: 1px;
  bottom: 0;
  content: '';
  height: 2px;
  left: 0;
  margin: 0;
  opacity: 1;
  padding: 0;
  position: absolute;
  width: 100%;
  transition: all .35s ease-in-out;
}

.input-label label {
  font-size: 13px;
  font-size: 1.3rem;
  color: #6f7271;
  font-weight: normal;
  left: 0;
  line-height: 16px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  transform: translate(0, 0);
  transition: all .25s ease-in-out;
  z-index: 1;
}

.input-label input,
.input-label select {
  font-size: 16px;
  font-size: 1.6rem;
  background: transparent;
  border-radius: 0;
  border: none;
  color: #6f7271;
  display: block;
  height: 40px;
  margin: 0;
  opacity: .84;
  outline: none;
  padding: 0;
  position: relative;
  text-align: left;
  width: 100%;
  z-index: 2;
}

.input-label input::-webkit-input-placeholder,
.input-label select::-webkit-input-placeholder {
  color: #6f7271;
}

.input-label input::-moz-placeholder,
.input-label select::-moz-placeholder {
  color: #6f7271;
}

.input-label input:-ms-input-placeholder,
.input-label select:-ms-input-placeholder {
  color: #6f7271;
}

.input-label input:-moz-placeholder,
.input-label select:-moz-placeholder {
  color: #6f7271;
}

.input-label input:-webkit-autofill,
.input-label select:-webkit-autofill {
  background: transparent !important;
  color: #6f7271;
  -webkit-text-fill-color: #6f7271 !important;
  box-shadow: 0 0 0 300px white inset;
}

.input-label input:focus,
.input-label select:focus {
  background: transparent;
  box-shadow: 0 0 0 300px white inset;
  border: none;
}

.input-label--is-empty::after {
  height: 1px;
}

.input-label--is-empty label {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: normal;
  top: 50%;
  transform: translate(0, calc(-50% + 8px));
}

.input-label--is-focused::after {
  height: 2px;
}

.input-label--is-focused label {
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 16px;
  top: 0;
  transform: translate(0, 0);
}

.submit {
  font-size: 13px;
  font-size: 1.3rem;
  background: transparent;
  border: none;
  color: white;
  font-weight: bold;
  letter-spacing: 2px;
  margin: 10px 0;
  position: relative;
  text-align: left;
  text-transform: uppercase;
}

@media (max-width: medium) {
  .submit {
    text-align: center;
  }
}

.scrolling-menu-wrapper {
  height: 72px;
  position: relative;
  width: 100%;
  display: block;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .scrolling-menu-wrapper {
    height: 48px;
  }
}

@media (max-width: tablet-landscape) {
  .scrolling-menu-wrapper {
    height: 48px;
  }
}

.scrolling-menu-wrapper--fixed .scrolling-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.scrolling-menu {
  background: white;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
  display: block;
  height: 72px;
  padding: 0;
  position: relative;
  width: 100%;
  z-index: 13;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .scrolling-menu {
    height: 48px;
  }
}

@media (max-width: tablet-landscape) {
  .scrolling-menu {
    height: 48px;
  }
}

.scrolling-menu ul {
  align-items: stretch;
  background: white;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  transition: .35s all ease-in-out;
  width: 100%;
  max-width: 1110px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .scrolling-menu ul {
    display: block;
    overflow: hidden;
    position: relative;
  }
  .scrolling-menu ul::after {
    content: "\68";
    position: absolute;
    top: 14px;
    right: 15px;
    z-index: 2;
    pointer-events: none;
    font-family: "w-icons" !important;
  }
}

@media (max-width: tablet-landscape) {
  .scrolling-menu ul {
    display: block;
    overflow: hidden;
    position: relative;
  }
  .scrolling-menu ul::after {
    content: "\68";
    position: absolute;
    top: 14px;
    right: 15px;
    z-index: 2;
    pointer-events: none;
    font-family: "w-icons" !important;
  }
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .scrolling-menu ul.open {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
    height: auto;
  }
  .scrolling-menu ul.open a {
    position: relative;
    opacity: 1;
  }
  .scrolling-menu ul.open a.active {
    color: white;
    background: rgba(111, 114, 113, 0.4);
  }
}

@media (max-width: tablet-landscape) {
  .scrolling-menu ul.open {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
    height: auto;
  }
  .scrolling-menu ul.open a {
    position: relative;
    opacity: 1;
  }
  .scrolling-menu ul.open a.active {
    color: white;
    background: rgba(111, 114, 113, 0.4);
  }
}

.scrolling-menu li {
  margin: 0;
  padding: 0;
  width: 24%;
  display: flex;
  flex-grow: 1;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .scrolling-menu li {
    width: 100%;
    display: block;
  }
}

@media (max-width: tablet-landscape) {
  .scrolling-menu li {
    width: 100%;
    display: block;
  }
}

.scrolling-menu a {
  font-size: 14px;
  font-size: 1.4rem;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 10px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
  color: #6f7271;
  font-weight: 500;
  transition: .01s all ease-in-out;
}

@media screen and (min-width: 0px) and (max-width: 1140px) {
  .scrolling-menu a {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

@media (max-width: desktop-minimun) {
  .scrolling-menu a {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .scrolling-menu a {
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

@media (max-width: tablet-landscape) {
  .scrolling-menu a {
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.scrolling-menu a:hover {
  color: #6f7271;
}

.scrolling-menu a::after {
  background: #ffd100;
  bottom: 0;
  content: '';
  height: 3px;
  left: 0;
  opacity: 0;
  position: absolute;
  width: 100%;
  transition: .35s all ease-in-out;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .scrolling-menu a::after {
    display: none;
  }
}

@media (max-width: tablet-landscape) {
  .scrolling-menu a::after {
    display: none;
  }
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .scrolling-menu a.active {
    opacity: 1;
    z-index: 2;
  }
}

@media (max-width: tablet-landscape) {
  .scrolling-menu a.active {
    opacity: 1;
    z-index: 2;
  }
}

.scrolling-menu a.active::after {
  opacity: 1;
}

.textwidget table {
  text-align: center;
  width: 100%;
}

.textwidget th, .textwidget td {
  text-align: center;
}

.textwidget td {
  padding: 8px;
}

.textwidget thead {
  background-color: #4a4d4d;
  color: white;
}

.textwidget th {
  padding: 16px 8px;
}

.textwidget tbody {
  background-color: rgba(0, 0, 0, 0.08);
}

.textwidget tbody td {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-width: 0 1px 1px 0;
}

.textwidget tbody td:last-child {
  border-width: 0px 0 1px 0;
}

.textwidget tfoot strong {
  color: #4a4d4d;
}

.textwidget tfoot tr:last-child strong {
  font-weight: bold;
}

.textwidget tfoot tr:last-child td {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.textwidget tfoot tr:last-child td:first-child {
  border: 0;
}

.page-tasas-de-referencia table {
  text-align: center;
  width: auto;
}

.page-tasas-de-referencia th strong, .page-tasas-de-referencia th b, .page-tasas-de-referencia td strong, .page-tasas-de-referencia td b {
  color: #4a4d4d;
  font-weight: bold;
}

.page-tasas-de-referencia tbody {
  background-color: rgba(0, 0, 0, 0.08);
}

.page-tasas-de-referencia tbody td {
  border: none;
}

/* ------------------------------------------------------------ */
/*  Modals */
/* ------------------------------------------------------------ */
/* Important part */
.modal {
  z-index: 100001;
}

.modal-content {
  border: none;
}

.modal-header {
  border: none;
}

.modal-header .close {
  background: #6f7271;
  border-radius: 2px;
  color: white;
  height: 32px;
  margin: 0;
  opacity: 1;
  padding: 0;
  position: absolute;
  right: -48px;
  text-align: center;
  text-shadow: 0 0 0 transparent;
  top: 0;
  width: 32px;
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .modal-header .close {
    background: transparent;
    right: 0;
  }
}

.modal-footer > :not(:first-child) {
  margin-left: 12px;
}

.modal-calculadora .modal-dialog {
  max-width: 445px;
}

.modal-calculadora img {
  margin-bottom: 40px;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .modal-calculadora img {
    max-width: 150px;
    margin-bottom: 32px;
  }
}

@media (max-width: mobile-landscape) {
  .modal-calculadora img {
    max-width: 150px;
    margin-bottom: 32px;
  }
}

.modal-calculadora h5 {
  font-size: 20px;
  font-size: 2rem;
  margin: 0 0 24px 0;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .modal-calculadora h5 {
    font-size: 18px;
    font-size: 1.8rem;
    margin-bottom: 8px;
  }
}

@media (max-width: mobile-landscape) {
  .modal-calculadora h5 {
    font-size: 18px;
    font-size: 1.8rem;
    margin-bottom: 8px;
  }
}

.modal-calculadora p {
  font-size: 13px;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 22px;
  margin: 0 0 16px 0;
}

.modal-calculadora .big-text {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.69;
  margin-bottom: 24px;
}

.modal-calculadora .modal-content {
  margin: 0;
  padding: 32px;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .modal-calculadora .modal-content {
    padding: 40px 24px;
  }
}

@media (max-width: mobile-landscape) {
  .modal-calculadora .modal-content {
    padding: 40px 24px;
  }
}

.modal-calculadora .modal-body,
.modal-calculadora .modal-footer {
  margin: 0;
  padding: 0;
  border: none;
}

.modal-calculadora .form-container {
  margin-bottom: 24px;
}

.modal-calculadora .form-group {
  padding: 0;
}

.modal-calculadora .form-group::after {
  background: #b7b8b8;
}

.modal-calculadora .input-label::after {
  background: #006835;
}

.modal-calculadora .input-label label {
  color: #006835;
}

.modal-calculadora .input-label--is-empty::after {
  background: #6f7271;
}

.modal-calculadora .input-label--is-empty label {
  color: #6f7271;
}

.modal-calculadora .input-label--is-focused::after {
  background: #006835;
}

.modal-calculadora .input-label--is-focused label {
  color: #006835;
}

.showcase-slide {
  margin: 0 !important;
  max-height: 836px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 100%;
}

.showcase-slide .showcase .showcase__content {
  padding-bottom: 64px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .showcase-slide .showcase .showcase__content {
    padding-bottom: 24px;
  }
}

@media (max-width: tablet-landscape) {
  .showcase-slide .showcase .showcase__content {
    padding-bottom: 24px;
  }
}

.showcase-slide .slick-arrow {
  width: 60px;
  height: 60px;
  z-index: 10;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .showcase-slide .slick-arrow {
    display: none !important;
  }
}

@media (max-width: tablet-landscape) {
  .showcase-slide .slick-arrow {
    display: none !important;
  }
}

.showcase-slide .slick-prev {
  left: 40px;
}

.showcase-slide .slick-prev::before {
  content: "\6f";
  font-family: "w-icons";
  font-size: 40px;
}

.showcase-slide .slick-next {
  right: 40px;
}

.showcase-slide .slick-next::before {
  content: "\41";
  font-family: "w-icons";
  font-size: 40px;
}

.showcase-slide .slick-dots {
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 64px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .showcase-slide .slick-dots {
    height: 24px;
  }
}

@media (max-width: tablet-landscape) {
  .showcase-slide .slick-dots {
    height: 24px;
  }
}

.showcase-slide .slick-dots li {
  margin: 0 8px;
}

.showcase-slide .slick-dots li button::before {
  font-size: 16px;
  color: white;
  transition: all .35s ease-in-out;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .showcase-slide .slick-dots li button::before {
    font-size: 12px;
  }
}

@media (max-width: tablet-landscape) {
  .showcase-slide .slick-dots li button::before {
    font-size: 12px;
  }
}

.showcase-slide .slick-dots li.slick-active button::before {
  color: white;
}

.showcase {
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
}

.showcase .showcase__image {
  margin: 0;
  padding: 0;
  position: relative;
  max-height: 520px;
  overflow: hidden;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .showcase .showcase__image {
    display: block;
  }
}

@media (max-width: tablet) {
  .showcase .showcase__image {
    display: block;
  }
}

.showcase .showcase__image img {
  height: auto;
  margin: 0;
  padding: 0;
  width: 100%;
  opacity: 0;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .showcase .showcase__image img {
    left: 50%;
    min-width: 570px;
    position: relative;
    transform: translate(-50%, 0);
  }
}

@media (max-width: mobile-landscape) {
  .showcase .showcase__image img {
    left: 50%;
    min-width: 570px;
    position: relative;
    transform: translate(-50%, 0);
  }
}

.showcase .showcase__image_blur {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  -webkit-filter: blur(20px);
  -moz-filter: blur(20px);
  -o-filter: blur(20px);
  -ms-filter: blur(20px);
  filter: blur(20px);
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: scale(1.1);
}

.showcase .showcase__image_blur::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.48);
}

.showcase .showcase__image_text h3 {
  color: white;
  font-size: 40px;
  font-weight: bold;
  line-height: 49px;
  margin-bottom: 24px;
}

.showcase .showcase__image_text p {
  color: rgba(255, 255, 255, 0.55);
}

.showcase .showcase__image--full img {
  opacity: 1;
  width: 100%;
  height: auto;
}

.showcase .showcase__content {
  background: linear-gradient(180deg, rgba(51, 51, 51, 0) 0%, rgba(55, 56, 56, 0.86) 100%);
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 98px 0 40px 0;
  position: absolute;
  width: 100%;
  min-height: 240px;
  display: flex;
  align-items: flex-end;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .showcase .showcase__content {
    padding: 0 0 16px 0;
    min-height: 1px;
    height: 100%;
  }
}

@media (max-width: tablet-landscape) {
  .showcase .showcase__content {
    padding: 0 0 16px 0;
    min-height: 1px;
    height: 100%;
  }
}

.showcase .showcase__title {
  color: white;
  margin-bottom: 32px;
  padding-bottom: 17px;
  position: relative;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .showcase .showcase__title {
    margin-bottom: 0;
    font-size: 20px;
  }
}

@media (max-width: tablet-landscape) {
  .showcase .showcase__title {
    margin-bottom: 0;
    font-size: 20px;
  }
}

.showcase .showcase__title span {
  font-size: 25px;
  font-size: 2.5rem;
  display: block;
  font-weight: 500;
  margin: 0;
  padding: 16px 0 0 0;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .showcase .showcase__title span {
    font-size: 16px;
    font-size: 1.6rem;
    padding: 0;
  }
}

@media (max-width: tablet) {
  .showcase .showcase__title span {
    font-size: 16px;
    font-size: 1.6rem;
    padding: 0;
  }
}

.showcase .showcase__title::after {
  background: #ffd100;
  bottom: 0;
  content: '';
  height: 2px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 16px;
}

.showcase p {
  font-size: 18px;
  font-size: 1.8rem;
  color: white;
  margin: 0;
  padding: 0;
}

.showcase--no-info .showcase__title {
  margin-bottom: 0;
}

.showcase--no-info .showcase__info {
  display: none;
}

.showcase-v2 {
  align-items: center;
  display: flex;
  margin: 0;
  padding: 0;
  vertical-align: center;
  width: 100%;
}

.showcase-v2 .showcase-info-content {
  margin: 0;
  padding: 0;
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.showcase-v2 .showcase-info {
  align-items: center;
  display: flex;
  margin: 0;
  padding: 10px 30px 10px 15px;
  vertical-align: center;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 570px;
}

.showcase-v2 .showcase-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  padding: 0;
  width: 50%;
}

.showcase-v2 .showcase-image img {
  margin: 0;
  padding: 0;
  height: auto;
  opacity: 0;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .showcase-v2 .showcase-image img {
    width: 100%;
    opacity: 1;
  }
}

@media (max-width: tablet) {
  .showcase-v2 .showcase-image img {
    width: 100%;
    opacity: 1;
  }
}

.showcase-v2 .showcase__title {
  margin-bottom: 32px;
  padding-bottom: 17px;
  position: relative;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .showcase-v2 .showcase__title {
    margin-bottom: 0;
    font-size: 20px;
  }
}

@media (max-width: mobile-landscape) {
  .showcase-v2 .showcase__title {
    margin-bottom: 0;
    font-size: 20px;
  }
}

.showcase-v2 .showcase__title::after {
  background: #ffd100;
  bottom: 0;
  content: '';
  height: 2px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 16px;
}

/* ------------------------------------------------------------ */
/*  Calculator */
/* ------------------------------------------------------------ */
.calculator-form {
  background: #373838;
  margin: 0;
  padding: 40px;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .calculator-form {
    padding: 24px 16px;
  }
}

@media (max-width: tablet) {
  .calculator-form {
    padding: 24px 16px;
  }
}

.calculator-form * {
  color: rgba(255, 255, 255, 0.75);
}

.calculator-form .text--small {
  color: #939594;
}

.calculator-form__header {
  display: block;
  font-size: 0;
  margin: 0 0 26px 0;
  padding: 0;
  text-align: center;
}

.calculator-form__header h4 {
  display: inline-block;
  line-height: normal;
  margin: 0 8px 0 0;
  vertical-align: middle;
}

.calculator-form__header .calculator-form__select {
  display: inline-block;
  max-width: 144px;
  vertical-align: middle;
  position: relative;
}

.calculator-form__header .calculator-form__select::after {
  font-size: 18px;
  font-size: 1.8rem;
  color: #ffd100;
  content: "\68";
  font-family: w-icons !important;
  font-style: normal;
  font-weight: normal !important;
  height: 10px;
  line-height: 10px;
  position: absolute;
  right: 0;
  top: 54%;
  transform: translate(0, -50%);
  vertical-align: top;
  width: 24px;
  z-index: 1;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .calculator-form__header .calculator-form__select::after {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

@media (max-width: tablet) {
  .calculator-form__header .calculator-form__select::after {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.calculator-form__header .calculator-form__select select {
  font-size: 25px;
  font-size: 2.5rem;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: none;
  color: #ffd100;
  display: block;
  line-height: normal;
  outline: none;
  padding: 0 32px 0 0;
  position: relative;
  z-index: 2;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .calculator-form__header .calculator-form__select select {
    font-size: 20px;
    font-size: 2rem;
  }
}

@media (max-width: tablet) {
  .calculator-form__header .calculator-form__select select {
    font-size: 20px;
    font-size: 2rem;
  }
}

.calculator-form__header .calculator-form__select select option {
  background: #373838;
  color: #ffd100;
}

.calculator-form__content {
  margin: 0;
  padding: 0;
}

.calculator-form__content .calculator-form__field {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 16px;
  padding: 0 0 16px 0;
}

.calculator-form__content .calculator-form__field:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.calculator-form__content * {
  margin: 0;
  padding: 0;
}

.calculator-form__content i {
  font-size: 21px;
  font-size: 2.1rem;
  border: none;
  color: #6f7271;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

.calculator-form__content i.w-icon-ic-interest {
  font-size: 13px;
  font-size: 1.3rem;
}

.calculator-form__content input,
.calculator-form__content select {
  font-size: 16px;
  font-size: 1.6rem;
  background: white;
  border-radius: 2px;
  border: none;
  color: #6f7271;
  height: 40px;
  line-height: 40px;
  padding: 0 8px;
  text-align: right;
  vertical-align: middle;
  width: 100%;
}

.calculator-form__content select option {
  background: white;
  color: #6f7271;
}

.calculator-form__content .select-container {
  background: white;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
}

.calculator-form__content .select-container select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  position: relative;
  z-index: 2;
}

.calculator-form__content .select-container::after {
  font-size: 10px;
  font-size: 1rem;
  color: #6f7271;
  content: "\6a";
  font-family: "w-icons" !important;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translate(0, -50%);
  width: 40px;
}

.calculator-results {
  margin: 0;
  padding: 40px;
  background-color: #F7F7F7;
}

.calculator-results--white {
  background: white;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .calculator-results {
    padding: 24px 16px;
  }
}

@media (max-width: tablet) {
  .calculator-results {
    padding: 24px 16px;
  }
}

.calculator-results .calculator-number-wrapper * {
  margin: 0;
  padding: 0;
}

.calculator-results .calculator-number {
  font-weight: bold;
  margin-bottom: 8px;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .calculator-results .calculator-number {
    font-size: 32px;
    font-size: 3.2rem;
  }
}

@media (max-width: tablet) {
  .calculator-results .calculator-number {
    font-size: 32px;
    font-size: 3.2rem;
  }
}

.calculator-results .separator {
  background-color: rgba(111, 114, 113, 0.25);
  display: block;
  height: 2px;
  width: 100%;
}

/* ------------------------------------------------------------ */
/*  Quote List */
/* ------------------------------------------------------------ */
.quote-list-container {
  overflow: hidden;
  position: relative;
}

.quote-list-container::after {
  background-image: url("../images/ic_sabias_que_bg.png");
  background-position: center center;
  background-repeat: no-repeat;
  content: '';
  display: block;
  height: 100%;
  left: 60px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 327px;
  z-index: 0;
}

.quote-list-container .quote-list-content {
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .quote-list-container::after {
    width: 172px;
    height: 100%;
    left: 0;
    background-size: 100% auto;
    background-position: -25px -10px;
  }
}

@media (max-width: tablet-landscape) {
  .quote-list-container::after {
    width: 172px;
    height: 100%;
    left: 0;
    background-size: 100% auto;
    background-position: -25px -10px;
  }
}

.quote-list-container .quote-list {
  margin: 0 0 50px 0 !important;
  position: static;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .quote-list-container .quote-list {
    margin-bottom: 32px !important;
  }
}

@media (max-width: tablet-landscape) {
  .quote-list-container .quote-list {
    margin-bottom: 32px !important;
  }
}

.quote-list-container .quote-list .quote-title {
  margin-bottom: 32px;
  text-align: center;
  color: white;
  font-weight: bold;
}

.quote-list-container .quote-list .quote-content * {
  font-size: 32px;
  font-size: 3.2rem;
  color: white;
  font-family: "HelveticaNeueLightItalic", Helvetica, sans-serif;
  font-weight: 300;
  line-height: 52px;
  margin: 0;
  padding: 0;
  text-align: center;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .quote-list-container .quote-list .quote-content * {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 32px;
  }
}

@media (max-width: tablet) {
  .quote-list-container .quote-list .quote-content * {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 32px;
  }
}

.quote-list-container .quote-list .quote-image {
  border-radius: 50%;
  height: 64px;
  margin: 0 auto 16px auto;
  overflow: hidden;
  padding: 0;
  width: 64px;
}

.quote-list-container .quote-list .quote-detail .quote-name {
  font-size: 16px;
  font-size: 1.6rem;
  color: white;
  font-weight: 500;
  margin: 0 0 8px 0;
  padding: 0;
  text-align: center;
}

.quote-list-container .quote-list .quote-detail .quote-position {
  font-size: 16px;
  font-size: 1.6rem;
  color: rgba(255, 255, 255, 0.72);
  font-weight: normal;
  margin: 0;
  padding: 0;
  text-align: center;
}

.quote-list-container .slick-arrow {
  width: 60px;
  height: 60px;
  z-index: 10;
}

.quote-list-container .slick-prev {
  left: 128px;
}

@media screen and (min-width: 0px) and (max-width: 1140px) {
  .quote-list-container .slick-prev {
    left: 64px;
  }
}

@media (max-width: desktop-minimun) {
  .quote-list-container .slick-prev {
    left: 64px;
  }
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .quote-list-container .slick-prev {
    display: none !important;
  }
}

@media (max-width: tablet-landscape) {
  .quote-list-container .slick-prev {
    display: none !important;
  }
}

.quote-list-container .slick-prev::before {
  content: "\46";
  font-family: "w-icons";
  font-size: 40px;
}

.quote-list-container .slick-next {
  right: 128px;
}

@media screen and (min-width: 0px) and (max-width: 1140px) {
  .quote-list-container .slick-next {
    right: 64px;
  }
}

@media (max-width: desktop-minimun) {
  .quote-list-container .slick-next {
    right: 64px;
  }
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .quote-list-container .slick-next {
    display: none !important;
  }
}

@media (max-width: tablet-landscape) {
  .quote-list-container .slick-next {
    display: none !important;
  }
}

.quote-list-container .slick-next::before {
  content: "\47";
  font-family: "w-icons";
  font-size: 40px;
}

.quote-list-container .slick-dots {
  bottom: 48px;
  left: 0;
  position: absolute;
  width: 100%;
  height: 80px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .quote-list-container .slick-dots {
    bottom: 0;
    height: 72px;
  }
}

@media (max-width: tablet-landscape) {
  .quote-list-container .slick-dots {
    bottom: 0;
    height: 72px;
  }
}

.quote-list-container .slick-dots li {
  margin: 0 8px;
}

.quote-list-container .slick-dots li button::before {
  font-size: 16px;
  color: white;
  transition: all .35s ease-in-out;
}

.quote-list-container .slick-dots li.slick-active button::before {
  color: white;
}

.quote-list-container--v2 {
  background-image: none;
}

.quote-list-container--v2::after {
  left: calc(100% - 278px);
  transform: rotate(180deg);
  width: 278px;
  background-position: right center;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .quote-list-container--v2::after {
    width: 172px;
    height: 100%;
    left: calc(100% - 172px);
    background-size: 100% auto;
    background-position: center calc(100% + 11px);
  }
}

@media (max-width: tablet) {
  .quote-list-container--v2::after {
    width: 172px;
    height: 100%;
    left: calc(100% - 172px);
    background-size: 100% auto;
    background-position: center calc(100% + 11px);
  }
}

.quote-list-container--v2 .quote-list {
  margin: 0 !important;
}

.quote-list-container--v2 .quote-list .quote-content * {
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 30px;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .quote-list-container--v2 .quote-list .quote-content * {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 26px;
  }
}

@media (max-width: tablet) {
  .quote-list-container--v2 .quote-list .quote-content * {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 26px;
  }
}

.quote-list-container--v2 .slick-dots {
  position: relative;
  bottom: 0;
  height: 64px;
}

.quote-list-container--v2 .slick-dots li {
  width: 16px;
  height: 16px;
}

.quote-list-container--v2 .slick-dots li button {
  width: 16px;
  height: 16px;
  margin: 0;
  padding: 0;
}

.quote-list-container--v2 .slick-dots li button::before {
  width: 16px;
  height: 16px;
  font-size: 12px;
}

.quote-list-container--v3 {
  background-image: none;
}

.quote-list-container--v3::after {
  width: 100%;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(to left, rgba(168, 171, 170, 0), rgba(161, 164, 163, 0.25) 20%, rgba(139, 142, 141, 0.25) 52%, rgba(120, 123, 122, 0.25) 78%, rgba(111, 114, 113, 0));
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .quote-list-container--v3::after {
    background-position: center center;
    background: rgba(111, 114, 113, 0.25);
  }
}

@media (max-width: tablet) {
  .quote-list-container--v3::after {
    background-position: center center;
    background: rgba(111, 114, 113, 0.25);
  }
}

.quote-list-container--v3 .quote-list {
  margin: 0 !important;
}

.quote-list-container--v3 .quote-list .quote-content {
  margin-bottom: 32px;
}

.quote-list-container--v3 .quote-list .quote-content * {
  font-size: 25px;
  font-size: 2.5rem;
  line-height: 1.6;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .quote-list-container--v3 .quote-list .quote-content * {
    font-size: 25px;
    font-size: 2.5rem;
    line-height: 1.6;
  }
}

@media (max-width: tablet) {
  .quote-list-container--v3 .quote-list .quote-content * {
    font-size: 25px;
    font-size: 2.5rem;
    line-height: 1.6;
  }
}

.quote-list-container--v3 .slick-dots {
  position: relative;
  bottom: 0;
  height: 64px;
}

.quote-list-container--v3 .slick-dots li {
  width: 16px;
  height: 16px;
}

.quote-list-container--v3 .slick-dots li button {
  width: 16px;
  height: 16px;
  margin: 0;
  padding: 0;
}

.quote-list-container--v3 .slick-dots li button::before {
  width: 16px;
  height: 16px;
  font-size: 12px;
}

/* ------------------------------------------------------------ */
/*  Search result card */
/* ------------------------------------------------------------ */
.page-search-result {
  display: flex;
  margin: 0 0 24px 0;
  padding: 0 0 24px 0;
  position: relative;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .page-search-result {
    flex-wrap: wrap;
    width: 100%;
  }
}

@media (max-width: mobile-landscape) {
  .page-search-result {
    flex-wrap: wrap;
    width: 100%;
  }
}

.page-search-result::after {
  background-color: rgba(0, 0, 0, 0.25);
  bottom: -0.5px;
  content: '';
  height: 1px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
}

.page-search-result:last-child {
  margin: 0;
}

.page-search-result-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 30px 0 0;
  min-width: 255px;
  padding: 0;
  position: relative;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .page-search-result-image {
    margin: 0 0 16px 0;
    width: 100%;
  }
}

@media (max-width: mobile-landscape) {
  .page-search-result-image {
    margin: 0 0 16px 0;
    width: 100%;
  }
}

.page-search-result-image img {
  margin: 0;
  opacity: 0;
  padding: 0;
  position: relative;
  z-index: 1;
}

.page-search-result-image--video::before {
  align-items: center;
  background: white;
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.8);
  content: "\76";
  display: flex;
  font-family: "w-icons";
  height: 36px;
  justify-content: center;
  left: 50%;
  margin: 0;
  padding: 0 0 0 2px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 36px;
  z-index: 3;
}

.page-search-result-image--video::after {
  background-color: rgba(0, 0, 0, 0.18);
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}

.page-search-result-info {
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .page-search-result-info {
    width: 100%;
  }
}

@media (max-width: mobile-landscape) {
  .page-search-result-info {
    width: 100%;
  }
}

.page-search-result-title {
  margin: 0 0 16px 0;
  padding: 0;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .page-search-result-title {
    font-size: 20px;
    font-size: 2rem;
    margin-bottom: 14px;
  }
}

@media (max-width: tablet) {
  .page-search-result-title {
    font-size: 20px;
    font-size: 2rem;
    margin-bottom: 14px;
  }
}

.page-search-result-title a {
  color: #373838;
}

.page-search-result-breadcrumb {
  margin: 0 0 16px 0;
  padding: 0;
  font-size: 0;
}

.page-search-result-breadcrumb li {
  font-size: 13px;
  font-size: 1.3rem;
  display: inline-block;
  margin: 0 4px;
  padding: 0;
  vertical-align: middle;
  font-weight: bold;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .page-search-result-breadcrumb li {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

@media (max-width: tablet) {
  .page-search-result-breadcrumb li {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.page-search-result-breadcrumb li:first-child {
  margin-left: 0;
}

.page-search-result-breadcrumb li:last-child {
  margin-right: 0;
}

.page-search-result-breadcrumb i {
  font-size: 8px;
  font-size: 0.8rem;
  transform: rotate(180deg);
}

.page-search-result-breadcrumb i::before {
  display: inline-block;
  vertical-align: middle;
  line-height: 0;
}

.page-search-result-breadcrumb a:hover {
  color: #006835;
}

.page-search-result-content {
  margin: 0;
  padding: 0;
}

.page-search-result-content * {
  margin: 0;
  padding: 0;
}

.page-search-result-read-more {
  font-size: 16px;
  font-size: 1.6rem;
  color: #ff8300;
  display: inline-block;
  line-height: 26px;
}

.page-search-result-read-more:hover {
  color: #cc6900;
}

.page-search-result-read-more i {
  margin-left: 12px;
  display: inline-block;
  vertical-align: middle;
  line-height: 0;
}

/* ------------------------------------------------------------ */
/*  News card */
/* ------------------------------------------------------------ */
.sala-de-prensa-bg {
  position: relative;
  overflow: hidden;
}

.sala-de-prensa-bg * > {
  z-index: 1;
}

.sala-de-prensa-bg::after {
  background-image: url(../images/bg_pattern.svg);
  background-position: left top;
  background-repeat: no-repeat;
  bottom: 0;
  content: '';
  height: calc(100% - 90px);
  margin: 0;
  opacity: .5;
  padding: 0;
  position: absolute;
  right: 0;
  width: 540px;
  z-index: 0;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .sala-de-prensa-bg::after {
    bottom: -191px;
    right: -207px;
    height: 327px;
    width: 500px;
    background-size: contain;
  }
}

@media (max-width: tablet) {
  .sala-de-prensa-bg::after {
    bottom: -191px;
    right: -207px;
    height: 327px;
    width: 500px;
    background-size: contain;
  }
}

.news-card, .news-card-slider {
  margin-bottom: 32px;
  position: relative;
  z-index: 1;
  border-radius: 2px;
  overflow: hidden;
  height: calc(100% - 32px);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
}

.news-card--no-border {
  padding: 0;
  border-radius: 0;
  box-shadow: 0 0 0 0 transparent;
}

.news-card--no-border .news-card__image, .news-card--no-border .news-card .news-card__image-prensa, .news-card .news-card--no-border .news-card__image-prensa, .news-card--no-border .news-card-slider .news-card__image-prensa, .news-card-slider .news-card--no-border .news-card__image-prensa {
  border-radius: 2px;
  overflow: hidden;
}

.news-card .news-card__image, .news-card-slider .news-card__image, .news-card .news-card__image-prensa, .news-card-slider .news-card__image-prensa {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  margin: 0 16px 0 0;
  overflow: hidden;
  padding: 0;
}

.news-card .news-card__image-prensa, .news-card-slider .news-card__image-prensa {
  margin: 0;
}

.news-card .news-card__image img, .news-card-slider .news-card__image img, .news-card .news-card__image-prensa img, .news-card-slider .news-card__image-prensa img {
  opacity: 0;
}

.news-card .news-card__info, .news-card-slider .news-card__info {
  margin-bottom: 16px;
}

.news-card .news-card__info *, .news-card-slider .news-card__info * {
  margin-bottom: 0;
  padding-bottom: 0;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .news-card .news-card__info *, .news-card-slider .news-card__info * {
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 22px;
  }
}

@media (max-width: tablet) {
  .news-card .news-card__info *, .news-card-slider .news-card__info * {
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 22px;
  }
}

.news-card .news-card__title, .news-card-slider .news-card__title {
  font-weight: bold;
  margin-bottom: 16px;
}

.news-card .news-card__title a:hover, .news-card-slider .news-card__title a:hover {
  color: #006835;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .news-card .news-card__title, .news-card-slider .news-card__title {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: normal;
    margin-bottom: 6px;
  }
  .news-card .news-card__title *, .news-card-slider .news-card__title * {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: normal;
  }
}

@media (max-width: tablet) {
  .news-card .news-card__title, .news-card-slider .news-card__title {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: normal;
    margin-bottom: 6px;
  }
  .news-card .news-card__title *, .news-card-slider .news-card__title * {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: normal;
  }
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .news-card .news-card__title, .news-card-slider .news-card__title {
    font-size: 20px;
    margin-bottom: 16px;
  }
}

.news-card .news-card__read-more, .news-card-slider .news-card__read-more {
  color: #ff8300;
  font-size: 16px;
  font-size: 1.6rem;
}

.news-card .news-card__read-more i, .news-card-slider .news-card__read-more i {
  margin-left: 8px;
  font-size: 14px;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .news-card .news-card__read-more i, .news-card-slider .news-card__read-more i {
    font-size: 10px;
    font-size: 1rem;
  }
}

@media (max-width: tablet) {
  .news-card .news-card__read-more i, .news-card-slider .news-card__read-more i {
    font-size: 10px;
    font-size: 1rem;
  }
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .news-card .news-card__read-more, .news-card-slider .news-card__read-more {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

@media (max-width: tablet) {
  .news-card .news-card__read-more, .news-card-slider .news-card__read-more {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.news-card .news-card__white-bg, .news-card-slider .news-card__white-bg {
  background: white;
  padding: 32px;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .news-card .news-card__white-bg .news-card__info *, .news-card-slider .news-card__white-bg .news-card__info * {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 27px;
  }
}

@media (max-width: tablet) {
  .news-card .news-card__white-bg .news-card__info *, .news-card-slider .news-card__white-bg .news-card__info * {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 27px;
  }
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .news-card .news-card__white-bg .news-card__title, .news-card-slider .news-card__white-bg .news-card__title {
    margin-bottom: 16px;
    font-size: 20px;
    font-size: 2rem;
  }
  .news-card .news-card__white-bg .news-card__title *, .news-card-slider .news-card__white-bg .news-card__title * {
    font-size: 20px;
    font-size: 2rem;
  }
}

@media (max-width: tablet) {
  .news-card .news-card__white-bg .news-card__title, .news-card-slider .news-card__white-bg .news-card__title {
    margin-bottom: 16px;
    font-size: 20px;
    font-size: 2rem;
  }
  .news-card .news-card__white-bg .news-card__title *, .news-card-slider .news-card__white-bg .news-card__title * {
    font-size: 20px;
    font-size: 2rem;
  }
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .news-card .news-card__white-bg .news-card__read-more i, .news-card-slider .news-card__white-bg .news-card__read-more i {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

@media (max-width: tablet) {
  .news-card .news-card__white-bg .news-card__read-more i, .news-card-slider .news-card__white-bg .news-card__read-more i {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .news-card .news-card__white-bg .news-card__read-more, .news-card-slider .news-card__white-bg .news-card__read-more {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

@media (max-width: tablet) {
  .news-card .news-card__white-bg .news-card__read-more, .news-card-slider .news-card__white-bg .news-card__read-more {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .news-card-slider {
    background-color: white;
    padding: 32px;
    width: 400px;
  }
}

@media (max-width: tablet-landscape) {
  .news-card-slider {
    background-color: white;
    padding: 32px;
    width: 400px;
  }
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .news-card-slider {
    width: 320px;
  }
}

@media (max-width: mobile-landscape) {
  .news-card-slider {
    width: 320px;
  }
}

/* ---------------------- */
/*  Promotions */
/* ---------------------- */
.promotions-list {
  overflow: hidden;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .promotions-list {
    margin: 0 !important;
    padding: 0 0 50px 0;
    width: 100%;
  }
  .promotions-list .slick-dots {
    align-items: center;
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    height: 50px;
    justify-content: center;
    left: 0;
    position: absolute;
    width: 100%;
  }
  .promotions-list .slick-dots li {
    margin: 0 8px;
  }
  .promotions-list .slick-dots li button::before {
    color: #6f7271;
    font-size: 16px;
    transition: all .35s ease-in-out;
  }
  .promotions-list .slick-dots li.slick-active button::before {
    color: #6f7271;
  }
}

@media (max-width: mobile-landscape) {
  .promotions-list {
    margin: 0 !important;
    padding: 0 0 50px 0;
    width: 100%;
  }
  .promotions-list .slick-dots {
    align-items: center;
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    height: 50px;
    justify-content: center;
    left: 0;
    position: absolute;
    width: 100%;
  }
  .promotions-list .slick-dots li {
    margin: 0 8px;
  }
  .promotions-list .slick-dots li button::before {
    color: #6f7271;
    font-size: 16px;
    transition: all .35s ease-in-out;
  }
  .promotions-list .slick-dots li.slick-active button::before {
    color: #6f7271;
  }
}

.promotion__card {
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
  margin: 0 0 32px 0;
  overflow: hidden;
  padding: 0;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .promotion__card {
    margin-bottom: 2px;
  }
}

@media (max-width: mobile-landscape) {
  .promotion__card {
    margin-bottom: 2px;
  }
}

.promotion__card a {
  display: block;
  margin: 0;
  padding: 0;
}

.promotion__card img {
  height: auto;
  width: 100%;
}

/* ------------------------------------------------------------ */
/*  Help Form */
/* ------------------------------------------------------------ */
.help-form-container {
  border-top: 1px solid #ececec;
  display: block;
  margin-top: 32px;
  position: relative;
  width: 100%;
}

.help-form-container .text-container {
  padding: 32px 0;
}

.help-form-container .green-form {
  background: #80bc00;
  color: white;
}

.help-form-container .green-form * {
  color: white;
}

.help-form-container .form-container {
  display: inline-block;
  height: calc(100% + 32px);
  margin: -32px 0 0 0;
  max-width: 376px;
  padding: 32px;
  text-align: left;
  width: 100%;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .help-form-container .form-container {
    height: auto;
    margin: 0;
    max-width: 100%;
    padding: 32px 16px;
  }
}

@media (max-width: tablet-landscape) {
  .help-form-container .form-container {
    height: auto;
    margin: 0;
    max-width: 100%;
    padding: 32px 16px;
  }
}

.help-form-container .form-container .form-title {
  margin: 0;
}

.help-form-container .form-container label {
  color: white;
  opacity: .84;
}

.help-form-container .form-container input,
.help-form-container .form-container select {
  font-size: 16px;
  font-size: 1.6rem;
  background: transparent;
  border-radius: 0;
  border: none;
  color: white;
  display: block;
  height: 40px;
  margin: 0;
  outline: none;
  padding: 0;
  text-align: left;
  width: 100%;
  opacity: .84;
}

.help-form-container .form-container input::-webkit-input-placeholder,
.help-form-container .form-container select::-webkit-input-placeholder {
  color: white;
}

.help-form-container .form-container input::-moz-placeholder,
.help-form-container .form-container select::-moz-placeholder {
  color: white;
}

.help-form-container .form-container input:-ms-input-placeholder,
.help-form-container .form-container select:-ms-input-placeholder {
  color: white;
}

.help-form-container .form-container input:-moz-placeholder,
.help-form-container .form-container select:-moz-placeholder {
  color: white;
}

.help-form-container .form-container input:-webkit-autofill,
.help-form-container .form-container select:-webkit-autofill {
  background: transparent !important;
  color: white;
  -webkit-text-fill-color: #80bc00 !important;
  -webkit-box-shadow: 0 0 0 300px #80bc00 inset;
}

.help-form-container .form-container button {
  background: white;
  color: #6f7271;
  margin: 0;
  padding: 0;
  width: 100%;
}

.help-form-container .form-container .form-group {
  padding: 0;
  margin: 0 0 8px 0;
  position: relative;
}

.help-form-container .form-container .form-group::after {
  background: white;
  bottom: 0;
  content: '';
  height: 1px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
}

.help-form-container .form-container .form-group:last-child {
  padding-top: 8px;
  margin-bottom: 0;
}

.help-form-container .form-container .form-control__select {
  padding: 0;
  position: relative;
}

.help-form-container .form-container .form-control__select::after {
  border-bottom: none;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid white;
  content: '';
  display: block;
  height: 8px;
  margin: 0;
  opacity: .84;
  padding: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all .4s ease-in-out;
  width: 10px;
  z-index: 1;
}

.help-form-container .form-container .form-control__select select {
  font-size: 16px;
  font-size: 1.6rem;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  font-weight: normal;
  padding-right: 24px;
  position: relative;
  z-index: 2;
}

.help-form-container .form-container .form-control__select select option {
  background: #80bc00;
  color: white;
  padding: 5px;
}

/* ------------------------------------------------------------ */
/*  Exe card */
/* ------------------------------------------------------------ */
.exe-card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: calc(100% - 40px);
  justify-content: space-between;
  margin: 0 0 40px 0;
  padding: 0;
  position: relative;
  width: 100%;
}

.exe-card .exe-card-image {
  border-radius: 2px;
  display: flex;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
}

.exe-card .exe-card-image img {
  height: auto;
  margin: 0;
  position: relative;
  width: 100%;
}

.exe-card .exe-card-info {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.exe-card .exe-card-title {
  font-size: 20px;
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
  padding: 16px 0;
}

.exe-card .exe-card-content {
  margin: 0 0 8px 0;
  padding: 0;
  overflow: hidden;
  flex-grow: 1;
}

.exe-card .exe-card-content *:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.exe-card .exe-card-more-info {
  font-size: 16px;
  font-size: 1.6rem;
  align-items: center;
  align-self: flex-start;
  color: #ff8300;
  display: inline-flex;
  margin: 0;
  padding: 0;
  transition: all .35s ease-in-out;
  z-index: 2;
}

.exe-card .exe-card-more-info i {
  font-size: 16px;
  font-size: 1.6rem;
  align-items: center;
  color: #ff8300;
  display: flex;
  justify-content: center;
  margin-left: 8px;
  transition: all .35s ease-in-out;
  vertical-align: middle;
}

/* ---------------------- */
/*  Timeline */
/* ---------------------- */
.timeline-carousel {
  padding: 0;
  margin: 40px 0 0 0;
  overflow: hidden;
  width: 100%;
}

.timeline-carousel.slick-dotted.slick-slider {
  margin-bottom: -24px;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .timeline-carousel {
    padding-bottom: 50px;
  }
}

@media (max-width: tablet) {
  .timeline-carousel {
    padding-bottom: 50px;
  }
}

.timeline-carousel .slick-list {
  padding: 0 144px !important;
  position: relative;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .timeline-carousel .slick-list {
    padding: 1.5px 0 0 0 !important;
    width: 100%;
    margin: 0;
  }
}

@media (max-width: mobile-landscape) {
  .timeline-carousel .slick-list {
    padding: 1.5px 0 0 0 !important;
    width: 100%;
    margin: 0;
  }
}

.timeline-carousel .slick-list::before {
  background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 144px;
  z-index: 10;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .timeline-carousel .slick-list::before {
    width: 72px;
    height: 77px;
  }
}

@media (max-width: mobile-landscape) {
  .timeline-carousel .slick-list::before {
    width: 72px;
    height: 77px;
  }
}

.timeline-carousel .slick-list::after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
  content: '';
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 144px;
  z-index: 10;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .timeline-carousel .slick-list::after {
    width: 72px;
    height: 77px;
  }
}

@media (max-width: mobile-landscape) {
  .timeline-carousel .slick-list::after {
    width: 72px;
    height: 77px;
  }
}

.timeline-carousel .slick-arrow,
.timeline-carousel .slick-arrow:hover,
.timeline-carousel .slick-arrow:focus {
  background: #ff8300;
  border-radius: 50%;
  height: 56px;
  opacity: 1;
  overflow: hidden;
  transition: all .35s ease-in-out;
  width: 56px;
  z-index: 11;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
}

.timeline-carousel .slick-prev {
  left: 48px;
}

.timeline-carousel .slick-prev::before {
  content: "\6f";
  font-family: "w-icons";
  font-size: 16px;
}

.timeline-carousel .slick-next {
  right: 48px;
}

.timeline-carousel .slick-next::before {
  content: "\41";
  font-family: "w-icons";
  font-size: 16px;
}

.timeline-carousel .slick-disabled,
.timeline-carousel .slick-disabled:hover,
.timeline-carousel .slick-disabled:focus {
  opacity: 0;
}

.timeline-carousel .slick-dots {
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  min-height: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.timeline-carousel .slick-dots li {
  margin: 0 8px;
}

.timeline-carousel .slick-dots li button::before {
  font-size: 16px;
  color: #6f7271;
  transition: all .35s ease-in-out;
}

.timeline-carousel .slick-dots li.slick-active button::before {
  color: #6f7271;
}

.timeline-carousel .timeline-spot {
  align-items: flex-end;
  display: flex !important;
  height: 588px;
  outline: none;
  position: relative;
  width: 270px;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .timeline-carousel .timeline-spot {
    height: auto;
    width: 100%;
  }
}

@media (max-width: mobile-landscape) {
  .timeline-carousel .timeline-spot {
    height: auto;
    width: 100%;
  }
}

.timeline-carousel .timeline-spot:nth-child(even) {
  align-items: flex-end;
}

.timeline-carousel .timeline-spot:nth-child(even) .timeline-spot-container {
  position: relative;
}

.timeline-carousel .timeline-spot:nth-child(even) .timeline-spot-container::before {
  content: '';
  height: 3px;
  background-color: #DADBDB;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  transform: translateY(-50%);
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .timeline-carousel .timeline-spot:nth-child(even) .timeline-spot-container::before {
    display: block;
    transform: translateY(0);
  }
}

@media (max-width: mobile-landscape) {
  .timeline-carousel .timeline-spot:nth-child(even) .timeline-spot-container::before {
    display: block;
    transform: translateY(0);
  }
}

.timeline-carousel .timeline-spot:nth-child(even) .timeline-spot-time {
  padding-top: 46px;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .timeline-carousel .timeline-spot:nth-child(even) .timeline-spot-time {
    padding-top: 46px;
    padding-bottom: 24px;
  }
}

@media (max-width: mobile-landscape) {
  .timeline-carousel .timeline-spot:nth-child(even) .timeline-spot-time {
    padding-top: 46px;
    padding-bottom: 24px;
  }
}

.timeline-carousel .timeline-spot:nth-child(even) .timeline-spot-time::before {
  top: 0;
}

.timeline-carousel .timeline-spot:nth-child(even) .timeline-spot-content {
  bottom: 0;
}

.timeline-carousel .timeline-spot:nth-child(odd) {
  align-items: flex-start;
  width: 500px;
}

.timeline-carousel .timeline-spot:nth-child(odd) .timeline-spot-container {
  position: relative;
}

.timeline-carousel .timeline-spot:nth-child(odd) .timeline-spot-container::before {
  content: '';
  height: 3px;
  background-color: #DADBDB;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  transform: translateY(50%);
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .timeline-carousel .timeline-spot:nth-child(odd) .timeline-spot-container::before {
    bottom: calc(100% - 3px);
    transform: translateY(0);
  }
}

@media (max-width: mobile-landscape) {
  .timeline-carousel .timeline-spot:nth-child(odd) .timeline-spot-container::before {
    bottom: calc(100% - 3px);
    transform: translateY(0);
  }
}

.timeline-carousel .timeline-spot:nth-child(odd) .timeline-spot-time {
  padding-bottom: 46px;
  align-self: flex-end;
  text-align: center;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .timeline-carousel .timeline-spot:nth-child(odd) .timeline-spot-time {
    padding-top: 46px;
    padding-bottom: 24px;
  }
}

@media (max-width: mobile-landscape) {
  .timeline-carousel .timeline-spot:nth-child(odd) .timeline-spot-time {
    padding-top: 46px;
    padding-bottom: 24px;
  }
}

.timeline-carousel .timeline-spot:nth-child(odd) .timeline-spot-time::before {
  bottom: 0;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .timeline-carousel .timeline-spot:nth-child(odd) .timeline-spot-time::before {
    bottom: calc(100% - 32px);
  }
}

@media (max-width: mobile-landscape) {
  .timeline-carousel .timeline-spot:nth-child(odd) .timeline-spot-time::before {
    bottom: calc(100% - 32px);
  }
}

.timeline-carousel .timeline-spot:nth-child(odd) .timeline-spot-content {
  top: 0;
}

.timeline-carousel .timeline-spot:first-child {
  position: relative;
}

.timeline-carousel .timeline-spot:first-child::before {
  background-color: #DADBDB;
  border-radius: 1.5px;
  content: '';
  height: 17px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .timeline-carousel .timeline-spot:first-child::before {
    display: none;
  }
}

@media (max-width: mobile-landscape) {
  .timeline-carousel .timeline-spot:first-child::before {
    display: none;
  }
}

.timeline-carousel .timeline-spot:first-child .timeline-spot-container::before {
  border-radius: 1.5px 0 0 1.5px;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .timeline-carousel .timeline-spot:first-child .timeline-spot-container::before {
    left: 50%;
    top: 0;
    width: 50%;
    transform: translateY(0) translateX(0);
  }
}

@media (max-width: mobile-landscape) {
  .timeline-carousel .timeline-spot:first-child .timeline-spot-container::before {
    left: 50%;
    top: 0;
    width: 50%;
    transform: translateY(0) translateX(0);
  }
}

.timeline-carousel .timeline-spot:last-child::before {
  background-color: #DADBDB;
  border-radius: 1.5px;
  content: '';
  height: 17px;
  right: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .timeline-carousel .timeline-spot:last-child::before {
    display: none;
  }
}

@media (max-width: mobile-landscape) {
  .timeline-carousel .timeline-spot:last-child::before {
    display: none;
  }
}

.timeline-carousel .timeline-spot:last-child .timeline-spot-container::before {
  border-radius: 0 1.5px 1.5px 0;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .timeline-carousel .timeline-spot:last-child .timeline-spot-container::before {
    right: 50%;
    top: 0;
    width: 50%;
    transform: translateY(0) translateX(0);
  }
}

@media (max-width: mobile-landscape) {
  .timeline-carousel .timeline-spot:last-child .timeline-spot-container::before {
    right: 50%;
    top: 0;
    width: 50%;
    transform: translateY(0) translateX(0);
  }
}

.timeline-carousel .timeline-spot-container {
  display: flex;
  height: 50%;
  position: relative;
  width: 100%;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .timeline-carousel .timeline-spot-container {
    display: block;
    height: 100%;
  }
}

@media (max-width: mobile-landscape) {
  .timeline-carousel .timeline-spot-container {
    display: block;
    height: 100%;
  }
}

.timeline-carousel .timeline-spot-time {
  font-size: 25px;
  font-size: 2.5rem;
  align-self: flex-start;
  font-weight: 500;
  position: relative;
  text-align: center;
  width: 100%;
}

.timeline-carousel .timeline-spot-time:before {
  background-color: #DADBDB;
  border-radius: 1.5px;
  content: '';
  height: 32px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 3px;
}

.timeline-carousel .timeline-spot-content {
  align-items: center;
  background-color: #f7f7f7;
  border-radius: 2px;
  display: flex;
  height: 194px;
  justify-content: center;
  left: 50%;
  overflow: hidden;
  position: absolute;
  transform: translateX(-50%);
  width: 562px;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .timeline-carousel .timeline-spot-content {
    display: block;
    height: auto;
    left: 0;
    margin: 0 24px;
    position: relative;
    transform: translateX(0);
    width: calc(100% - 48px);
  }
}

@media (max-width: mobile-landscape) {
  .timeline-carousel .timeline-spot-content {
    display: block;
    height: auto;
    left: 0;
    margin: 0 24px;
    position: relative;
    transform: translateX(0);
    width: calc(100% - 48px);
  }
}

.timeline-carousel .timeline-spot-content .timeline-spot-image {
  background-size: cover;
  height: 100%;
  width: 226px;
}

.timeline-carousel .timeline-spot-content .timeline-spot-image img {
  height: auto;
  margin: 0;
  padding: 0;
  width: 100%;
  opacity: 0;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .timeline-carousel .timeline-spot-content .timeline-spot-image {
    width: 100%;
  }
}

@media (max-width: mobile-landscape) {
  .timeline-carousel .timeline-spot-content .timeline-spot-image {
    width: 100%;
  }
}

.timeline-carousel .timeline-spot-content .timeline-spot-info {
  height: 100%;
  max-height: 194px;
  overflow: hidden;
  padding: 16px 24px;
  width: 336px;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .timeline-carousel .timeline-spot-content .timeline-spot-info {
    width: 100%;
    max-height: 1000px;
    padding: 16px;
  }
}

@media (max-width: mobile-landscape) {
  .timeline-carousel .timeline-spot-content .timeline-spot-info {
    width: 100%;
    max-height: 1000px;
    padding: 16px;
  }
}

.timeline-carousel .timeline-spot-content .timeline-spot-info * {
  margin-bottom: 0;
  padding-bottom: 0;
}

/* ------------------------------------------------------------ */
/*  Gallery Slider */
/* ------------------------------------------------------------ */
.gallery-slider-container {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 0;
}

.gallery-slider-container .gallery-slider-content {
  display: block;
  margin: 0 0 16px 0;
  padding: 0;
  position: relative;
  width: 100%;
}

.gallery-slider-container .gallery-slider-counter {
  font-size: 14px;
  font-size: 1.4rem;
  background: rgba(55, 56, 56, 0.82);
  border-radius: 2px;
  bottom: 8px;
  color: white;
  left: 8px;
  line-height: 40px;
  min-width: 140px;
  position: absolute;
  text-align: center;
  z-index: 2;
}

.gallery-slider-container .gallery-slider {
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}

.gallery-slider-container .gallery-slider li {
  display: block;
  margin: 0;
  padding: 0;
}

.gallery-slider-container .gallery-slider img {
  height: auto;
  margin: 0;
  padding: 0;
  width: 100%;
}

.gallery-slider-container .gallery-slider .slick-arrow {
  width: 80px;
  height: 80px;
  z-index: 10;
}

.gallery-slider-container .gallery-slider .slick-prev {
  left: 0;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .gallery-slider-container .gallery-slider .slick-prev {
    display: none !important;
  }
}

@media (max-width: tablet-landscape) {
  .gallery-slider-container .gallery-slider .slick-prev {
    display: none !important;
  }
}

.gallery-slider-container .gallery-slider .slick-prev::before {
  content: "\6f";
  font-family: "w-icons";
  font-size: 40px;
}

.gallery-slider-container .gallery-slider .slick-next {
  right: 0;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .gallery-slider-container .gallery-slider .slick-next {
    display: none !important;
  }
}

@media (max-width: tablet-landscape) {
  .gallery-slider-container .gallery-slider .slick-next {
    display: none !important;
  }
}

.gallery-slider-container .gallery-slider .slick-next::before {
  content: "\41";
  font-family: "w-icons";
  font-size: 40px;
}

.gallery-slider-container .gallery-slider-thumbs {
  margin: 0;
  padding: 0;
  width: 100%;
}

.gallery-slider-container .gallery-slider-thumbs .slick-slide {
  margin: 0;
  outline: none;
  padding: 0 8px;
  position: relative;
}

.gallery-slider-container .gallery-slider-thumbs .slick-slide::before {
  background: #ffd100;
  bottom: 0;
  content: '';
  height: 3px;
  left: 8px;
  opacity: 0;
  position: absolute;
  transition: all .35s ease-in-out;
  width: calc(100% - 16px);
}

.gallery-slider-container .gallery-slider-thumbs .slick-slide img {
  height: auto;
  margin: 0;
  padding: 0;
  width: 100%;
  cursor: pointer;
}

.gallery-slider-container .gallery-slider-thumbs .slick-slide.slick-current::before {
  opacity: 1;
}

/* ---------------------- */
/*  Values Cards */
/* ---------------------- */
.value-card {
  align-items: center;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  margin: 0 0 30px 0;
  padding: 10px 10px 25px 10px;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .value-card {
    margin-bottom: 16px;
  }
}

@media (max-width: mobile-landscape) {
  .value-card {
    margin-bottom: 16px;
  }
}

.value-card:hover .value-card-image-normal {
  opacity: 0;
}

.value-card:hover .value-card-image-hover {
  opacity: 1;
}

.value-card .value-card-image {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.value-card .value-card-image-normal {
  width: 100%;
  position: relative;
  overflow: hidden;
  transition: all .35s ease-in-out;
  text-align: center;
}

.value-card .value-card-image-hover {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all .35s ease-in-out;
  text-align: center;
}

/* ------------------------------------------------------------ */
/*  Cards Overlay */
/* ------------------------------------------------------------ */
.card-overlay {
  display: block;
  margin: 0;
  padding: 2px;
  position: relative;
  width: 100%;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .card-overlay {
    margin: 0 0 16px 0;
    padding: 0;
  }
}

@media (max-width: mobile-landscape) {
  .card-overlay {
    margin: 0 0 16px 0;
    padding: 0;
  }
}

.card-overlay:hover .card-overlay-image::before {
  opacity: 0;
}

.card-overlay:hover .card-overlay-image::after {
  opacity: 1;
}

.card-overlay:hover .card-overlay-title i {
  color: white;
}

.card-overlay .card-overlay-image {
  border-radius: 2px;
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
}

.card-overlay .card-overlay-image::before {
  background-color: rgba(47, 47, 47, 0.54);
  content: '';
  display: block;
  height: 100%;
  left: 0;
  margin: 0;
  opacity: 1;
  padding: 0;
  position: absolute;
  top: 0;
  transition: all .35s ease-in-out;
  width: 100%;
  z-index: 2;
}

.card-overlay .card-overlay-image::after {
  background: linear-gradient(180deg, rgba(51, 51, 51, 0) 0%, rgba(55, 56, 56, 0.86) 100%);
  content: '';
  display: block;
  height: 100%;
  left: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 0;
  transition: all .35s ease-in-out;
  width: 100%;
  z-index: 2;
}

.card-overlay .card-overlay-image img {
  height: auto;
  margin: 0;
  position: relative;
  width: 100%;
  z-index: 1;
}

.card-overlay .card-overlay-title {
  font-size: 25px;
  font-size: 2.5rem;
  bottom: 0;
  color: white;
  left: 0;
  line-height: 30px;
  margin: 0;
  padding: 24px 16px;
  position: absolute;
  transition: all .35s ease-in-out;
  width: 100%;
  z-index: 2;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .card-overlay .card-overlay-title {
    font-size: 20px;
    font-size: 2rem;
    padding: 16px;
  }
}

@media (max-width: tablet-landscape) {
  .card-overlay .card-overlay-title {
    font-size: 20px;
    font-size: 2rem;
    padding: 16px;
  }
}

.card-overlay .card-overlay-title i {
  font-size: 16px;
  font-size: 1.6rem;
  color: #ff8300;
  transition: all .35s ease-in-out;
  vertical-align: middle;
}

.main-content-block {
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.main-content-block .panel-grid-cell,
.main-content-block .so-panel {
  height: 100%;
  padding: 0 !important;
}

.text-container {
  margin: 0 auto;
  max-width: 820px;
  padding: 220px 0 100px 0;
  position: relative;
  z-index: 3;
}

@media (max-width: popular) {
  .text-container {
    max-width: 680px;
    padding-bottom: 50px;
    padding-top: 110px;
  }
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .text-container {
    max-width: 100%;
    padding-left: 70px;
    padding-right: 70px;
  }
}

@media (max-width: tablet) {
  .text-container {
    max-width: 100%;
    padding-left: 70px;
    padding-right: 70px;
  }
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .text-container {
    padding-left: 36px;
    padding-right: 36px;
  }
}

@media (max-width: mobile-landscape) {
  .text-container {
    padding-left: 36px;
    padding-right: 36px;
  }
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .text-container br {
    display: none;
  }
}

.text-container--equal-space {
  padding-bottom: 220px;
  padding-top: 220px;
}

@media (max-width: popular) {
  .text-container--equal-space {
    padding-bottom: 110px;
    padding-top: 110px;
  }
}

.text-container--half-space {
  padding-bottom: 110px;
  padding-top: 110px;
}

@media (max-width: popular) {
  .text-container--half-space {
    padding-bottom: 50px;
    padding-top: 50px;
  }
}

.secondary-text-container {
  margin: 0 auto;
  max-width: 900px;
  padding-bottom: 130px;
  padding-top: 130px;
  position: relative;
  z-index: 3;
}

@media (max-width: popular) {
  .secondary-text-container {
    max-width: 680px;
    padding-bottom: 100px;
    padding-top: 100px;
  }
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .secondary-text-container br {
    display: none;
  }
}

.secondary-text-container * {
  font-size: 24px;
  font-size: 2.4rem;
  font-family: "HelveticaNeue", Helvetica, sans-serif;
  letter-spacing: 2px;
  margin: 0;
  padding: 0;
}

@media (max-width: popular) {
  .secondary-text-container * {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.secondary-text-container > .so-widget-sow-editor {
  margin: 0;
  padding: 50px 0;
}

@media (max-width: popular) {
  .secondary-text-container > .so-widget-sow-editor {
    padding: 30px 0;
  }
}

.secondary-text-container--double-space {
  padding-bottom: 400px;
  padding-top: 400px;
}

@media (max-width: popular) {
  .secondary-text-container--double-space {
    padding-bottom: 300px;
    padding-top: 300px;
  }
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .secondary-text-container--double-space {
    padding-bottom: 150px;
    padding-top: 150px;
  }
}

@media (max-width: mobile-landscape) {
  .secondary-text-container--double-space {
    padding-bottom: 150px;
    padding-top: 150px;
  }
}

.secondary-text-container--half-space {
  padding-bottom: 70px;
  padding-top: 70px;
}

@media (max-width: popular) {
  .secondary-text-container--half-space {
    padding-bottom: 30px;
    padding-top: 30px;
  }
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .secondary-text br {
    display: none;
  }
}

.secondary-text * {
  font-size: 24px;
  font-size: 2.4rem;
  font-family: "HelveticaNeue", Helvetica, sans-serif;
  letter-spacing: 2px;
  margin: 0 0 20px 0;
  padding: 0;
  text-transform: uppercase;
}

@media (max-width: popular) {
  .secondary-text * {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.secondary-font-family * {
  font-family: "HelveticaNeue", Helvetica, sans-serif;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .mobile-text-center * {
    text-align: center !important;
  }
}

@media (max-width: tablet) {
  .mobile-text-center * {
    text-align: center !important;
  }
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .mobile-text-center br {
    display: none;
  }
}

.no-margin-bottom * {
  margin-bottom: 0;
}

.image-less-top {
  margin-top: -24%;
  z-index: 0;
}

@media (max-width: popular) {
  .image-less-top {
    margin-top: -20%;
  }
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .image-less-top {
    margin-top: -30%;
  }
  .image-less-top .sow-image-container {
    min-width: 220%;
  }
}

@media (max-width: tablet) {
  .image-less-top {
    margin-top: -30%;
  }
  .image-less-top .sow-image-container {
    min-width: 220%;
  }
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .image-min-width {
    overflow: hidden;
  }
  .image-min-width .sow-image-container {
    left: 50%;
    min-width: 150%;
    position: relative;
    transform: translate(-50%, 0);
  }
}

@media (max-width: tablet) {
  .image-min-width {
    overflow: hidden;
  }
  .image-min-width .sow-image-container {
    left: 50%;
    min-width: 150%;
    position: relative;
    transform: translate(-50%, 0);
  }
}

.image-max-width {
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .image-max-width {
    max-width: 90%;
  }
}

/* ------------------------------------------------------------ */
/*  Card detail list */
/* ------------------------------------------------------------ */
.card-detail-list-container {
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .card-detail-list-container {
    margin-bottom: 12px;
  }
}

@media (max-width: tablet-landscape) {
  .card-detail-list-container {
    margin-bottom: 12px;
  }
}

.card-detail-list-container::after {
  background: linear-gradient(180deg, rgba(168, 171, 170, 0) 0%, rgba(152, 155, 154, 0.25) 26.51%, rgba(138, 141, 140, 0.25) 50.07%, rgba(127, 130, 129, 0.25) 100%);
  content: '';
  height: calc(100% - 1px);
  left: 50%;
  opacity: 1;
  position: absolute;
  bottom: 1px;
  width: 1px;
  z-index: 1;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .card-detail-list-container::after {
    opacity: 0;
  }
}

@media (max-width: tablet-landscape) {
  .card-detail-list-container::after {
    opacity: 0;
  }
}

.card-detail-list {
  position: relative;
  z-index: 2;
}

.card-detail-list .card-detail-block {
  margin: 0 0 1px 0;
  padding: 0;
  position: relative;
  z-index: 2;
}

.card-detail-list .card-detail-block::after {
  background: linear-gradient(270deg, rgba(168, 171, 170, 0) 0%, rgba(161, 164, 163, 0.25) 20.23%, rgba(139, 142, 141, 0.25) 52.37%, rgba(120, 123, 122, 0.25) 77.58%, rgba(111, 114, 113, 0) 100%);
  content: '';
  bottom: 0;
  height: 1px;
  left: 0;
  opacity: 1;
  position: absolute;
  width: 100%;
  z-index: 1;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .card-detail-list .card-detail-block::after {
    opacity: 0;
  }
}

@media (max-width: tablet-landscape) {
  .card-detail-list .card-detail-block::after {
    opacity: 0;
  }
}

.card-detail-list .card-detail-info {
  margin: 0;
  min-height: 200px;
  position: relative;
  z-index: 2;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .card-detail-list .card-detail-info {
    padding: 32px 0;
    min-height: 1px;
    margin-bottom: 1px;
  }
}

@media (max-width: tablet-landscape) {
  .card-detail-list .card-detail-info {
    padding: 32px 0;
    min-height: 1px;
    margin-bottom: 1px;
  }
}

.card-detail-list .card-detail-info::after {
  background: linear-gradient(270deg, rgba(168, 171, 170, 0) 0%, rgba(161, 164, 163, 0.25) 20.23%, rgba(139, 142, 141, 0.25) 52.37%, rgba(120, 123, 122, 0.25) 77.58%, rgba(111, 114, 113, 0) 100%);
  content: '';
  bottom: 0;
  height: 1px;
  left: -15px;
  opacity: 0;
  position: absolute;
  width: calc(100% + 30px);
  z-index: 1;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .card-detail-list .card-detail-info::after {
    opacity: 1;
  }
}

@media (max-width: tablet-landscape) {
  .card-detail-list .card-detail-info::after {
    opacity: 1;
  }
}

.card-detail-list .card-detail-info p {
  margin: 0;
  padding: 10px 0 0 0;
}

.card-detail-list .card-detail-info p:first-child {
  padding-top: 0;
}

/* ------------------------------------------------------------ */
/*  Card elite detail list */
/* ------------------------------------------------------------ */
.card-elite-detail-list-container {
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .card-elite-detail-list-container {
    margin-bottom: 12px;
  }
}

@media (max-width: tablet-landscape) {
  .card-elite-detail-list-container {
    margin-bottom: 12px;
  }
}

.card-elite-detail-list-container::after {
  background: linear-gradient(180deg, rgba(168, 171, 170, 0) 0%, rgba(152, 155, 154, 0.25) 26.51%, rgba(138, 141, 140, 0.25) 50.2%, rgba(127, 130, 129, 0.25) 70.15%, rgba(111, 114, 113, 0) 100%);
  content: '';
  height: 100%;
  left: 50%;
  opacity: 1;
  position: absolute;
  bottom: 1px;
  width: 1px;
  z-index: 1;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .card-elite-detail-list-container::after {
    opacity: 0;
  }
}

@media (max-width: tablet-landscape) {
  .card-elite-detail-list-container::after {
    opacity: 0;
  }
}

.card-elite-detail-list {
  position: relative;
  z-index: 2;
}

.card-elite-detail-list .card-detail-block {
  margin: 0 0 1px 0;
  padding: 0;
  position: relative;
  z-index: 2;
}

.card-elite-detail-list .card-detail-block::after {
  background: linear-gradient(270deg, rgba(168, 171, 170, 0) 0%, rgba(161, 164, 163, 0.25) 20.23%, rgba(139, 142, 141, 0.25) 52.37%, rgba(120, 123, 122, 0.25) 77.58%, rgba(111, 114, 113, 0) 100%);
  content: '';
  top: 0;
  height: 1px;
  left: 0;
  opacity: 1;
  position: absolute;
  width: 100%;
  z-index: 1;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .card-elite-detail-list .card-detail-block::after {
    opacity: 0;
  }
}

@media (max-width: tablet-landscape) {
  .card-elite-detail-list .card-detail-block::after {
    opacity: 0;
  }
}

.card-elite-detail-list .card-detail-info {
  margin: 0;
  padding-top: 80px;
  padding-bottom: 80px;
  min-height: 334px;
  position: relative;
  z-index: 2;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .card-elite-detail-list .card-detail-info {
    padding: 40px 0;
    min-height: 1px;
    margin-bottom: 1px;
  }
}

@media (max-width: tablet-landscape) {
  .card-elite-detail-list .card-detail-info {
    padding: 40px 0;
    min-height: 1px;
    margin-bottom: 1px;
  }
}

.card-elite-detail-list .card-detail-info::after {
  background: rgba(111, 114, 113, 0.25);
  content: '';
  top: 0;
  height: 1px;
  left: -15px;
  opacity: 0;
  position: absolute;
  width: calc(100% + 30px);
  z-index: 1;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .card-elite-detail-list .card-detail-info::after {
    opacity: 1;
  }
}

@media (max-width: tablet-landscape) {
  .card-elite-detail-list .card-detail-info::after {
    opacity: 1;
  }
}

.card-elite-detail-list .card-detail-info img {
  width: 100%;
  max-width: 130px;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .card-elite-detail-list .card-detail-info img {
    max-width: 96px;
  }
}

@media (max-width: mobile-landscape) {
  .card-elite-detail-list .card-detail-info img {
    max-width: 96px;
  }
}

.card-elite-detail-list .card-detail-info h5 {
  font-size: 20px;
  font-size: 2rem;
  margin: 0;
}

.card-elite-detail-list .card-detail-info p {
  margin: 0;
  padding: 16px 0 0 0;
}

.card-elite-detail-list .card-detail-info p:first-child {
  padding-top: 24px;
}

/* ------------------------------------------------------------ */
/*  Card elite benefit list */
/* ------------------------------------------------------------ */
.card-elite-benefits-list-container h3 {
  margin-bottom: 40px;
}

.card-elite-benefits-list-container .card-elite-benefit {
  background: #242525;
  border-radius: 2px;
  height: calc(100% - 32px);
  margin: 0 0 32px 0;
  padding: 24px;
}

.card-elite-benefits-list-container .card-elite-benefit h5 {
  font-size: 20px;
  font-size: 2rem;
}

.card-elite-benefits-list-container .card-elite-benefit p {
  margin: 0;
  padding: 0;
}

/* ------------------------------------------------------------ */
/*  Tarjeta teaser list */
/* ------------------------------------------------------------ */
.tarjeta-teaser-list p {
  margin-bottom: 16px;
}

.tarjeta-teaser-list ul {
  margin: 0 0 16px 18px;
  padding: 0;
}

.tarjeta-teaser-list ul li {
  line-height: 1.69;
  margin: 0;
  padding: 0 0 8px 0;
}

.tarjeta-teaser-list:first-child {
  padding-top: 40px;
}

.tarjeta-teaser-list:first-child .tarjetas-teaser:first-child {
  padding-top: 0;
}

.tarjeta-teaser-list:last-child {
  padding-bottom: 40px;
}

.tarjeta-teaser-list:last-child .tarjetas-teaser:last-child {
  padding-bottom: 0;
}

.tarjeta-teaser-list:last-child .tarjetas-teaser:last-child::after {
  display: none;
}

.tarjeta-teaser-list .tarjetas-teaser {
  margin: 0;
  padding: 80px 0;
  position: relative;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .tarjeta-teaser-list .tarjetas-teaser {
    padding: 40px 0;
  }
}

@media (max-width: tablet-landscape) {
  .tarjeta-teaser-list .tarjetas-teaser {
    padding: 40px 0;
  }
}

.tarjeta-teaser-list .tarjetas-teaser::after {
  background-color: #e8e8e8;
  bottom: -0.5px;
  content: '';
  height: 1px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
}

.tarjeta-teaser-list .tarjetas-teaser-title {
  font-size: 25px;
  font-size: 2.5rem;
  line-height: 30px;
  margin-bottom: 8px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .tarjeta-teaser-list .tarjetas-teaser-title {
    font-size: 25px;
    font-size: 2.5rem;
    margin-bottom: 16px;
  }
}

@media (max-width: tablet-landscape) {
  .tarjeta-teaser-list .tarjetas-teaser-title {
    font-size: 25px;
    font-size: 2.5rem;
    margin-bottom: 16px;
  }
}

.tarjeta-teaser-list .tarjetas-teaser-buttons {
  font-size: 0;
}

.tarjeta-teaser-list .tarjetas-teaser-buttons .btn {
  margin-right: 16px;
  min-width: 154px;
}

.tarjeta-teaser-list .tarjetas-teaser-buttons .btn:last-child {
  margin-right: 0;
}

@media (max-width: 359px) {
  .tarjeta-teaser-list .tarjetas-teaser-buttons .btn {
    min-width: 136px;
  }
}

/* ------------------------------------------------------------ */
/*  Social commitment */
/* ------------------------------------------------------------ */
.social-commitment .social-commitment-title {
  margin: 0 0 32px 0;
  font-size: 32px;
  font-size: 3.2rem;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .social-commitment .social-commitment-title {
    font-size: 32px;
    font-size: 3.2rem;
    margin-bottom: 24px;
  }
}

@media (max-width: tablet-landscape) {
  .social-commitment .social-commitment-title {
    font-size: 32px;
    font-size: 3.2rem;
    margin-bottom: 24px;
  }
}

.contact-form {
  background-color: white;
  border-radius: 2px;
  padding: 24px;
  overflow: hidden;
}

@media (max-width: 350px) {
  .contact-form {
    padding: 24px 20px;
  }
}

.contact-form-footer {
  background-color: rgba(0, 0, 0, 0.12);
  padding: 70px 14px 24px 14px;
}

.contact-form-footer .widget-title {
  color: #4a4d4d;
  display: block;
  text-align: center;
}

@media (max-width: small) {
  .contact-form-footer .widget-title {
    font-size: 30px;
    font-size: 3rem;
    line-height: 34px;
    font-weight: 600;
  }
}

.contact-form-footer .contact-form {
  margin-left: auto;
  margin-right: auto;
  max-width: 630px;
  width: 100%;
}

@media (max-width: 350px) {
  .contact-form-modal .contact-form {
    padding: 24px 10px;
  }
}

.contact-form-modal .modal-title {
  padding-left: 22px;
  padding-right: 22px;
}

@media (max-width: 350px) {
  .contact-form-modal .modal-title {
    padding-left: 10px;
  }
}

.contact-form-modal .modal-dialog {
  border: 0;
  box-shadow: none;
  max-width: 665px;
  margin: 0 auto;
}

.contact-form-modal .close {
  font-size: 24px;
  font-size: 2.4rem;
  opacity: 0.2;
  padding: 10px 15px;
  position: absolute;
  right: 7px;
  top: 7px;
}

.contact-form-modal .modal-content {
  border: 0;
}

@media (max-width: 350px) {
  .contact-form-modal .modal-content {
    border-radius: 0;
    margin: 0;
  }
}

.contact-form-modal .modal-body {
  padding: 20px 12px;
}

@media (max-width: 412px) {
  .contact-form-modal .modal-body {
    padding: 20px 0px;
  }
}

.near-list {
  margin: 0;
  padding: 0;
  font-size: 0;
}

.near-list li {
  border-radius: 2px;
  display: inline-block;
  margin: 0 15px;
  min-width: 250px;
  overflow: hidden;
  padding: 0;
  position: relative;
  vertical-align: middle;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .near-list li {
    display: block;
    margin: 0 0 16px 0;
  }
  .near-list li:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: mobile-landscape) {
  .near-list li {
    display: block;
    margin: 0 0 16px 0;
  }
  .near-list li:last-child {
    margin-bottom: 0;
  }
}

.near-list a {
  font-size: 20px;
  font-size: 2rem;
  border: 2px solid #006835;
  color: #6f7271;
  display: block;
  font-weight: bold;
  margin: 0;
  padding: 0;
  text-align: left;
  transition: all .25s ease-in-out;
}

.near-list a:hover {
  background: #006835;
  color: white;
}

.near-list span {
  display: inline-block;
  margin: 0;
  padding: 11px 8px;
  vertical-align: middle;
}

.near-list .near-list__number {
  font-size: 25px;
  font-size: 2.5rem;
  background: #006835;
  color: white;
  min-width: 80px;
  text-align: center;
  transition: all .25s ease-in-out;
}

.two-grids-section {
  margin: 30px auto;
  max-width: 1200px;
  padding: 0;
  position: relative;
  width: 100%;
}

@media (max-width: medium) {
  .two-grids-section {
    height: auto !important;
  }
}

.two-grids-section .panel-grid-cell {
  min-height: 560px;
  margin-bottom: 0 !important;
  padding: 0px !important;
  transition: all 700ms ease-in-out;
}

@media (max-width: 1170px) {
  .two-grids-section .panel-grid-cell {
    min-height: 100%;
  }
}

@media (max-width: medium) {
  .two-grids-section .panel-grid-cell {
    float: none !important;
    min-height: auto;
    width: 100% !important;
  }
}

.two-grids-section .panel-grid-cell .so-panel,
.two-grids-section .panel-grid-cell .panel-widget-style {
  height: 100%;
}

.two-grids-section .panel-grid-cell .panel-widget-style {
  background-position: center center;
  background-size: cover;
}

.two-grids-section .panel-grid-cell .so-widget-image {
  width: 100%;
}

@media (max-width: medium-up) {
  .two-grids-section .panel-grid-cell .so-widget-image {
    display: none !important;
  }
}

.two-grids-section .panel-grid-cell:last-child {
  background-color: #EDEDED;
  padding: 60px !important;
  position: relative;
  z-index: 2;
}

@media (max-width: small) {
  .two-grids-section .panel-grid-cell:last-child {
    padding: 40px !important;
  }
}

@media (max-width: smaller) {
  .two-grids-section .panel-grid-cell:last-child {
    padding: 10px 20px 20px !important;
  }
  .two-grids-section .panel-grid-cell:last-child h2 {
    margin: 15px 0;
  }
}

.two-grids-section .panel-grid-cell:first-child {
  height: auto;
}

@media (max-width: medium-up) {
  .two-grids-section .panel-grid-cell:first-child {
    height: 100%;
    position: absolute;
    right: 0;
    z-index: 1;
  }
}

.two-grids-section.info-to-right .panel-grid-cell:last-child {
  background: none;
  height: 100%;
  padding: 0 !important;
  left: 0;
  right: auto;
  z-index: 1;
}

@media (max-width: medium-up) {
  .two-grids-section.info-to-right .panel-grid-cell:last-child {
    position: absolute;
  }
}

.two-grids-section.info-to-right .panel-grid-cell:first-child {
  background-color: #EDEDED;
  float: right !important;
  height: auto;
  padding: 60px !important;
  position: relative;
  z-index: 2;
}

@media (max-width: small) {
  .two-grids-section.info-to-right .panel-grid-cell:first-child {
    padding: 40px !important;
  }
}

@media (max-width: smaller) {
  .two-grids-section.info-to-right .panel-grid-cell:first-child {
    padding: 10px 20px 20px !important;
  }
  .two-grids-section.info-to-right .panel-grid-cell:first-child h2 {
    margin: 15px 0;
  }
}

.infobox-two-colums {
  align-items: center;
  display: flex;
  margin: 0;
  padding: 0;
  vertical-align: center;
  flex-wrap: wrap;
}

.infobox-two-colums .infobox-two-colums-content-left {
  display: flex;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  width: calc(50% + 15px);
  order: 1;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .infobox-two-colums .infobox-two-colums-content-left {
    order: 2;
    width: 100%;
  }
}

@media (max-width: tablet-landscape) {
  .infobox-two-colums .infobox-two-colums-content-left {
    order: 2;
    width: 100%;
  }
}

.infobox-two-colums .infobox-two-colums-content-left .infobox-two-colums-info {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 570px;
  padding: 16px 80px 16px 16px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .infobox-two-colums .infobox-two-colums-content-left .infobox-two-colums-info {
    max-width: 100%;
    padding: 24px 16px 0 16px;
  }
}

@media (max-width: tablet-landscape) {
  .infobox-two-colums .infobox-two-colums-content-left .infobox-two-colums-info {
    max-width: 100%;
    padding: 24px 16px 0 16px;
  }
}

.infobox-two-colums .infobox-two-colums-content-right {
  align-items: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  margin: 0;
  min-height: 412px;
  padding: 1% 6% 1% 9%;
  position: relative;
  width: calc(50% - 15px);
  order: 2;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .infobox-two-colums .infobox-two-colums-content-right {
    margin: 0 15px;
    min-height: 258px;
    order: 1;
    width: calc(100% - 30px);
    padding: 6.4% 0.66%;
  }
}

@media (max-width: tablet-landscape) {
  .infobox-two-colums .infobox-two-colums-content-right {
    margin: 0 15px;
    min-height: 258px;
    order: 1;
    width: calc(100% - 30px);
    padding: 6.4% 0.66%;
  }
}

.infobox-two-colums .infobox-two-colums-content-right::after {
  background-color: rgba(55, 56, 56, 0.75);
  content: '';
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.infobox-two-colums .infobox-two-colums-content-right .infobox-two-colums-info {
  position: relative;
  z-index: 2;
}

.infobox-two-colums .infobox-two-colums-content-right .infobox-two-colums-info.text-italic-light {
  font-size: 25px;
  font-size: 2.5rem;
  line-height: 38px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .infobox-two-colums .infobox-two-colums-content-right .infobox-two-colums-info.text-italic-light {
    font-size: 20px;
    font-size: 2rem;
    line-height: 30px;
  }
}

@media (max-width: tablet-landscape) {
  .infobox-two-colums .infobox-two-colums-content-right .infobox-two-colums-info.text-italic-light {
    font-size: 20px;
    font-size: 2rem;
    line-height: 30px;
  }
}

.infobox-two-colums .infobox-two-colums-content-right .infobox-two-colums-info.text-italic-light * {
  font-size: 25px;
  font-size: 2.5rem;
  line-height: 38px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .infobox-two-colums .infobox-two-colums-content-right .infobox-two-colums-info.text-italic-light * {
    font-size: 20px;
    font-size: 2rem;
    line-height: 30px;
  }
}

@media (max-width: tablet-landscape) {
  .infobox-two-colums .infobox-two-colums-content-right .infobox-two-colums-info.text-italic-light * {
    font-size: 20px;
    font-size: 2rem;
    line-height: 30px;
  }
}

.social-links {
  margin: 0 0 5px 0;
  padding: 0;
}

.social-links li {
  display: inline-block;
  margin: 0 7px;
  vertical-align: middle;
}

.social-links a {
  background-repeat: no-repeat;
  background-position: center center;
  color: white;
  display: block;
  height: 32px;
  overflow: hidden;
  text-indent: 50px;
  transition: all 160ms ease-in-out;
  width: 24px;
}

.social-links a:hover, .social-links a:focus, .social-links a:active {
  transform: scale(1.1);
}

.social-links .phone {
  padding: 0 50px 0 0;
  position: relative;
}

.social-links .phone::after {
  background-color: rgba(255, 255, 255, 0.24);
  content: " ";
  display: block;
  height: 20px;
  position: absolute;
  right: 15px;
  top: 6px;
  width: 1px;
}

.social-links .phone a {
  line-height: 30px;
  width: auto;
}

.social-links .phone a::before {
  background-image: url("assets/images/phone-icon.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 20px 20px;
  content: " ";
  display: inline-block;
  height: 20px;
  margin: 0 10px 3px 0;
  vertical-align: middle;
  width: 20px;
}

.social-links .facebook a {
  background-image: url("assets/images/facebook-icon.svg");
}

.social-links .twitter a {
  background-image: url("assets/images/twitter-icon.svg");
}

.social-links .instagram a {
  background-image: url("assets/images/instagram-icon.svg");
}

.social-links .linkedin a {
  background-image: url("assets/images/linkedin-icon.svg");
}

.footer-links .social-links li {
  display: inline-block;
}

.footer-links .social-links a {
  background-size: 30px auto;
  height: 32px;
  width: 32px;
}

.footer-links .social-links .facebook {
  margin-left: 0;
}

.footer-links .social-links .facebook a {
  background-image: url("assets/images/facebook-icon-blue.svg");
}

.footer-links .social-links .facebook a:hover, .footer-links .social-links .facebook a:focus, .footer-links .social-links .facebook a:active {
  background-image: url("assets/images/facebook-icon.svg");
  transform: scale(1.1);
}

.footer-links .social-links .twitter a {
  background-image: url("assets/images/twitter-icon-blue.svg");
}

.footer-links .social-links .twitter a:hover, .footer-links .social-links .twitter a:focus, .footer-links .social-links .twitter a:active {
  background-image: url("assets/images/twitter-icon.svg");
  transform: scale(1.1);
}

.footer-links .social-links .instagram a {
  background-image: url("assets/images/instagram-icon-blue.svg");
}

.footer-links .social-links .instagram a:hover, .footer-links .social-links .instagram a:focus, .footer-links .social-links .instagram a:active {
  background-image: url("assets/images/instagram-icon.svg");
  transform: scale(1.1);
}

.footer-links .social-links .phone {
  display: none;
}

.block-list {
  margin: 0 0 128px 0;
  padding: 0;
}

.block-list .panel-grid-cell {
  width: 50% !important;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .block-list .panel-grid-cell {
    width: 100% !important;
  }
}

@media (max-width: tablet) {
  .block-list .panel-grid-cell {
    width: 100% !important;
  }
}

.block-list .panel-grid-cell:first-child {
  margin-right: 50px;
  z-index: 1;
}

@media (max-width: popular) {
  .block-list .panel-grid-cell:first-child {
    margin-right: 25px;
  }
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .block-list .panel-grid-cell:first-child {
    width: 58.26% !important;
  }
}

@media (max-width: tablet-landscape) {
  .block-list .panel-grid-cell:first-child {
    width: 58.26% !important;
  }
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .block-list .panel-grid-cell:first-child {
    margin: 0 0 -50px 0 !important;
    width: 100% !important;
  }
}

@media (max-width: tablet) {
  .block-list .panel-grid-cell:first-child {
    margin: 0 0 -50px 0 !important;
    width: 100% !important;
  }
}

.block-list .panel-grid-cell:last-child {
  margin-left: 50px;
  z-index: 2;
}

@media (max-width: popular) {
  .block-list .panel-grid-cell:last-child {
    margin-left: 25px;
  }
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .block-list .panel-grid-cell:last-child {
    width: 41.74% !important;
  }
}

@media (max-width: tablet-landscape) {
  .block-list .panel-grid-cell:last-child {
    width: 41.74% !important;
  }
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .block-list .panel-grid-cell:last-child {
    background: #6f7271 !important;
    margin: 0;
    padding: 50px !important;
    width: calc(100% - 140px) !important;
  }
}

@media (max-width: tablet) {
  .block-list .panel-grid-cell:last-child {
    background: #6f7271 !important;
    margin: 0;
    padding: 50px !important;
    width: calc(100% - 140px) !important;
  }
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .block-list .panel-grid-cell:last-child {
    padding: 30px !important;
    width: calc(100% - 72px) !important;
  }
}

@media (max-width: mobile-landscape) {
  .block-list .panel-grid-cell:last-child {
    padding: 30px !important;
    width: calc(100% - 72px) !important;
  }
}

.block-list--last {
  margin-bottom: 0;
}

.block-list > .panel-grid-cell:nth-child(1) {
  order: 1;
}

.block-list > .panel-grid-cell:nth-child(2) {
  order: 2;
}

.block-list--left > .panel-grid-cell:nth-child(1) {
  order: 2;
  margin-left: 50px;
  margin-right: 0;
}

@media (max-width: popular) {
  .block-list--left > .panel-grid-cell:nth-child(1) {
    margin-left: 25px;
  }
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .block-list--left > .panel-grid-cell:nth-child(1) {
    margin: 0 0 -50px 0 !important;
    order: 1;
  }
}

@media (max-width: tablet) {
  .block-list--left > .panel-grid-cell:nth-child(1) {
    margin: 0 0 -50px 0 !important;
    order: 1;
  }
}

.block-list--left > .panel-grid-cell:nth-child(2) {
  order: 1;
  margin-right: 50px;
  margin-left: 0;
}

@media (max-width: popular) {
  .block-list--left > .panel-grid-cell:nth-child(2) {
    margin-right: 25px;
  }
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .block-list--left > .panel-grid-cell:nth-child(2) {
    margin: 0;
    order: 2;
  }
}

@media (max-width: tablet) {
  .block-list--left > .panel-grid-cell:nth-child(2) {
    margin: 0;
    order: 2;
  }
}

.block-list--left > .panel-grid-cell:nth-child(2) .block-list__text {
  padding-left: 150px;
}

@media (max-width: popular) {
  .block-list--left > .panel-grid-cell:nth-child(2) .block-list__text {
    padding-left: 80px;
  }
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .block-list--left > .panel-grid-cell:nth-child(2) .block-list__text {
    padding: 0;
  }
}

@media (max-width: tablet) {
  .block-list--left > .panel-grid-cell:nth-child(2) .block-list__text {
    padding: 0;
  }
}

.block-list .wow {
  opacity: 0;
  transform: translate(0, 40px);
  transition: all .5s ease-in-out;
}

.block-list .wow.animated {
  opacity: 1;
  transform: translate(0, 0);
}

.block-list .wow.animated .block-list__text ul {
  opacity: 1;
}

.block-list .widget-title {
  font-size: 85px;
}

@media (max-width: popular) {
  .block-list .widget-title {
    font-size: 55px;
  }
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .block-list .widget-title {
    font-size: 45px;
  }
}

.block-list .block-list__text ul {
  margin: 0;
  opacity: 0;
  padding: 0;
  transition: all .5s ease-in-out;
  visibility: hidden;
}

.block-list .block-list__text ul li {
  margin: 0;
}

.block-list .block-list__text ul li:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .block-list .block-list__text ul li {
    margin-bottom: 15px;
  }
  .block-list .block-list__text ul li:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: tablet) {
  .block-list .block-list__text ul li {
    margin-bottom: 15px;
  }
  .block-list .block-list__text ul li:last-child {
    margin-bottom: 0;
  }
}

.block-list .block-list__text ul:hover > * a {
  color: #404040;
}

.block-list .block-list__text ul > * a {
  font-size: 24px;
  font-size: 2.4rem;
  color: white;
  font-weight: bold;
  line-height: 44px;
  text-transform: uppercase;
  transition: ease-in-out .25s color;
  visibility: visible;
  letter-spacing: 2px;
}

@media (max-width: popular) {
  .block-list .block-list__text ul > * a {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 26px;
  }
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .block-list .block-list__text ul > * a {
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 20px;
  }
}

@media (max-width: tablet) {
  .block-list .block-list__text ul > * a {
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 20px;
  }
}

.block-list .block-list__text ul > *:hover a {
  color: white;
}

.block-list .block-list__text ul > *:hover a:hover {
  color: #ffd100;
}

.product-poll {
  position: relative;
  overflow: hidden;
}

.product-poll::after {
  background-image: url(../images/bg_pattern.svg);
  background-position: left -32px;
  background-repeat: no-repeat;
  top: 0;
  content: '';
  height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  width: 524px;
  z-index: 0;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .product-poll::after {
    background-position: 288px 62px;
    background-size: contain;
    top: 0;
    right: 0;
    width: 500px;
  }
}

@media (max-width: mobile-landscape) {
  .product-poll::after {
    background-position: 288px 62px;
    background-size: contain;
    top: 0;
    right: 0;
    width: 500px;
  }
}

.poll {
  display: block;
  margin: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  transition: opacity .45s ease-in-out .1s, height .1s ease-in-out .5s;
  width: 100%;
  z-index: 1;
}

.poll--active {
  height: auto;
  opacity: 1;
  transition: opacity .45s ease-in-out .2s,  height .1s ease-in-out .1s;
}

.poll h3 {
  font-size: 32px;
  font-size: 3.2rem;
  margin-bottom: 38px;
  color: #373838;
  font-weight: 500;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .poll h3 {
    font-size: 20px;
    font-size: 2rem;
    margin-bottom: 32px;
  }
}

@media (max-width: tablet) {
  .poll h3 {
    font-size: 20px;
    font-size: 2rem;
    margin-bottom: 32px;
  }
}

.poll h4 {
  font-size: 25px;
  font-size: 2.5rem;
  margin-bottom: 32px;
  color: #373838;
  font-weight: normal;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .poll h4 {
    font-size: 16px;
    font-size: 1.6rem;
    margin-bottom: 24px;
  }
}

@media (max-width: tablet) {
  .poll h4 {
    font-size: 16px;
    font-size: 1.6rem;
    margin-bottom: 24px;
  }
}

.poll .btn {
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
  margin: 0 8px;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .poll .btn {
    margin-bottom: 16px;
  }
}

@media (max-width: mobile-landscape) {
  .poll .btn {
    margin-bottom: 16px;
  }
}

.poll .poll__product-cart {
  margin: 0 0 16px 0;
  padding: 0;
  position: relative;
}

.poll .poll__product-cart .poll__product-image {
  margin: 0;
  padding: 0;
  position: relative;
}

.poll .poll__product-cart .poll__product-image::before {
  background: linear-gradient(180deg, rgba(51, 51, 51, 0) 0%, rgba(55, 56, 56, 0.86) 100%);
  content: '';
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.poll .poll__product-cart .poll__product-image img {
  height: auto;
  width: 100%;
  z-index: 2;
}

.poll .poll__product-cart .poll__product-title {
  font-size: 20px;
  font-size: 2rem;
  bottom: 0;
  color: white;
  left: 0;
  line-height: 25px;
  margin: 0;
  padding: 24px 22px;
  position: absolute;
  text-align: left;
  width: 100%;
}

/* ------------------------------------------------------------ */
/*  Two Columns Product List */
/* ------------------------------------------------------------ */
.two-columns-product-list .product-card-list-title {
  margin: 0 0 24px 0;
}

.two-columns-product-list .product-card-list {
  align-items: stretch;
  border-radius: 2px;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

.two-columns-product-list .product-card-list .left-column {
  margin: 0;
  padding: 0;
  width: 57%;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .two-columns-product-list .product-card-list .left-column {
    width: 100%;
  }
}

@media (max-width: tablet-landscape) {
  .two-columns-product-list .product-card-list .left-column {
    width: 100%;
  }
}

.two-columns-product-list .product-card-list .right-column {
  background: #f7f7f7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  padding: 24px;
  width: 43%;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .two-columns-product-list .product-card-list .right-column {
    background: transparent;
    padding: 0;
    width: 100%;
  }
}

@media (max-width: tablet-landscape) {
  .two-columns-product-list .product-card-list .right-column {
    background: transparent;
    padding: 0;
    width: 100%;
  }
}

.product-card-main .product-card-image, .product-card .product-card-image {
  border-radius: 2px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 104px;
}

.product-card-main .product-card-image img, .product-card .product-card-image img {
  height: auto;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  opacity: 0;
}

.product-card-main .product-card-image .product-card-image-hover, .product-card .product-card-image .product-card-image-hover {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  display: none;
}

.product-card-main .product-card-image::before, .product-card .product-card-image::before {
  background: rgba(0, 104, 53, 0.8);
  content: '';
  height: 100%;
  left: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 0;
  transition: .35s all ease-in-out;
  width: 100%;
  z-index: 3;
}

.product-card-main .product-card-image::after, .product-card .product-card-image::after {
  background: linear-gradient(180deg, rgba(51, 51, 51, 0.25) 0%, rgba(55, 56, 56, 0.86) 100%);
  content: '';
  height: 100%;
  left: 0;
  margin: 0;
  opacity: 1;
  padding: 0;
  position: absolute;
  top: 0;
  transition: .35s all ease-in-out;
  width: 100%;
  z-index: 2;
}

.product-card-main {
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .product-card-main {
    margin-bottom: 16px;
  }
  .product-card-main::before {
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;
  }
}

@media (max-width: tablet-landscape) {
  .product-card-main {
    margin-bottom: 16px;
  }
  .product-card-main::before {
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;
  }
}

.product-card-main .product-card-link {
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 2;
}

.product-card-main .product-card-image {
  border-radius: 2px 0 0 2px;
}

.product-card-main .product-card-content {
  bottom: 0;
  left: 0;
  margin: 0;
  overflow: hidden;
  padding: 32px;
  position: absolute;
  width: 100%;
  z-index: 2;
}

@media screen and (min-width: 0px) and (max-width: 1140px) {
  .product-card-main .product-card-content {
    padding: 24px;
  }
}

@media (max-width: desktop-minimun) {
  .product-card-main .product-card-content {
    padding: 24px;
  }
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .product-card-main .product-card-content {
    padding: 16px;
  }
}

@media (max-width: tablet-landscape) {
  .product-card-main .product-card-content {
    padding: 16px;
  }
}

.product-card-main .product-card-content .product-title {
  font-size: 32px;
  font-size: 3.2rem;
  color: white;
  margin: 0 0 8px 0;
  padding: 0;
  text-align: left;
}

.product-card-main .product-card-content p {
  margin: 0 0 16px 0;
  color: white;
}

.product-card-main .product-card-content .product-more-info {
  font-size: 13px;
  font-size: 1.3rem;
}

.product-card {
  border-radius: 2px;
  cursor: pointer;
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .product-card {
    margin-bottom: 16px;
  }
}

@media (max-width: tablet-landscape) {
  .product-card {
    margin-bottom: 16px;
  }
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .product-card:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: tablet-landscape) {
  .product-card:last-child {
    margin-bottom: 0;
  }
}

.product-card:hover .product-card-image::before {
  opacity: 1;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .product-card:hover .product-card-image::before {
    opacity: 0;
  }
}

@media (max-width: tablet-landscape) {
  .product-card:hover .product-card-image::before {
    opacity: 0;
  }
}

.product-card:hover .product-card-image::after {
  opacity: 0;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .product-card:hover .product-card-image::after {
    opacity: 1;
  }
}

@media (max-width: tablet-landscape) {
  .product-card:hover .product-card-image::after {
    opacity: 1;
  }
}

.product-card::before {
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
}

.product-card .product-card-link {
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 2;
}

.product-card .product-card-image {
  padding-top: 30%;
  transition: all .5s ease;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .product-card .product-card-image {
    min-height: 140px;
    padding-top: 0;
  }
}

@media (max-width: tablet-landscape) {
  .product-card .product-card-image {
    min-height: 140px;
    padding-top: 0;
  }
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .product-card .product-card-image {
    min-height: 104px;
    padding-top: 0;
  }
}

.product-card .product-card-image img {
  display: none;
}

.product-card .product-card-content {
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  justify-content: center;
  left: 0;
  margin: 0;
  overflow: hidden;
  padding: 16px 32px;
  position: absolute;
  width: 100%;
  z-index: 2;
}

@media screen and (min-width: 0px) and (max-width: 1140px) {
  .product-card .product-card-content {
    padding: 16px 24px;
  }
}

@media (max-width: desktop-minimun) {
  .product-card .product-card-content {
    padding: 16px 24px;
  }
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .product-card .product-card-content {
    flex-wrap: nowrap;
    padding: 16px;
  }
}

@media (max-width: tablet-landscape) {
  .product-card .product-card-content {
    flex-wrap: nowrap;
    padding: 16px;
  }
}

.product-card .product-card-content .product-title {
  font-size: 20px;
  font-size: 2rem;
  color: white;
  display: block;
  line-height: normal;
  margin: 0;
  padding: 0;
  text-align: left;
  transition: all .5s ease;
}

@media screen and (min-width: 0px) and (max-width: 1140px) {
  .product-card .product-card-content .product-title {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

@media (max-width: desktop-minimun) {
  .product-card .product-card-content .product-title {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .product-card .product-card-content .product-title {
    font-size: 20px;
    font-size: 2rem;
  }
}

@media (max-width: tablet-landscape) {
  .product-card .product-card-content .product-title {
    font-size: 20px;
    font-size: 2rem;
  }
}

.product-card .product-card-content .product-info,
.product-card .product-card-content .product-more-info {
  max-height: 0;
  overflow: hidden;
}

.product-card .product-card-content .product-info {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all .5s ease;
}

.product-card .product-card-content .product-info p {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.63;
  color: white;
  margin: 0;
}

.product-card--active::before {
  z-index: 2;
}

.product-card--active .product-card-link {
  z-index: 3;
}

.product-card--active .product-card-image {
  padding-top: 75%;
}

.product-card--active .product-card-content {
  justify-content: flex-end;
}

.product-card--active .product-card-content .product-title {
  margin-bottom: 8px;
  transition: all .35s ease;
}

.product-card--active .product-card-content .product-info {
  max-height: 600px;
  opacity: 1;
  transition: all .35s ease;
}

/* ------------------------------------------------------------ */
/*  state card */
/* ------------------------------------------------------------ */
.state-card-list {
  display: flex;
  position: relative;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
}

.state-card {
  background: #f7f7f7;
  border-radius: 2px;
  margin: 0 8px 16px 8px;
  overflow: hidden;
  width: calc(20% - 16px);
}

.state-card .btn {
  border-radius: 0 0 2px 2px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .state-card {
    width: calc(33.33% - 16px);
  }
}

@media (max-width: tablet-landscape) {
  .state-card {
    width: calc(33.33% - 16px);
  }
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .state-card {
    width: calc(50% - 16px);
  }
}

@media (max-width: mobile-landscape) {
  .state-card {
    width: calc(50% - 16px);
  }
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .state-card {
    width: calc(100% - 16px);
  }
}

.state-card .state-card__title {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
  margin: 0;
  padding: 20px 10px;
}

/* ------------------------------------------------------------ */
/*  members card */
/* ------------------------------------------------------------ */
.members-card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: calc(100% - 32px);
  justify-content: space-between;
  margin: 0 0 32px 0;
  padding: 0;
  position: relative;
  width: 100%;
}

.members-card .members-card__image {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
}

.members-card .members-card__image img {
  height: auto;
  margin: 0;
  padding: 0;
  width: 100%;
}

.members-card .members-card__info {
  align-items: flex-start;
  background: #f7f7f7;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0;
  padding: 16px;
}

.members-card .members-card__name {
  font-size: 20px;
  font-size: 2rem;
  margin: 0;
  padding: 0;
}

.members-card .members-card__charge {
  font-size: 16px;
  font-size: 1.6rem;
  display: block;
  line-height: 27px;
  margin: 0 0 8px 0;
}

.members-card .members-card__read-more {
  font-size: 16px;
  font-size: 1.6rem;
  color: #ff8300;
  outline: none;
}

.members-card .members-card__read-more i {
  margin-left: 8px;
  font-size: 12px;
  font-size: 1.2rem;
}

.modal-member .modal-header {
  margin: 0;
  padding: 0;
  position: relative;
  flex-wrap: wrap;
}

.modal-member .modal-body {
  margin: 0;
  padding: 0;
}

.modal-member .members-modal__image {
  margin: 0;
  padding: 0;
  width: 100%;
}

.modal-member .members-modal__image img {
  height: auto;
  margin: 0;
  padding: 0;
  width: 100%;
}

.modal-member .members-modal__info {
  margin: 0;
  padding: 24px;
  width: 100%;
  background: #fdfdfd;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .modal-member .members-modal__info {
    padding: 16px;
  }
}

@media (max-width: tablet) {
  .modal-member .members-modal__info {
    padding: 16px;
  }
}

.modal-member .member-name {
  margin: 0 0 8px 0;
  padding: 0;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .modal-member .member-name {
    font-size: 20px;
    font-size: 2rem;
  }
}

@media (max-width: tablet) {
  .modal-member .member-name {
    font-size: 20px;
    font-size: 2rem;
  }
}

.modal-member .member-position {
  font-size: 25px;
  font-size: 2.5rem;
  display: block;
  margin: 0;
  padding: 0 0 17px 0;
  position: relative;
  width: 100%;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .modal-member .member-position {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

@media (max-width: tablet) {
  .modal-member .member-position {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.modal-member .member-position::after {
  background: #ffd100;
  bottom: 0;
  content: '';
  height: 2px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 16px;
}

.modal-member .members-modal--wrapper {
  height: 340px;
  overflow: hidden;
  padding: 0 24px;
  transition: all .35s ease-in-out;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .modal-member .members-modal--wrapper {
    padding: 0 16px;
  }
}

@media (max-width: tablet) {
  .modal-member .members-modal--wrapper {
    padding: 0 16px;
  }
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .modal-member .members-modal--wrapper {
    height: 300px;
  }
}

.modal-member .members-modal--wrapper.scrolling {
  box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.16);
}

.modal-member .members-modal--wrapper .baron__scroller {
  width: 100% !important;
  min-width: 100% !important;
}

.modal-member .members-modal--wrapper .baron__track {
  border-radius: 1px;
  bottom: 0;
  height: calc(100% - 20px);
  right: 10px;
  top: 10px;
  width: 2px;
  overflow: hidden;
}

.file-card {
  border-radius: 2px;
  display: block;
  margin: 0 0 40px 0;
  overflow: hidden;
  padding: 0;
  position: relative;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .file-card {
    margin-bottom: 16px;
  }
}

@media (max-width: mobile-landscape) {
  .file-card {
    margin-bottom: 16px;
  }
}

.file-card .file-card__image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 136% 0 0 0;
  position: relative;
  width: 100%;
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .file-card .file-card__image {
    padding: 154% 0 0 0;
  }
}

.file-card .file-card__image::before {
  background: linear-gradient(180deg, rgba(51, 51, 51, 0) 0%, rgba(55, 56, 56, 0.86) 100%);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}

.file-card .file-card__image img {
  height: auto;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  opacity: 0;
}

.file-card .file-card__info {
  bottom: 0;
  left: 0;
  position: absolute;
  text-align: center;
  transform: translateY(42px);
  transition: all .3s ease-in-out;
  width: 100%;
  z-index: 2;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .file-card .file-card__info {
    transform: translateY(0);
  }
}

@media (max-width: tablet) {
  .file-card .file-card__info {
    transform: translateY(0);
  }
}

.file-card:hover .file-card__info {
  transform: translateY(0);
}

.file-card .file-card__title {
  font-size: 18px;
  font-size: 1.8rem;
  color: white;
  margin: 0;
  padding: 16px;
  text-align: center;
  border-bottom: 1px solid white;
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .file-card .file-card__title {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.file-card .btn {
  border-radius: 0 0 2px 2px;
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .file-card .btn {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

/* ------------------------------------------------------------ */
/*  Cuentas teaser list */
/* ------------------------------------------------------------ */
.cuentas-teaser-list ul {
  margin: 0 0 16px 18px;
  padding: 0;
}

.cuentas-teaser-list ul li {
  line-height: 1.69;
  margin: 0;
  padding: 0 0 8px 0;
}

.cuentas-teaser-list:first-child {
  padding-top: 40px;
}

.cuentas-teaser-list:first-child .cuentas-teaser:first-child {
  padding-top: 0;
}

.cuentas-teaser-list:last-child {
  padding-bottom: 40px;
}

.cuentas-teaser-list:last-child .cuentas-teaser:last-child {
  padding-bottom: 0;
}

.cuentas-teaser-list:last-child .cuentas-teaser:last-child::after {
  display: none;
}

.cuentas-teaser-list .cuentas-teaser {
  margin: 0;
  padding: 48px 0;
  position: relative;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .cuentas-teaser-list .cuentas-teaser {
    padding: 40px 0;
  }
}

@media (max-width: tablet-landscape) {
  .cuentas-teaser-list .cuentas-teaser {
    padding: 40px 0;
  }
}

.cuentas-teaser-list .cuentas-teaser::after {
  background-color: #e8e8e8;
  bottom: -0.5px;
  content: '';
  height: 1px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
}

.cuentas-teaser-list .cuentas-teaser-title {
  font-size: 25px;
  font-size: 2.5rem;
  line-height: 30px;
  margin-bottom: 16px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .cuentas-teaser-list .cuentas-teaser-title {
    font-size: 25px;
    font-size: 2.5rem;
    margin-bottom: 16px;
  }
}

@media (max-width: tablet-landscape) {
  .cuentas-teaser-list .cuentas-teaser-title {
    font-size: 25px;
    font-size: 2.5rem;
    margin-bottom: 16px;
  }
}

.cuentas-teaser-list .cuentas-teaser-buttons {
  font-size: 0;
}

.cuentas-teaser-list .cuentas-teaser-buttons .btn {
  margin-right: 16px;
  min-width: 154px;
}

.cuentas-teaser-list .cuentas-teaser-buttons .btn:last-child {
  margin-right: 0;
}

@media (max-width: 359px) {
  .cuentas-teaser-list .cuentas-teaser-buttons .btn {
    min-width: 136px;
  }
}

/* ------------------------------------------------------------ */
/*  Cuentas detail */
/* ------------------------------------------------------------ */
.cuentas-detail h3 {
  margin-bottom: 24px;
  font-weight: 500;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .cuentas-detail h3 {
    font-size: 25px;
    font-size: 2.5rem;
    margin-bottom: 16px;
  }
}

@media (max-width: tablet) {
  .cuentas-detail h3 {
    font-size: 25px;
    font-size: 2.5rem;
    margin-bottom: 16px;
  }
}

.cuentas-detail h4 {
  margin-bottom: 24px;
}

.cuentas-detail .cuentas-detail-description {
  position: relative;
}

.cuentas-detail .cuentas-detail-description p {
  font-size: 20px;
  font-size: 2rem;
  line-height: 30px;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .cuentas-detail .cuentas-detail-description p {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 26px;
    margin-bottom: 24px;
  }
}

@media (max-width: tablet) {
  .cuentas-detail .cuentas-detail-description p {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 26px;
    margin-bottom: 24px;
  }
}

.cuentas-detail .cuentas-detail-description-bg {
  background-color: transparent;
  background-position: -32px center;
  background-repeat: no-repeat;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: .08;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .cuentas-detail .cuentas-detail-description-bg {
    display: none;
  }
}

@media (max-width: tablet-landscape) {
  .cuentas-detail .cuentas-detail-description-bg {
    display: none;
  }
}

.cuentas-detail .cuentas-detail-benefits {
  position: relative;
  overflow: hidden;
}

.cuentas-detail .cuentas-detail-benefits::after {
  background-image: url(../images/bg_pattern.svg);
  background-position: left bottom;
  background-repeat: no-repeat;
  top: 0;
  content: '';
  height: 464px;
  margin: 0;
  opacity: .3;
  padding: 0;
  position: absolute;
  right: 0;
  width: 420px;
  z-index: 0;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .cuentas-detail .cuentas-detail-benefits::after {
    width: 30%;
    height: 100%;
    background-position: left -164px;
  }
}

@media (max-width: tablet) {
  .cuentas-detail .cuentas-detail-benefits::after {
    width: 30%;
    height: 100%;
    background-position: left -164px;
  }
}

.cuentas-detail .cuentas-detail-benefits h3 {
  margin-bottom: 16px;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .cuentas-detail .cuentas-detail-benefits h3 {
    text-align: center;
  }
}

@media (max-width: tablet) {
  .cuentas-detail .cuentas-detail-benefits h3 {
    text-align: center;
  }
}

.cuentas-detail .cuentas-detail-benefits ul {
  display: block;
  margin: 0;
  padding: 0 0 0 48px;
}

.cuentas-detail .cuentas-detail-benefits ul li {
  font-size: 16px;
  font-size: 1.6rem;
  display: block;
  margin: 0;
  padding: 28px 0 20px 0;
  position: relative;
  line-height: 1.6;
  letter-spacing: 0.1px;
  border-bottom: 1px solid rgba(111, 114, 113, 0.25);
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .cuentas-detail .cuentas-detail-benefits ul li {
    padding: 18px 0 14px 0;
  }
}

@media (max-width: tablet) {
  .cuentas-detail .cuentas-detail-benefits ul li {
    padding: 18px 0 14px 0;
  }
}

.cuentas-detail .cuentas-detail-benefits ul li:last-child {
  border: none;
}

.cuentas-detail .cuentas-detail-benefits ul li::before {
  font-size: 14px;
  font-size: 1.4rem;
  align-items: center;
  background: #6f7271;
  border-radius: 50%;
  color: white;
  content: "\45";
  display: flex;
  font-family: "w-icons";
  height: 32px;
  justify-content: center;
  left: -48px;
  margin: 0;
  overflow: hidden;
  padding: 2px 0 0 0;
  position: absolute;
  text-align: center;
  top: 24px;
  width: 32px;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .cuentas-detail .cuentas-detail-benefits ul li::before {
    top: 16px;
  }
}

@media (max-width: tablet) {
  .cuentas-detail .cuentas-detail-benefits ul li::before {
    top: 16px;
  }
}

.slider__navigation img {
  padding-right: 1.17%;
}

/* ------------------------------------------------------------ */
/*  Pagination */
/* ------------------------------------------------------------ */
.pagination .page-item {
  align-items: center;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  margin-left: 8px;
  min-height: 40px;
  min-width: 40px;
}

.pagination .page-item .page-link {
  border-color: #006835;
  border-radius: 2px;
  color: #006835;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  height: 100%;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  width: 100%;
  border: 1px solid #bfd9cc;
}

.pagination .page-item.active .page-link {
  background-color: #6f7271 !important;
  border: 1px solid #6f7271;
  color: #ffffff;
}

.pagination .next .page-link {
  background-color: #004d27 !important;
  border: 1px solid #004d27;
  color: #ffffff;
  font-family: "HelveticaNeue", Helvetica, sans-serif;
  font-size: 12px;
  font-weight: bold;
  padding-left: 16px;
  width: auto;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .pagination .next .page-link {
    padding: 0.5rem 0.75rem;
    width: 100%;
  }
}

@media (max-width: tablet-landscape) {
  .pagination .next .page-link {
    padding: 0.5rem 0.75rem;
    width: 100%;
  }
}

.pagination .next .page-link span {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.pagination .next .page-link i {
  align-items: center;
  font-size: 16px;
  margin-left: 5px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .pagination .next .page-link i {
    margin: 0;
  }
}

@media (max-width: tablet-landscape) {
  .pagination .next .page-link i {
    margin: 0;
  }
}

.news-full-header .news-full-title {
  margin-top: 32px;
  margin-bottom: 16px;
  font-size: 40px;
  font-weight: bold;
  line-height: 49px;
  color: #6f7271;
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .news-full-header .news-full-title {
    font-weight: 500;
    line-height: 39px;
    font-size: 32px;
    margin-bottom: 24px;
  }
}

.news-full-header .news-full-date {
  margin-bottom: 0;
  color: rgba(111, 114, 113, 0.62);
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
}

/* ------------------------------------------------------------ */
/*  Layout */
/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/*  Layout */
/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/*  Header */
/* ------------------------------------------------------------ */
.site__header-top {
  height: 104px;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .site__header-top {
    height: auto;
  }
}

@media (max-width: tablet-landscape) {
  .site__header-top {
    height: auto;
  }
}

.site__mobile-menu-button {
  background: transparent;
  border: none;
  display: none;
  height: 40px;
  margin: 0;
  outline: none;
  padding: 0;
  width: 40px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .site__mobile-menu-button {
    display: inline-block;
  }
}

@media (max-width: tablet-landscape) {
  .site__mobile-menu-button {
    display: inline-block;
  }
}

.site__branding {
  margin: 0;
  padding: 0;
  position: relative;
  width: 241px;
  height: 40px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .site__branding {
    height: 30px;
    max-width: 186px;
  }
}

@media (max-width: tablet-landscape) {
  .site__branding {
    height: 30px;
    max-width: 186px;
  }
}

.site__branding .site__title {
  display: none;
}

.site__branding .site__title-link {
  display: block;
  margin: 0;
  padding: 0;
}

.site__branding img {
  display: block;
  height: auto;
  margin: 0;
  padding: 0;
  width: 100%;
}

.site__online-bank {
  margin-left: 12px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .site__online-bank {
    font-size: 13px;
    font-size: 1.3rem;
    border-radius: 0;
    height: 48px;
    line-height: 48px;
    margin: 0;
    width: 100%;
  }
  .site__online-bank i {
    line-height: 48px;
  }
}

@media (max-width: tablet-landscape) {
  .site__online-bank {
    font-size: 13px;
    font-size: 1.3rem;
    border-radius: 0;
    height: 48px;
    line-height: 48px;
    margin: 0;
    width: 100%;
  }
  .site__online-bank i {
    line-height: 48px;
  }
}

/* ------------------------------------------------------------ */
/*  Top Navigation */
/* ------------------------------------------------------------ */
.site__top-menu-wrapper {
  margin: 0;
  padding: 0;
}

.site__top-menu-wrapper::after {
  background: rgba(111, 114, 113, 0.2);
  content: '';
  display: inline-block;
  height: 16px;
  margin: 0 12px;
  padding: 0;
  vertical-align: middle;
  width: 1px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .site__top-menu-wrapper {
    display: none;
  }
}

@media (max-width: tablet-landscape) {
  .site__top-menu-wrapper {
    display: none;
  }
}

.site__top-menu {
  display: inline-block;
  font-size: 0;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

.site__top-menu li {
  display: inline-block;
  margin: 0 12px;
  padding: 0;
  vertical-align: middle;
}

.site__top-menu a {
  font-size: 18px;
  font-size: 1.8rem;
  color: #6f7271;
  display: block;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
  padding: 30px 0;
  transition: all .25s ease-in-out;
}

.site__top-menu a:hover {
  color: #006835;
}

@media screen and (min-width: 0px) and (max-width: 1140px) {
  .site__top-menu a {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

@media (max-width: desktop-minimun) {
  .site__top-menu a {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.site__top-menu .submenu-parent .sub-menu {
  margin-top: -12px;
}

/* ------------------------------------------------------------ */
/*  Site search */
/* ------------------------------------------------------------ */
.site__search {
  margin: 0 12px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .site__search {
    margin: 0;
  }
}

@media (max-width: tablet-landscape) {
  .site__search {
    margin: 0;
  }
}

.site__search .site__search-button {
  font-size: 18px;
  font-size: 1.8rem;
  background: transparent;
  border: none;
  color: #6f7271;
  display: inline-block;
  height: 80px;
  line-height: 80px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 24px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .site__search .site__search-button {
    width: 40px;
  }
}

@media (max-width: tablet-landscape) {
  .site__search .site__search-button {
    width: 40px;
  }
}

.site__search .site__search-button::after {
  border-bottom: 8px solid #004d27;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  bottom: 0;
  content: '';
  display: block;
  height: 8px;
  left: 50%;
  margin: 0;
  padding: 0;
  position: absolute;
  transform: translate(-50%, 8px);
  transition: all .4s ease-in-out;
  width: 18px;
}

.site__search .site__search-clear {
  font-size: 14px;
  font-size: 1.4rem;
  color: white;
  cursor: pointer;
  display: inline-block;
  height: 24px;
  line-height: 24px;
  margin: 0 32px 0 0;
  opacity: 0;
  padding: 0;
  text-align: center;
  transition: all .4s ease-in-out;
  vertical-align: middle;
  width: 24px;
}

.site__search .site__search-clear * {
  color: white;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .site__search .site__search-clear {
    margin: 0;
  }
}

@media (max-width: tablet-landscape) {
  .site__search .site__search-clear {
    margin: 0;
  }
}

.site__search .site__search-form {
  background: #004d27;
  display: block;
  left: 0;
  margin: -12px 0 0 0;
  max-height: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 100%;
  transition: all .4s ease-in-out;
  width: 100%;
  z-index: 11;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .site__search .site__search-form {
    margin-top: -48px;
  }
}

@media (max-width: tablet-landscape) {
  .site__search .site__search-form {
    margin-top: -48px;
  }
}

.site__search .site__search-form input {
  font-size: 40px;
  font-size: 4rem;
  background: transparent;
  color: white;
  height: 48px;
  line-height: 48px;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .site__search .site__search-form input {
    font-size: 20px;
    font-size: 2rem;
    height: 40px;
    line-height: 40px;
  }
}

@media (max-width: tablet-landscape) {
  .site__search .site__search-form input {
    font-size: 20px;
    font-size: 2rem;
    height: 40px;
    line-height: 40px;
  }
}

.site__search .site__search-form input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.site__search .site__search-form input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.site__search .site__search-form input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.site__search .site__search-form input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.site__search .site__search-form button {
  font-size: 12px;
  font-size: 1.2rem;
  background: white;
  border: none;
  color: #6f7271;
  font-weight: bold;
  letter-spacing: 1px;
  margin: 0;
  opacity: .5;
  padding: 0 30px;
  text-indent: 1px;
  transition: all .4s ease-in-out;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .site__search .site__search-form button {
    display: none;
  }
}

@media (max-width: tablet-landscape) {
  .site__search .site__search-form button {
    display: none;
  }
}

.site__search--active .site__search-button::after {
  transform: translate(-50%, 0);
}

.site__search--active .site__search-form {
  max-height: 1000px;
  padding: 22px 0;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .site__search--active .site__search-form {
    padding: 16px 0;
  }
}

@media (max-width: tablet-landscape) {
  .site__search--active .site__search-form {
    padding: 16px 0;
  }
}

.site__search--focused .site__search-form button {
  opacity: 1;
}

.site__search--focused .site__search-clear {
  opacity: 1;
}

/* ------------------------------------------------------------ */
/*  Exchange rates */
/* ------------------------------------------------------------ */
.site_exchange-rates {
  margin: 0 12px;
  position: relative;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .site_exchange-rates {
    background: #006835;
    margin: 0;
  }
}

@media (max-width: tablet-landscape) {
  .site_exchange-rates {
    background: #006835;
    margin: 0;
  }
}

.site_exchange-rates .site__exchange-rates-button {
  font-size: 18px;
  font-size: 1.8rem;
  align-items: center;
  color: #6f7271;
  display: flex;
  font-weight: 500;
  height: 60px;
  justify-content: center;
  line-height: 60px;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: center;
  vertical-align: middle;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .site_exchange-rates .site__exchange-rates-button {
    font-size: 13px;
    font-size: 1.3rem;
    color: white;
    height: 48px;
    line-height: 48px;
    padding: 0 16px;
    width: 100%;
    text-align: left;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media (max-width: tablet-landscape) {
  .site_exchange-rates .site__exchange-rates-button {
    font-size: 13px;
    font-size: 1.3rem;
    color: white;
    height: 48px;
    line-height: 48px;
    padding: 0 16px;
    width: 100%;
    text-align: left;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.site_exchange-rates .site__exchange-rates-button i {
  align-items: center;
  display: flex;
  font-size: 21px;
  height: 24px;
  justify-content: center;
  margin-right: 8px;
  width: 24px;
}

.site_exchange-rates .site__exchange-rates-button i::before {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .site_exchange-rates .site__exchange-rates-button i {
    font-size: 16px;
    font-size: 1.6rem;
  }
  .site_exchange-rates .site__exchange-rates-button i.w-icon-ic-down {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

@media (max-width: tablet-landscape) {
  .site_exchange-rates .site__exchange-rates-button i {
    font-size: 16px;
    font-size: 1.6rem;
  }
  .site_exchange-rates .site__exchange-rates-button i.w-icon-ic-down {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.site_exchange-rates .site__exchange-rates-button span {
  display: inline-block;
  vertical-align: middle;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .site_exchange-rates .site__exchange-rates-button span {
    flex-grow: 2;
    padding-left: 16px;
    text-align: left;
  }
}

@media (max-width: tablet-landscape) {
  .site_exchange-rates .site__exchange-rates-button span {
    flex-grow: 2;
    padding-left: 16px;
    text-align: left;
  }
}

.site_exchange-rates .site__exchange-rates-container {
  display: block;
  left: 12px;
  margin: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  padding: 8px 0 0 0;
  position: absolute;
  top: 100%;
  transform: translate(-50%, 0);
  transition: opacity .4s ease-in-out .1s, height .1s ease-in-out .5s, width .1s ease-in-out .5s;
  width: 0;
  z-index: 12;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .site_exchange-rates .site__exchange-rates-container {
    left: 0;
    padding: 0;
    transform: translate(0, 0);
    position: relative;
    opacity: 1;
    width: 100%;
    transition: height .5s ease-in-out .1s;
  }
}

@media (max-width: tablet-landscape) {
  .site_exchange-rates .site__exchange-rates-container {
    left: 0;
    padding: 0;
    transform: translate(0, 0);
    position: relative;
    opacity: 1;
    width: 100%;
    transition: height .5s ease-in-out .1s;
  }
}

.site_exchange-rates .site__exchange-rates-container::before {
  border-bottom: 8px solid #373838;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  content: '';
  display: block;
  height: 8px;
  left: 50%;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  transform: translate(-50%, 0);
  transition: opacity .4s ease-in-out .2s;
  width: 18px;
}

.site_exchange-rates .site__exchange-rates-calc {
  background: #373838;
  border-radius: 2px;
  display: block;
  height: 276px;
  margin: 0;
  padding: 24px;
  width: 384px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .site_exchange-rates .site__exchange-rates-calc {
    width: 100%;
    border-radius: 0;
  }
}

@media (max-width: tablet-landscape) {
  .site_exchange-rates .site__exchange-rates-calc {
    width: 100%;
    border-radius: 0;
  }
}

.site_exchange-rates .site__exchange-rates-calc p {
  font-size: 14px;
  font-size: 1.4rem;
  color: rgba(255, 255, 255, 0.75);
  margin: 0;
  padding: 0;
  line-height: 40px;
}

.site_exchange-rates .nav-pills {
  background: rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  margin-bottom: 16px;
  overflow: hidden;
}

.site_exchange-rates .nav-pills .nav-link {
  font-size: 12px;
  font-size: 1.2rem;
  border-radius: 0;
  color: white;
  height: 32px;
  line-height: 32px;
  padding: 0 10px;
  text-transform: uppercase;
  transition: all .35s ease-in-out;
  font-weight: 500;
}

.site_exchange-rates .nav-pills .nav-link.active {
  background: white;
  color: #6f7271;
}

.site_exchange-rates .site__exchange-rates-amount,
.site_exchange-rates .site__exchange-rates-value {
  border-radius: 2px;
  font-size: 0;
  height: 40px;
  overflow: hidden;
  width: 100%;
}

.site_exchange-rates .site__exchange-rates-amount .site__exchange-rates-currency-sign,
.site_exchange-rates .site__exchange-rates-value .site__exchange-rates-currency-sign {
  font-size: 16px;
  font-size: 1.6rem;
  background: #6f7271;
  border: none;
  color: white;
  display: inline-block;
  height: 100%;
  line-height: 40px;
  text-align: center;
  vertical-align: middle;
  width: 40%;
}

.site_exchange-rates .site__exchange-rates-amount .site__exchange-rates-currency,
.site_exchange-rates .site__exchange-rates-amount .site__exchange-rates-currency-amount,
.site_exchange-rates .site__exchange-rates-value .site__exchange-rates-currency,
.site_exchange-rates .site__exchange-rates-value .site__exchange-rates-currency-amount {
  font-size: 16px;
  font-size: 1.6rem;
  background: white;
  border-radius: 0;
  border: none;
  color: #6f7271;
  display: inline-block;
  height: 100%;
  line-height: 40px;
  padding: 0 16px;
  text-align: left;
  vertical-align: middle;
  width: 60%;
}

.site_exchange-rates .site__exchange-rates-equal-to {
  font-size: 18px;
  font-size: 1.8rem;
  color: white;
  font-weight: bold;
  text-align: center;
}

.site_exchange-rates--active .site__exchange-rates-container {
  height: 284px;
  opacity: 1;
  transition: opacity .45s ease-in-out .2s, height .1s ease-in-out .1s, width .1s ease-in-out .1s;
  width: 384px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .site_exchange-rates--active .site__exchange-rates-container {
    height: 276px;
    width: 100%;
    transition: height .5s ease-in-out .1s;
  }
}

@media (max-width: tablet-landscape) {
  .site_exchange-rates--active .site__exchange-rates-container {
    height: 276px;
    width: 100%;
    transition: height .5s ease-in-out .1s;
  }
}

.site_exchange-rates--active .site__exchange-rates-container::before {
  opacity: 1;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .site_exchange-rates--active .site__exchange-rates-button .w-icon-ic-down {
    transform: rotate(180deg);
  }
}

@media (max-width: tablet-landscape) {
  .site_exchange-rates--active .site__exchange-rates-button .w-icon-ic-down {
    transform: rotate(180deg);
  }
}

/* ------------------------------------------------------------ */
/*  Main Navigation */
/* ------------------------------------------------------------ */
@media screen and (min-width: 0px) and (max-width: 991px) {
  .site__navigation {
    display: none;
  }
}

@media (max-width: tablet-landscape) {
  .site__navigation {
    display: none;
  }
}

.site__main-menu-picker {
  background: rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  font-size: 0;
  margin: 0 12px 0 0;
  overflow: hidden;
  padding: 0;
}

.site__main-menu-picker li {
  display: inline-block;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

.site__main-menu-picker li.active a {
  background: white;
  color: #6f7271;
}

.site__main-menu-picker a {
  font-size: 14px;
  font-size: 1.4rem;
  color: white;
  display: block;
  font-weight: 500;
  line-height: 32px;
  margin: 0;
  padding: 0 24px;
  transition: all .25s ease-in-out;
}

.site__main-menu-picker a:hover {
  background: rgba(255, 255, 255, 0.8);
  color: #6f7271;
}

@media screen and (min-width: 0px) and (max-width: 1140px) {
  .site__main-menu-picker a {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

@media (max-width: desktop-minimun) {
  .site__main-menu-picker a {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.site__main-menu-wrapper {
  background: #006835;
  margin: 0;
  padding: 0;
  position: relative;
}

.site__main-menu {
  display: inline-block;
  font-size: 0;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

.site__main-menu li {
  display: inline-block;
  margin: 0 12px;
  padding: 0;
  vertical-align: middle;
}

.site__main-menu a {
  font-size: 16px;
  font-size: 1.6rem;
  color: white;
  display: block;
  font-weight: 500;
  line-height: 26px;
  margin: 0;
  padding: 15px 0;
  transition: all .25s ease-in-out;
}

.site__main-menu a:hover {
  color: white;
}

@media screen and (min-width: 0px) and (max-width: 1140px) {
  .site__main-menu a {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

@media (max-width: desktop-minimun) {
  .site__main-menu a {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.site__main-menu .submenu-parent .sub-menu {
  margin-top: 0;
}

/* ------------------------------------------------------------ */
/*  Submenu Navigation */
/* ------------------------------------------------------------ */
.submenu-parent > a {
  overflow: hidden;
  position: relative;
}

.submenu-parent > a::after {
  border-bottom: 8px solid #004d27;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  bottom: 0;
  content: '';
  display: block;
  height: 8px;
  left: 50%;
  margin: 0;
  padding: 0;
  position: absolute;
  transform: translate(-50%, 8px);
  transition: all .4s ease-in-out;
  width: 18px;
}

.submenu-parent:hover > a::after {
  transform: translate(-50%, 0);
}

.submenu-parent:hover .sub-menu {
  max-height: 1000px;
  padding: 32px 0;
}

.submenu-parent .sub-menu {
  background: #004d27;
  display: block;
  left: 0;
  margin: 0;
  max-height: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 100%;
  transition: all .4s ease-in-out;
  width: 100%;
  z-index: 10;
}

.submenu-parent .sub-menu .sub-menu-header {
  font-size: 14px;
  font-size: 1.4rem;
  color: rgba(255, 255, 255, 0.75);
  display: block;
  margin: 0 0 24px 0;
  padding: 0 0 10px 0;
  position: relative;
}

.submenu-parent .sub-menu .sub-menu-header::after {
  background: rgba(255, 255, 255, 0.25);
  bottom: 0;
  content: '';
  height: 2px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 14px;
}

.submenu-parent .sub-menu ul {
  display: block;
  margin: 0;
  padding: 0;
}

.submenu-parent .sub-menu li {
  display: block;
  margin: 0 0 16px 0;
  padding: 0;
}

.submenu-parent .sub-menu li:last-child {
  margin-bottom: 0;
}

.submenu-parent .sub-menu a {
  font-size: 16px;
  font-size: 1.6rem;
  color: white;
  display: block;
  font-weight: 500;
  padding: 0;
  position: relative;
}

/* ------------------------------------------------------------ */
/*  Mobile Navigation */
/* ------------------------------------------------------------ */
.site__mobile-menu-wrapper {
  background: #006835;
  display: none;
  height: 100%;
  left: -100%;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  transition: all .35s ease-in-out;
  width: 320px;
  z-index: 20;
  opacity: 0;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .site__mobile-menu-wrapper {
    display: block;
  }
}

@media (max-width: tablet-landscape) {
  .site__mobile-menu-wrapper {
    display: block;
  }
}

.site__mobile-menu-wrapper--active {
  left: 0;
  opacity: 1;
}

.site__mobile-menu-wrapper.parent-opened .site__mobile-menu-header {
  opacity: 0;
  z-index: 19;
  transition: opacity .4s ease-in-out 0s, z-index .01s ease-in-out .35s;
}

.site__mobile-menu-wrapper.parent-opened .site__mobile-menu-content {
  z-index: 20;
  transition: z-index .01s ease-in-out .35s;
}

.site__mobile-menu-header {
  background: #006835;
  height: 80px;
  left: 0;
  opacity: 1;
  padding: 16px;
  position: absolute;
  top: 0;
  transition: opacity .35s ease-in-out .02s, z-index .01s ease-in-out 0s;
  width: 100%;
  z-index: 20;
}

.site__mobile-menu-header .site__mobile-menu__button {
  font-size: 16px;
  font-size: 1.6rem;
  background: transparent;
  border: none;
  color: white;
  height: 24px;
  line-height: 24px;
  margin: 0 16px 0 0;
  overflow: hidden;
  padding: 0;
  width: 24px;
}

.site__mobile-menu-picker {
  background: rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  font-size: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

.site__mobile-menu-picker li {
  display: inline-block;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  width: 50%;
}

.site__mobile-menu-picker li.active a {
  background: white;
  color: #6f7271;
}

.site__mobile-menu-picker a {
  font-size: 12px;
  font-size: 1.2rem;
  color: white;
  display: block;
  font-weight: 500;
  line-height: 32px;
  margin: 0;
  padding: 0 24px;
  text-align: center;
  text-transform: uppercase;
  transition: all .25s ease-in-out;
  width: 100%;
}

.site__mobile-menu-picker a:hover {
  background: rgba(255, 255, 255, 0.8);
  color: #6f7271;
}

.site__mobile-menu-content {
  -webkit-overflow-scrolling: touch;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 80px 0 0 0;
  position: relative;
  transition: z-index .01s ease-in-out 0s;
  z-index: 19;
}

.site__mobile-menu-content ul {
  display: block;
  margin: 0;
  padding: 0 24px;
}

.site__mobile-menu-content .site__mobile-menu {
  position: relative;
}

.site__mobile-menu-content .site__mobile-menu:nth-child(odd) {
  background: #004d27;
}

.site__mobile-menu-content .site__mobile-menu li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: block;
  margin: 0;
  padding: 0;
}

.site__mobile-menu-content .site__mobile-menu li:last-child {
  border: none;
}

.site__mobile-menu-content .site__mobile-menu a {
  font-size: 16px;
  font-size: 1.6rem;
  color: white;
  display: block;
  line-height: 24px;
  margin: 0;
  padding: 16px 0;
  position: relative;
}

.site__mobile-menu-content .site__mobile-menu-parent > a {
  font-size: 16px;
  font-size: 1.6rem;
  color: white;
  display: block;
  line-height: 24px;
  margin: 0;
  padding: 16px 0;
  position: relative;
}

.site__mobile-menu-content .site__mobile-menu-parent > a i {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  right: 0;
  opacity: .4;
  transform: translate(0, -50%);
}

.site__mobile-menu-content .site__mobile-menu-parent > a i::before {
  vertical-align: middle;
}

.site__mobile-menu-content .site__mobile-menu__sub-menu {
  background: #004d27;
  display: block;
  height: 100%;
  left: -100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: fixed;
  top: 0;
  transition: all .35s ease-in-out;
  width: 320px;
  z-index: 21;
}

.site__mobile-menu-content .site__mobile-menu__sub-menu--active {
  left: 0;
  opacity: 1;
}

.site__mobile-menu-content .site__mobile-menu__sub-menu > li {
  border: none;
  margin: 0 0 16px 0;
  padding: 0;
  position: relative;
}

.site__mobile-menu-content .site__mobile-menu__sub-menu > li::after {
  background: rgba(255, 255, 255, 0.1);
  bottom: 0;
  content: '';
  height: 1px;
  left: 24px;
  margin: 0;
  padding: 0;
  position: absolute;
  width: calc(100% - 48px);
}

.site__mobile-menu-content .site__mobile-menu__sub-menu > li:last-child::after {
  display: none;
}

.site__mobile-menu-content .site__mobile-menu__sub-menu-title {
  margin: 0;
  padding: 0;
  height: 80px;
}

.site__mobile-menu-content .site__mobile-menu__sub-menu-title a {
  font-size: 14px;
  font-size: 1.4rem;
  background: #006835;
  line-height: 24px;
  margin: 0;
  padding: 28px 20px 28px 56px;
  text-transform: uppercase;
}

.site__mobile-menu-content .site__mobile-menu__sub-menu-title a i {
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(0, -50%);
  width: 56px;
}

.site__mobile-menu-content .site__mobile-menu__sub-menu-title a i::before {
  vertical-align: middle;
}

.site__mobile-menu-content .site__mobile-menu__sub-menu-content {
  height: calc(100% - 80px);
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 16px 0 0 0;
  -webkit-overflow-scrolling: touch;
}

.site__mobile-menu-content .site__mobile-menu__sub-menu-content > li {
  margin: 0 0 16px 0;
  padding: 0;
  position: relative;
  border: none;
}

.site__mobile-menu-content .site__mobile-menu__sub-menu-content > li::after {
  background: rgba(255, 255, 255, 0.1);
  bottom: 0;
  content: '';
  height: 1px;
  left: 24px;
  position: absolute;
  width: calc(100% - 48px);
}

.site__mobile-menu-content .site__mobile-menu__sub-menu-content > li:last-child {
  margin-bottom: 0;
}

.site__mobile-menu-content .site__mobile-menu__sub-menu-content > li:last-child::after {
  opacity: 0;
}

.site__mobile-menu-content .site__mobile-menu__sub-menu-header {
  font-size: 14px;
  font-size: 1.4rem;
  color: rgba(255, 255, 255, 0.75);
  display: block;
  margin: 0 24px;
  padding: 5px 0;
  position: relative;
}

.site__mobile-menu-content .site__mobile-menu__sub-menu-header::after {
  background: rgba(255, 255, 255, 0.25);
  bottom: 0;
  content: '';
  height: 2px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 14px;
}

.site .close-mobile-overlay {
  display: none;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .site .close-mobile-overlay {
    background: rgba(0, 0, 0, 0.24);
    content: '';
    display: block;
    height: 0;
    left: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: fixed;
    transition: height .01s ease-in-out .4s, width .01s ease-in-out .4s, opacity .35s ease-in-out .01s;
    top: 0;
    width: 0;
    z-index: 19;
  }
}

@media (max-width: tablet-landscape) {
  .site .close-mobile-overlay {
    background: rgba(0, 0, 0, 0.24);
    content: '';
    display: block;
    height: 0;
    left: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: fixed;
    transition: height .01s ease-in-out .4s, width .01s ease-in-out .4s, opacity .35s ease-in-out .01s;
    top: 0;
    width: 0;
    z-index: 19;
  }
}

.site__mobile-menu--is-open {
  min-height: 100%;
  overflow: hidden;
  position: relative;
}

.site__mobile-menu--is-open body {
  min-height: 100%;
  overflow: hidden;
  position: relative;
}

.site__mobile-menu--is-open body .close-mobile-overlay {
  height: 100%;
  opacity: 1;
  width: 100%;
  transition: height .01s ease-in-out .01s, width .01s ease-in-out .01s, opacity .35s ease-in-out .01s;
}

/* ------------------------------------------------------------ */
/*  Content - Common Layout */
/* ------------------------------------------------------------ */
.site {
  min-height: 700px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .site {
    min-height: 100%;
  }
}

@media (max-width: tablet-landscape) {
  .site {
    min-height: 100%;
  }
}

.site__content {
  width: 100%;
}

.container--no-padding {
  padding-left: 0;
  padding-right: 0;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .container-sm-half .row {
    margin-right: -7.5px;
    margin-left: -7.5px;
  }
  .container-sm-half .col-1,
  .container-sm-half .col-2,
  .container-sm-half .col-3,
  .container-sm-half .col-4,
  .container-sm-half .col-5,
  .container-sm-half .col-6,
  .container-sm-half .col-7,
  .container-sm-half .col-8,
  .container-sm-half .col-9,
  .container-sm-half .col-10,
  .container-sm-half .col-11,
  .container-sm-half .col-12 {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
}

@media (max-width: mobile-landscape) {
  .container-sm-half .row {
    margin-right: -7.5px;
    margin-left: -7.5px;
  }
  .container-sm-half .col-1,
  .container-sm-half .col-2,
  .container-sm-half .col-3,
  .container-sm-half .col-4,
  .container-sm-half .col-5,
  .container-sm-half .col-6,
  .container-sm-half .col-7,
  .container-sm-half .col-8,
  .container-sm-half .col-9,
  .container-sm-half .col-10,
  .container-sm-half .col-11,
  .container-sm-half .col-12 {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
}

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1140px;
  width: 100%;
}

.site__footer {
  background: #F7F7F7;
  margin: 0;
  padding: 48px 0 0 0;
}

.site__footer-logo {
  display: inline-block;
  margin: 0;
  max-width: 208px;
  padding: 0;
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .site__footer-logo {
    margin-bottom: 16px;
  }
}

.footer__social-networks {
  margin: 0;
  padding: 0;
  display: block;
  font-size: 0;
}

.footer__social-networks li {
  margin: 0 16px 16px 0;
  padding: 0;
  display: inline-block;
}

.footer__social-networks li:last-child {
  margin-right: 0;
}

.footer__social-networks a {
  border-radius: 50%;
  border: 1px solid #B7B8B8;
  color: #6f7271;
  display: inline-block;
  font-size: 18px;
  height: 40px;
  line-height: 40px;
  margin: 0;
  padding: 0;
  text-align: center;
  transition: all .35s ease-in-out;
  vertical-align: middle;
  width: 40px;
}

.footer__social-networks a:hover {
  color: white;
  background: #6f7271;
}

.footer__social-networks i {
  line-height: 38px;
}

.footer-bottom {
  align-items: center;
  border-top: 1px solid rgba(111, 114, 113, 0.25);
  display: flex;
  height: 84px;
  justify-content: space-between;
  padding-bottom: 26px;
  padding-top: 26px;
  width: 100%;
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .footer-bottom {
    height: auto;
    display: block;
    padding-bottom: 48px;
    text-align: center;
  }
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .site__copyright {
    display: block;
  }
}

.site__copyright a:hover {
  color: #004d27;
}

.footer__menu {
  margin: 0 0 16px 0;
  padding: 0;
  display: block;
}

.footer__menu:last-child {
  margin-bottom: 0;
}

.footer__menu li {
  margin: 0 0 8px 0;
  padding: 0;
  display: block;
}

.footer__menu li:last-child {
  margin-bottom: 0;
}

.footer__menu a {
  font-size: 16px;
  font-size: 1.6rem;
  color: #6f7271;
  display: inline-block;
  font-weight: normal;
  line-height: 26px;
  margin: 0;
  padding: 0;
  transition: all .25s ease-in-out;
}

.footer__menu a:hover {
  color: #004d27;
}

.footer__menu .footer__menu-parent {
  margin: 0 0 48px 0;
  padding: 0;
}

.footer__menu .footer__menu-parent:last-child {
  margin-bottom: 0;
}

.footer__menu .footer__menu-parent > a {
  font-weight: bold;
  margin: 0 0 24px 0;
  padding: 0 0 10px 0;
  position: relative;
}

.footer__menu .footer__menu-parent > a::after {
  background: #ffd100;
  bottom: 0;
  content: '';
  height: 2px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 16px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .footer__menu .footer__menu-parent > a::after {
    left: 50%;
    transform: translate(-50%, 0);
  }
}

@media (max-width: tablet-landscape) {
  .footer__menu .footer__menu-parent > a::after {
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.footer__menu .footer__submenu {
  margin: 0;
  padding: 0;
}

/* ------------------------------------------------------------ */
/*  Pages */
/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/*  Pages */
/* ------------------------------------------------------------ */
.page-search-results .page-search-title {
  font-size: 32px;
  font-size: 3.2rem;
  margin: 0 0 28px 0;
  padding: 0;
  position: relative;
  font-weight: 500;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .page-search-results .page-search-title {
    margin-bottom: 20px;
  }
}

@media (max-width: tablet) {
  .page-search-results .page-search-title {
    margin-bottom: 20px;
  }
}

.page-search-results .page-search-results-amount {
  margin: 0 0 24px 0;
  padding: 0;
  position: relative;
  display: block;
  font-weight: 500;
}

.page-search-results .page-search-results-search-form {
  border-radius: 2px;
  border-bottom: solid 1px rgba(183, 184, 184, 0.75);
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 0 48px 0;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .page-search-results .page-search-results-search-form {
    margin-bottom: 40px;
  }
}

@media (max-width: tablet) {
  .page-search-results .page-search-results-search-form {
    margin-bottom: 40px;
  }
}

.page-search-results .page-search-results-search-form input {
  font-size: 25px;
  font-size: 2.5rem;
  background: transparent;
  border: none;
  color: rgba(55, 56, 56, 0.5);
  font-weight: 500;
  line-height: 28px;
  margin: 0;
  padding: 13px 0;
  text-align: left;
  width: calc(100% - 32px);
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .page-search-results .page-search-results-search-form input {
    font-size: 20px;
    font-size: 2rem;
  }
}

@media (max-width: tablet) {
  .page-search-results .page-search-results-search-form input {
    font-size: 20px;
    font-size: 2rem;
  }
}

.page-search-results .page-search-results-search-form input:focus {
  box-shadow: none;
  outline: 0;
}

.page-search-results .page-search-results-search-form input::-webkit-input-placeholder {
  color: rgba(55, 56, 56, 0.5);
}

.page-search-results .page-search-results-search-form input::-moz-placeholder {
  color: rgba(55, 56, 56, 0.5);
}

.page-search-results .page-search-results-search-form input:-ms-input-placeholder {
  color: rgba(55, 56, 56, 0.5);
}

.page-search-results .page-search-results-search-form input:-moz-placeholder {
  color: rgba(55, 56, 56, 0.5);
}

.page-search-results .page-search-results-search-form input:-webkit-autofill {
  background: transparent !important;
  color: rgba(55, 56, 56, 0.5);
  -webkit-text-fill-color: #373838 !important;
  -webkit-box-shadow: 0 0 0 300px #373838 inset;
}

.page-search-results .page-search-results-search-form button {
  font-size: 19px;
  font-size: 1.9rem;
  background: transparent;
  border: none;
  color: #6f7271;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 32px;
}

.page-search-results .page-search-results-search-form button i {
  display: inline-block;
  line-height: 0;
  vertical-align: middle;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .page-search-results .page-search-results-search-form button {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

@media (max-width: tablet) {
  .page-search-results .page-search-results-search-form button {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.page-search-results .page-search-results-container {
  margin: 0 0 40px 0;
}

#press-room #favorite img {
  opacity: 0;
}

#press-room #favorite .news-card__image, #press-room #favorite .news-card .news-card__image-prensa, .news-card #press-room #favorite .news-card__image-prensa, #press-room #favorite .news-card-slider .news-card__image-prensa, .news-card-slider #press-room #favorite .news-card__image-prensa {
  margin: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: center;
  height: 100%;
}

#press-room #favorite .card-info {
  background-color: #ffffff;
  padding: 30px;
}

.list .title {
  display: block;
}

.compromiso-social h3 {
  margin-bottom: 24px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .compromiso-social h3 {
    font-size: 25px;
    font-size: 2.5rem;
    margin-bottom: 16px;
  }
}

@media (max-width: tablet-landscape) {
  .compromiso-social h3 {
    font-size: 25px;
    font-size: 2.5rem;
    margin-bottom: 16px;
  }
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .sobre-nosotros .text-italic-light {
    padding: 8px 0;
    margin-bottom: 40px;
  }
}

@media (max-width: mobile-landscape) {
  .sobre-nosotros .text-italic-light {
    padding: 8px 0;
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .sobre-nosotros .text-italic-light * {
    font-size: 28px;
    font-size: 2.8rem;
    line-height: 40px;
  }
}

@media (max-width: mobile-landscape) {
  .sobre-nosotros .text-italic-light * {
    font-size: 28px;
    font-size: 2.8rem;
    line-height: 40px;
  }
}

.card-full-detail h3 {
  margin-bottom: 40px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .card-full-detail h3 {
    font-size: 25px;
    font-size: 2.5rem;
  }
}

@media (max-width: tablet-landscape) {
  .card-full-detail h3 {
    font-size: 25px;
    font-size: 2.5rem;
  }
}

.card-full-detail h4 {
  font-size: 25px;
  font-size: 2.5rem;
  margin-bottom: 16px;
}

.card-full-detail .text-highlight {
  margin-bottom: 24px;
  line-height: 30px;
}

.card-full-detail .text-highlight span {
  color: #373838;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .card-full-detail .text-highlight {
    font-size: 20px;
    font-size: 2rem;
    line-height: 25px;
  }
}

@media (max-width: tablet-landscape) {
  .card-full-detail .text-highlight {
    font-size: 20px;
    font-size: 2rem;
    line-height: 25px;
  }
}

.card-elite-full-detail .showcase .showcase__content {
  background: linear-gradient(180deg, rgba(51, 51, 51, 0) 0%, #1b1c1c 100%);
  height: 100%;
}

.card-elite-full-detail .site__content {
  background: #1b1c1c;
}

.card-elite-full-detail .site__content h2, .card-elite-full-detail .site__content h3, .card-elite-full-detail .site__content h4, .card-elite-full-detail .site__content h5 {
  color: white;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .card-elite-full-detail .site__content h3 {
    font-size: 25px;
    font-size: 2.5rem;
  }
}

@media (max-width: tablet-landscape) {
  .card-elite-full-detail .site__content h3 {
    font-size: 25px;
    font-size: 2.5rem;
  }
}

.card-elite-full-detail .site__content p {
  color: rgba(255, 255, 255, 0.72);
}

.card-elite-full-detail .text-bigger * {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.6;
  margin-bottom: 32px;
}

@media screen and (min-width: 0px) and (max-width: 959px) {
  .card-elite-full-detail .text-bigger * {
    font-size: 16px;
    font-size: 1.6rem;
    margin-bottom: 24px;
  }
}

@media (max-width: tablet) {
  .card-elite-full-detail .text-bigger * {
    font-size: 16px;
    font-size: 1.6rem;
    margin-bottom: 24px;
  }
}
