.sobre-nosotros {

	.text-italic-light {
		@include r(mobile-landscape) {
			padding:8px 0;
			margin-bottom: 40px;
		}

		* {
			@include r(mobile-landscape) {
				@include font-size(2.8);
	    		line-height: 40px;
	    	}
	    }
	}
}