#press-room {
    #favorite {
        img {
            opacity: 0;
        }
        
        .news-card__image {
            margin: 0;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: center;
            height: 100%;
        }
        .card-info {
            background-color: #ffffff;
            padding: 30px
        }
    }
}

.list {
    .title {
        display: block;
    }
    .value {

    }
}