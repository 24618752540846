/* ------------------------------------------------------------ */
/*  Backgrounds */
/* ------------------------------------------------------------ */
.bg-logo-br {
  position: relative;
  overflow: hidden;

  &::before {
    background-image: url(../images/bg_pattern.svg);
    background-position: left 275px;
    background-repeat: no-repeat;
    content: '';
    height: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 580px;
    z-index: 0;
    opacity: .5;

    @include r(tablet) {
        background-position: left top;
        background-size: contain; 
        bottom: -170px;
        right: -217px;
        width: 500px;
        height: 310px;
    }
  }
}

.bg-logo-br-2 {
  position: relative;
  overflow: hidden;

  &::before {
    background-image: url(../images/bg_pattern.svg);
    background-position: left 65px;
    background-repeat: no-repeat;
    content: '';
    height: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 346px;
    z-index: 0;
    opacity: .2;

    @include r(tablet) {
        background-position: center center;
        background-size: contain; 
        bottom: -40px;
        right: -373px;
        width: 477px;
        height: 307px;
    }
  }
}

.bg-logo-br-3 {
  position: relative;
  overflow: hidden;

  &::before {
    background-image: url(../images/bg_pattern_gray.svg);
    background-position: bottom right;
    background-repeat: no-repeat;
    bottom: 0;
    content: '';
    height: 268px;
    margin: 0;
    opacity: .06;
    padding: 0;
    position: absolute;
    right: 0;
    transform: rotate(180deg);
    width: 348px;
    z-index: 0;

    @include r(tablet) {
        opacity: 0;
    }
  }
}

.bg-logo-bl {
  position: relative;
  overflow: hidden;

  &::before {
    background-image: url(../images/bg_pattern_gray.svg);
    background-position: right top;
    background-repeat: no-repeat;
    content: '';
    height: 370px;
    left: 0;
    margin: 0;
    opacity: 1;
    padding: 0;
    position: absolute;
    bottom: 0;
    width: 438px;
    z-index: 0;

    @include r(tablet) {
        background-position: right center;
        background-size: contain; 
        top: 0;
        left: -373px;
        width: 512px;
        height: 326px;
        opacity: .83;
    }
  }
}

.bg-logo-tl {
  position: relative;
  overflow: hidden;

  &::before {
    background-image: url(../images/bg_pattern_gray.svg);
    background-position: bottom right;
    background-repeat: no-repeat;
    content: '';
    height: 266px;
    left: 0;
    margin: 0;
    opacity: .54;
    padding: 0;
    position: absolute;
    top: 0;
    width: 438px;
    z-index: 0;

    @include r(tablet) {
        opacity: 0;
    }
  }
}

.bg-logo-tr {
  position: relative;
  overflow: hidden;

  &::before {
    background-image: url(../images/bg_pattern_gray.svg);
    background-position: right top;
    background-repeat: no-repeat;
    content: '';
    height: 368px;
    right: 0;
    margin: 0;
    opacity: .1;
    padding: 0;
    position: absolute;
    top: 0;
    width: 348px;
    z-index: 0;
    transform: rotate(180deg);

    @include r(tablet) {
        opacity: 0;
    }
  }
}

.bg-content {
    position: relative;
    z-index: 1;
}