/* ------------------------------------------------------------ */
/*  state card */
/* ------------------------------------------------------------ */
.state-card-list {
    display: flex;
    position: relative;
    align-items: flex-start;
    width: 100%;
    flex-wrap: wrap;
}

.state-card {
    background: $gray-background;
    border-radius: 2px;
    margin: 0 8px 16px 8px;
    overflow: hidden;
    width: calc(20% - 16px);

    .btn {
        border-radius: 0 0 2px 2px;
    }

    @include r(tablet-landscape) {
        width: calc(33.33% - 16px);
    }

    @include r(mobile-landscape) {
        width: calc(50% - 16px);
    }

    @include r(mobile) {
        width: calc(100% - 16px);
    }

    .state-card__title {
        @include font-size(1.6);
        font-weight: bold;
        text-align: center;
        margin: 0;
        padding: 20px 10px;
    }
}