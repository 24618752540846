/* ------------------------------------------------------------ */
/*  Cuentas detail */
/* ------------------------------------------------------------ */
.cuentas-detail {
    h3 {
        margin-bottom: 24px;
        font-weight: 500;

        @include r(tablet) {
            @include font-size(2.5);
            margin-bottom: 16px;
        }
    }

    h4 {
        margin-bottom: 24px;
    }

    .cuentas-detail-description {
        position: relative;

        p {
            @include font-size(2);
            line-height: 30px;

            @include r(tablet) {
                @include font-size(1.6);
                line-height: 26px;
                margin-bottom: 24px;
            }
        }
    }

    .cuentas-detail-description-bg {
        background-color: transparent;
        background-position: -32px center;
        background-repeat: no-repeat;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        opacity: .08;

        @include r(tablet-landscape) {
            display: none;
        }
    }

    .cuentas-detail-benefits {
        position: relative;
        overflow: hidden;

        &::after {
            background-image: url(../images/bg_pattern.svg);
            background-position: left bottom;
            background-repeat: no-repeat;
            top: 0;
            content: '';
            height: 464px;
            margin: 0;
            opacity: .3;
            padding: 0;
            position: absolute;
            right: 0;
            width: 420px;
            z-index: 0;

            @include r(tablet) {
                width: 30%;
                height: 100%;
                background-position: left -164px;
            }
        }

        h3 {
            margin-bottom: 16px;

            @include r(tablet) {
                text-align: center;
            }
        }

        ul {
            display: block;
            margin: 0;
            padding: 0 0 0 48px;

            li {
                @include font-size(1.6);
                display: block;
                margin: 0;
                padding: 28px 0 20px 0;
                position: relative;
                line-height: 1.6;
                letter-spacing: 0.1px;
                border-bottom: 1px solid rgba(111, 114, 113, 0.25);

                @include r(tablet) {
                    padding: 18px 0 14px 0;
                }

                &:last-child {
                    border: none;
                }

                &::before {
                    @include font-size(1.4);
                    align-items: center;
                    background: $gray;
                    border-radius: 50%;
                    color: white;
                    content: "\45";
                    display: flex;
                    font-family: "w-icons";
                    height: 32px;
                    justify-content: center;
                    left: -48px;
                    margin: 0;
                    overflow: hidden;
                    padding: 2px 0 0 0;
                    position: absolute;
                    text-align: center;
                    top: 24px;
                    width: 32px;

                    @include r(tablet) {
                        top: 16px;
                    }
                }
            }
        }
    }
}   