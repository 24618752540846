/* ---------------------- */
/*  Values Cards */
/* ---------------------- */
.value-card {
    align-items: center;
    background-color: white;
    border-radius: 2px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: column;
    margin: 0 0 30px 0;
    padding: 10px 10px 25px 10px;

    @include r(mobile-landscape) {
        margin-bottom: 16px;
    }

    &:hover {
    	.value-card-image-normal {
    		opacity: 0;
    	}

    	.value-card-image-hover {
    		opacity: 1;
    	}
    }

    .value-card-image {
    	width: 100%;
    	position: relative;
    	overflow: hidden;
    }

    .value-card-image-normal {
    	width: 100%;
    	position: relative;
    	overflow: hidden;
    	transition: all .35s ease-in-out;
    	text-align: center;
    }

    .value-card-image-hover {
    	width: 100%;
    	position: absolute;
    	top: 0;
    	left: 0;
    	opacity: 0;
    	transition: all .35s ease-in-out;
    	text-align: center;
    }
}