/* ------------------------------------------------------------ */
/*  Content - Common Layout */
/* ------------------------------------------------------------ */
.site {
  min-height: 700px;

  @include r(tablet-landscape) {
    min-height: 100%;
  }
}

.site__content {
  width: 100%;
}


.container {
  @extend %container;

  &--no-padding {
    padding-left: 0;
    padding-right: 0;
  }

  &-sm-half {
    @include r(mobile-landscape) {
      .row {
        margin-right: -7.5px;
        margin-left: -7.5px;
      }

      .col-1,
      .col-2,
      .col-3,
      .col-4,
      .col-5,
      .col-6,
      .col-7,
      .col-8,
      .col-9,
      .col-10,
      .col-11,
      .col-12 {
        padding-left: 7.5px;
        padding-right: 7.5px;
      }
    }
  }
}

%container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1140px;
  width: 100%;
}