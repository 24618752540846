/* ------------------------------------------------------------ */
/*  Exchange rates */
/* ------------------------------------------------------------ */

.site_exchange-rates {
	margin: 0 12px; 
	position: relative;

	@include r(tablet-landscape) {
		background: $green;
		margin: 0;
	}

	// Exchange rates Button
	.site__exchange-rates-button {
		@include font-size(1.8);
		align-items: center;
		color: $gray;
		display: flex;
		font-weight: 500;
		height: 60px;
		justify-content: center;
		line-height: 60px;
		margin: 0;
		padding: 0;
		position: relative;
		text-align: center;
		vertical-align: middle;

		@include r(tablet-landscape) {
			@include font-size(1.3);
			color: white;
			height: 48px;
			line-height: 48px;
			padding: 0 16px;
			width: 100%;
			text-align: left;
			text-transform: uppercase;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		i {
			align-items: center;
			display: flex;
			font-size: 21px;
			height: 24px;
			justify-content: center;
			margin-right: 8px;
			width: 24px;

			&::before {
				align-items: center;
				display: flex;
				height: 24px;
				justify-content: center;
				width: 24px;
			}

			@include r(tablet-landscape) {
				@include font-size(1.6);

				&.w-icon-ic-down {
					@include font-size(1.2);
				}
			}
		}

		span {
			display: inline-block;
			vertical-align: middle;

			@include r(tablet-landscape) {
				flex-grow: 2;
				padding-left: 16px;
				text-align: left;
			}
		}
	}


	// Exchange rates Container
	.site__exchange-rates-container {
		display: block;
		left: 12px;
		margin: 0;
		height: 0;
		opacity: 0;
		overflow: hidden;
		padding: 8px 0 0 0;
		position: absolute;
		top: 100%;
		transform: translate(-50%, 0);
		transition: opacity .4s ease-in-out .1s,
					height .1s ease-in-out .5s,
					width .1s ease-in-out .5s;
		width: 0;
		z-index: 12;

		@include r(tablet-landscape) {
			left: 0;
			padding: 0;
			transform: translate(0,0);
			position: relative;
			opacity: 1;
			width: 100%;
			transition: height .5s ease-in-out .1s;
		}

		&::before {
			border-bottom: 8px solid $gray-smoke;
			border-left: 9px solid transparent;
			border-right: 9px solid transparent;
			content: '';
			display: block;
			height: 8px;
			left: 50%;
			margin: 0;
			padding: 0;
			position: absolute;
			top: 0;
			transform: translate(-50%, 0);
			transition: opacity .4s ease-in-out .2s;
			width: 18px;
		}
	}


	// Exchange rates Calculator
	.site__exchange-rates-calc {
		background: $gray-smoke;
		border-radius: 2px;
		display: block;
		height: 276px;
		margin: 0;
		padding: 24px;
		width: 384px;

		@include r(tablet-landscape) {
			width: 100%;
			border-radius: 0;
		}

		p {
			@include font-size(1.4);
			color: rgba(255,255,255,0.75);
			margin: 0;
			padding: 0;
			line-height: 40px;
		}
	}

	.nav-pills {
		background: $gray-shadow;
		border-radius: 2px;
		margin-bottom: 16px;
		overflow: hidden;

		.nav-link {
			@include font-size(1.2);
			border-radius: 0;
			color: white;
			height: 32px;
			line-height: 32px;
			padding: 0 10px;
			text-transform: uppercase;
			transition: all .35s ease-in-out;
			font-weight: 500;

			&.active {
				background: white;
				color: $gray;
			}
		}
	}


	.site__exchange-rates-amount,
	.site__exchange-rates-value {
		border-radius: 2px;
		font-size: 0;
		height: 40px;
		overflow: hidden;
		width: 100%;


		.site__exchange-rates-currency-sign {
			@include font-size(1.6);
			background: $gray;
			border: none;
			color: white;
			display: inline-block;
			height: 100%;
			line-height: 40px;
			text-align: center;
			vertical-align: middle;
			width: 40%;
		}

		.site__exchange-rates-currency,
		.site__exchange-rates-currency-amount {
			@include font-size(1.6);
			background: white;
			border-radius: 0;
			border: none;
			color: $gray;
			display: inline-block;
			height: 100%;
			line-height: 40px;
			padding: 0 16px;
			text-align: left;
			vertical-align: middle;
			width: 60%;
		}
	}

	.site__exchange-rates-equal-to {
		@include font-size(1.8);
		color: white;
		font-weight: bold;
		text-align: center;
	}


	&--active {
		
		.site__exchange-rates-container {
			height: 284px;
			opacity: 1;
			transition: opacity .45s ease-in-out .2s,
						height .1s ease-in-out .1s,
						width .1s ease-in-out .1s;
			width: 384px;

			@include r(tablet-landscape) {
				height: 276px;
				width: 100%;
				transition: height .5s ease-in-out .1s;
			}

			&::before {
				opacity: 1;
			}
		}

		// Exchange rates Button
		.site__exchange-rates-button {
			@include r(tablet-landscape) {
				.w-icon-ic-down {
					transform: rotate(180deg);
				}
			}
		}
	}
}