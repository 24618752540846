// Two-Popular Grid component


.two-grids-section {
  margin: 30px auto;
  max-width: 1200px;
  padding: 0;
  position: relative;
  width: 100%;

  @include r(medium) {
    height: auto !important;
  }

  .panel-grid-cell {
    min-height: 560px;
    margin-bottom: 0 !important;
    padding: 0px !important;

    transition: all 700ms ease-in-out;

    @include r(1170px) {
      min-height: 100%;
    }

    @include r(medium) {
      float: none !important;
      min-height: auto;
      width: 100% !important;
    }

    .so-panel,
    .panel-widget-style {
      height: 100%;
    }

    .panel-widget-style {
      background-position: center center;
      background-size: cover;
    }

    .so-widget-image {
      width: 100%;

      @include r(medium-up) {
        display: none !important;
      }
    }

    &:last-child {
      background-color: #EDEDED;
      padding: 60px !important;
      position: relative;
      z-index: 2;

      @include r(small) {
        padding: 40px !important;
      }

      @include r(smaller) {
        padding: 10px 20px 20px !important;

        h2 {
          margin: 15px 0;
        }
      }
    }

    &:first-child {
      height: auto;
    }

    @include r(medium-up) {
      &:first-child {
        height: 100%;
        position: absolute;
        right: 0;
        z-index: 1;
      }
    }
  }


  // Sets White Background Info Box to the Right

  &.info-to-right {
    .panel-grid-cell {

      &:last-child {
        background: none;
        height: 100%;
        padding: 0 !important;
        left: 0;
        right: auto;
        z-index: 1;

        @include r(medium-up) {
          position: absolute;
        }
      }

      &:first-child {
        background-color: #EDEDED;
        float: right !important;
        height: auto;
        padding: 60px !important;
        position: relative;
        z-index: 2;

        @include r(small) {
          padding: 40px !important;
        }

        @include r(smaller) {
          padding: 10px 20px 20px !important;

          h2 {
            margin: 15px 0;
          }
        }
      }
    }
  }
}