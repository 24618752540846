/* ------------------------------------------------------------ */
/*  Pages */
/* ------------------------------------------------------------ */

// Search results
@import 'search-results';

// Press room page
@import 'press-room';

// Compromiso Social
@import 'compromiso-social';

// Sobre Nosotros
@import 'sobre-nosotros';

// Tarjetas Detalle
@import 'card-full-detail';

// Tarjetas Elite Detalle
@import 'card-elite-full-detail';